import React, {useState} from "react";
import {Input, Tag, Typography, Modal, Radio, Select, Alert} from 'antd';
import './styles/store.css'

const {Option} = Select;
const {Title} = Typography;


const AddWin = (props) => {



    let [alertName, setalertName] = useState(false); //устанавливаем количество перемещаемых товаров
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [count, setCount] = useState();
    const [brand, setBrand] = useState('');
    const [cost, setCost] = useState(0);
    const [description, setDescriptiom] = useState('');
    const [packName, setPackName] = useState('шт');
    const [pack, setPack] = useState(1);
    const [dateAvalible, setDateAvalible] = useState('');
    const [CatId, setCatId] = useState({
        cat: 1,
        sub: 1
    });



    const categoryArray = () => {
        return props.category.mainCategory.map((el) => {
            return <Option key={Number(el.id)} value={Number(el.id)}>{el.name}</Option>
        })
    };
    const subcategoryArray = (id) => {
        return props.category.subCategory.map((el) => {
            return Number(el.parent_id) === id ?
                <Option key={Number(el.id)} value={Number(el.id)}>{el.name}</Option> : ''
        }).filter((el) => {
            return el !== ''
        })

    };
    const changeCategory = (e, option) => {
        setCatId({cat: Number(option.key), sub: Number(subcategoryArray(Number(option.key))[0].props.value)})

    }





    const post = () => {
        props.setModal()
        setLoading(true)
        let postName = name;

        if (name === '') {
            setLoading(false)
            setalertName(true)
        } else {
            if (alertName) {
                setalertName(false)
            }
            let postSubCat = CatId.sub;
            let postBrand = brand;

            let postCost = cost === '' ? 0 : cost;
            let postDescription = description;
            let postPackage_name = packName;
            let postDate_avalible = dateAvalible;
            let postPack = pack === '' ? 0 : pack;
            let postCount = count;

            //console.log(`${postName} | ${postBrand} | ${postDescription} | ${postPackage_name} | ${postPack} | ${postCount} | ${postCost} | ${postDate_avalible} | ${postSubCat}`)
            props.post(postName,postBrand,postDescription,postPackage_name,postPack,postCount,postCost,postDate_avalible,postSubCat)

        }



    }
    const cancelEvent = () => {
        props.setModal();
    }


    const changeSubCategory = (e) => {
        setCatId({...CatId,sub: e})

    }
    const changeItem = (e) => {

        setPackName(e.value)

    }
    const SelectSubCat = (props) => {
        return (
            <>
                <div className={'label_3'}>Подкатегория:</div>
                <Select
                    style={{width: 200}}
                    placeholder="Выберите категорию"
                    optionFilterProp="children"
                    defaultValue={props.defaultValue}
                    onChange={(input, option) => {
                        changeSubCategory(input, option)
                    }}
                >
                    {props.arr}
                </Select>
            </>
        )
    }


    const SelectCat = (props) => {
        return (<>
                <div className={'label_3'}>Категория:</div>
                <Select
                    style={{width: 200}}
                    placeholder="Выберите категорию"
                    optionFilterProp="children"
                    defaultValue={props.defaultValue}
                    onChange={(input, option) => {
                        changeCategory(input, option)
                    }}
                >
                    {props.arr}
                </Select>
            </>
        )
    }


    return (

        <>
            <Modal
                title={<Title level={5}>{`Добавление новой позиции`}</Title>}
                centered
                visible={true}
                onOk={() => post()}
                onCancel={() => cancelEvent()}
                okText="Добавить"
                cancelText="Отмена"
                confirmLoading={loading}

            >
                <>
                    <div className={'label_3'}>Наименование:</div>
                    <Input className={'inpChange'} value={name} onChange={(input) => {
                        setName(input.target.value)
                    }} placeholder="Наименование"/>
                    <div className={'label_3'}>Производитель:</div>
                    <Input className={'inpChange'} value={brand} onChange={(input) => {
                        setBrand(input.target.value)
                    }} placeholder="Производитель"/>
                    <div className={'label_3'}>Описание:</div>
                    <Input className={'inpChange'} value={description} onChange={(input) => {
                        setDescriptiom(input.target.value)
                    }} placeholder="Описание"/>
                    <div className={'label_3'}>Единица измерения:</div>
                    <Select
                        className={'sel'}
                        style={{width: 200}}
                        placeholder="Выберите единицу измерения"
                        optionFilterProp="children"
                        defaultValue={'шт'}
                        onChange={(input, option) => {
                            changeItem(option)
                        }}
                    >
                        <Option key={1} value={'шт'}>шт</Option>
                        <Option key={2} value={'Kg'}>Kg</Option>
                        <Option key={3} value={'g'}>g</Option>
                        <Option key={4} value={'L'}>L</Option>
                        <Option key={5} value={'ml'}>ml</Option>
                        <Option key={6} value={'M'}>M</Option>
                        <Option key={7} value={'sm'}>sm</Option>
                        <Option key={8} value={'M2'}>M</Option>



                    </Select>
                    <div className={'label_3'}>{`Количество ${packName} в упаковке:`}</div>
                    <Input min={0} type={'number'} className={'inpChange'} value={pack} onChange={(input) => {
                        setPack(input.target.value)
                    }} placeholder="Количество в упаковке"/>
                    <div className={'label_3'}>Количество упаковок:</div>
                    <Input min={0} step={"any"} type={'number'} className={'inpChange'} value={count} onChange={(input) => {
                        setCount(input.target.value)
                    }} placeholder="Количество в упаковке"/>
                    <div className={'label_3'}>Годен до:</div>
                    <Input className={'inpChange'} value={dateAvalible} onChange={(input) => {
                        setDateAvalible(input.target.value)
                    }} placeholder="Годен до"/>
                    <div className={'label_3'}>Стоимость:</div>
                    <Input min={0} type={'number'} className={'inpChange'} value={cost} onChange={(input) => {
                        setCost(input.target.value)
                    }} placeholder="Стоимость"/>
                </>
                <SelectCat defaultValue={CatId.cat} arr={categoryArray()}/>
                <SelectSubCat defaultValue={CatId.sub} arr={subcategoryArray(CatId.cat)}/>
                { alertName ? <Alert message="Укажите наименование" type="error"  showIcon/> : ''}
            </Modal>


        </>
    )


}


export default AddWin;



/*

const ChangeWin = (props) => {

    const [name, setName] = useState(props.element.name);
    const [brand, setBrand] = useState(props.element.brand);
    const [cost, setCost] = useState(Number(props.element.cost));
    const [description, setDescriptiom] = useState(props.element.description);
    const [packName, setPackName] = useState(props.element.package_name);
    const [pack, setPack] = useState(props.element.package);
    const [dateAvalible, setDateAvalible] = useState(props.element.date_avalible);
    const [budva_store, setbudva_store] = useState(Number(props.element.store_1));
    const [CatId, setCatId] = useState({
        cat: (Number(props.element.maincatId)),
        sub: Number(props.element.subCatId)
    });


    const categoryArray = () => {
        return props.category.mainCategory.map((el) => {
            return <Option key={Number(el.id)} value={Number(el.id)}>{el.name}</Option>
        })
    };
    const subcategoryArray = (id) => {
        return props.category.subCategory.map((el) => {
            return Number(el.parent_id) === id ?
                <Option key={Number(el.id)} value={Number(el.id)}>{el.name}</Option> : ''
        }).filter((el) => {
            return el !== ''
        })

    };
    const changeCategory = (e, option) => {
        setCatId({cat: Number(option.key), sub: Number(subcategoryArray(Number(option.key))[0].props.value)})

    };

    const resultElem = () => {
        return <>
            <div className={'label_3'}>Наименование:</div>
            <Input className={'inpChange'} value={name} onChange={(input) => {
                setName(input.target.value)
            }} placeholder="Наименование"/>
            <div className={'label_3'}>Производитель:</div>
            <Input className={'inpChange'} value={brand} onChange={(input) => {
                setBrand(input.target.value)
            }} placeholder="Производитель"/>
            <div className={'label_3'}>Описание:</div>
            <Input className={'inpChange'} value={description} onChange={(input) => {
                setDescriptiom(input.target.value)
            }} placeholder="Описание"/>
            <div className={'label_3'}>Единица измерения:</div>
            <Input className={'inpChange'} value={packName} onChange={(input) => {
                setPackName(input.target.value)
            }} placeholder="Единица измерения"/>
            <div className={'label_3'}>Количество в упаковке:</div>
            <Input className={'inpChange'} value={pack} onChange={(input) => {
                setPack(input.target.value)
            }} placeholder="Количество в упаковке"/>
            <div className={'label_3'}>Кол-во Склад Будва:</div>
            <Input type={'number'} step={'any'} className={'inpChange'} value={budva_store} onChange={(input) => {
                setbudva_store(input.target.value)
            }} placeholder="Кол-во Склад Будва"/>
            <div className={'label_3'}>Годен до:</div>
            <Input className={'inpChange'} value={dateAvalible} onChange={(input) => {
                setDateAvalible(input.target.value)
            }} placeholder="Годен до"/>
            <div className={'label_3'}>Стоимость:</div>
            <Input className={'inpChange'} value={cost} onChange={(input) => {
                setCost(input.target.value)
            }} placeholder="Стоимость"/>
        </>

    };


    const post = () => {
        props.setModal()

        let postId = Number(props.element.id);
        let postSubCat = CatId.sub;
        let postBrand = brand;
        let postName = name;
        let postCost = cost === '' ? 0 : cost;
        let postDescription = description;
        let postPackage_name = packName;
        let postDate_avalible = dateAvalible;
        let postPack = Number(pack);
        let postCount = budva_store === '' ? 0 : budva_store;

        //console.log(postId,postSubCat,postBrand,postName,postCost,postDescription,postPackage_name,postDate_avalible,postPack,postCount)
        props.post(postId, postSubCat, postBrand, postName, postCost, postDescription, postPackage_name, postDate_avalible, postPack, postCount)


    }
    const cancelEvent = () => {
        props.setModal();
    }


    const changeSubCategory = (e) => {
        setCatId({...CatId,sub: e})
    }
    const SelectSubCat = (props) => {
        return (
            <>
                <div className={'label_3'}>Подкатегория:</div>
                <Select
                    style={{width: 200}}
                    placeholder="Выберите категорию"
                    optionFilterProp="children"
                    defaultValue={props.defaultValue}
                    onChange={(input, option) => {
                        changeSubCategory(input, option)
                    }}
                >
                    {props.arr}
                </Select>
            </>
        )
    }


    const SelectCat = (props) => {
        return (<>
                <div className={'label_3'}>Категория:</div>
                <Select
                    style={{width: 200}}
                    placeholder="Выберите категорию"
                    optionFilterProp="children"
                    defaultValue={props.defaultValue}
                    onChange={(input, option) => {
                        changeCategory(input, option)
                    }}
                >
                    {props.arr}
                </Select>
            </>
        )
    }


    return (

        <>                <Modal
            title={<Title level={5}>{`${props.element.catname} | ${props.element.name}`}</Title>}
            centered
            visible={true}
            onOk={() => post()}
            onCancel={() => cancelEvent()}
            okText="Изменить"
            cancelText="Отмена"

        >
            {resultElem()}
            <SelectCat defaultValue={CatId.cat} arr={categoryArray()}/>
            <SelectSubCat defaultValue={CatId.sub} arr={subcategoryArray(CatId.cat)}/>

        </Modal>


        </>
    )


}
*/

