import {Table} from "antd";
import React from "react";
import './styles/store.css'

const StoreItem = (props) => {
    return (
        <div className={'store_container'}>
        <Table
            sticky={true}
            loading={props.loading}
            size={'small'}
            bordered={true}
            pagination={false}
            scroll={{y: '61vh'}}
            columns={props.columns}
            dataSource={props.dataSource}
            onChange={props.onChange}
            rowClassName={(record, index) => (index % 2 !== 0 ? "red" : "green")}

        />


        </div>
    )
}


export default StoreItem;