import {Empty, Table, Tag} from 'antd';
import React from "react";
import moment from "moment";




const columns = [
    {
        title: '',
        dataIndex: 'name',
        key: 'name',

    },
    {
        title: '',
        dataIndex: 'value',
        key: 'value',
    },
];

const  getData = (props) => {



    if (props) {

        let result = [
            {
                key: 1,
                name: 'Паспорт',
                value: props.passport
            },
            {
                key: 2,
                name: 'Окончание срока действия паспорта: ',
                value: moment(Number(props.passport_fdate)*1000).format('L')
            },
            {
                key: 3,
                name: 'Боравок',
                value: props.boravok
            },
            {
                key: 4,
                name: 'Окончание срока действия боравка: ',
                value: moment(Number(props.boravok_fdate)*1000).format('L')
            },
            {
                key: 5,
                name: 'Водительское удостоверение' ,
                value: <Tag color="green">{props.driver_license}</Tag>
            },
            {
                key: 5,
                name: 'Окончание срока действия В/У' ,
                value: <Tag color="green">{moment(Number(props.driver_license_fdate)*1000).format('L')}</Tag>
            },


        ];
        return result
    } else {
        return [{
            key: 0,
            name: 'init',
            value: 'init'
        }]
    }

};
const Documents = (props) => {
    if (props.data.length > 0) {
        return  <Table pagination={false} columns={columns} dataSource={getData(props.data[0])} />
    } else {
        return   <Empty description={<span> Доступ к разделу запрещен.</span>} />
    }
};

export default Documents;