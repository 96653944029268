import {Table, Tag, Space, Button, Input} from 'antd';
import React, {Component} from "react";
import {connect} from "react-redux";
import {getObjectsThunkCreator, SETPAGETOOBJAC} from "../../redux/reducers/objectsReducer";
import {SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import './styles/objects.css'
import { LoadingOutlined, DoubleRightOutlined, EditOutlined} from '@ant-design/icons';
import ObjectsListP from "./ObjectsListP";
import moment from "moment";
import AddObject from "./AddObject";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} />;

class ObjectsContainer extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.setPage(0)

    }

    state = {
        sortedInfo: null,
        searchText: '',
        searchedColumn: '',
        editing: false,
        current: {}
    };

    handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            sortedInfo: sorter,
        });
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Поиск по названию`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        size="small"
                        icon={<SearchOutlined/>}
                        style={{width: 100}}
                        onClick={() => {
                            confirm({closeDropdown: false});
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Фильтр
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
                        Сброс
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({searchText: ''});
    };

    exp = (e) => {
        this.props.setPage(1, Number(e))
    };

    edit = (e) => {
        this.setState({
            current: e,
            editing: true
        });
        console.log(e)
    }
    close = () => {
        this.setState({
            current: {},
            editing: false
        });
    }

    render() {

        let {sortedInfo} = this.state;
        sortedInfo = sortedInfo || {};


        this.columns = [

            {
                title: 'Название объекта',
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => (a.name > b.name) ? 1 : -1,
                sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
                ...this.getColumnSearchProps('name'),
                width: '150px',
                fixed: true
            },
            {
                title: 'Рабочее название объекта',
                dataIndex: 'long_name',
                key: 'long_name',
                width: '180px'
            },
            {
                title: 'Имя контактного лица',
                dataIndex: 'contact_name',
                key: 'contact_name',
                width: '150px'
            },
            {
                title: 'Телефон',
                dataIndex: 'contacts',
                key: 'contacts',
                width: '100px'
            },
            {
                title: 'Автополив',
                dataIndex: 'auto_water',
                key: 'auto_water',
                render: text => Number(text) === 1 ? <Tag color={'green'}>есть</Tag> : '',
                width: '80px',
                align: 'center'
            },
            {
                title: 'Последнее посещение',
                dataIndex: 'date_last_service',
                key: 'date_last_service',
                width: '100px',
                align: 'center'
            },
            {
                title: 'Подробнее',
                dataIndex: 'add',
                key: 'add',
                render: text => <DoubleRightOutlined onClick={()=>{this.exp(text.id)}}/>,
                width: '100px',
                align: 'center'

            },
            {
                title: 'Редактировать',
                key: 'id',
                align: 'center',
                width: '100px',
                render: id => (
                    <Space size="middle">
                        <a onClick={() => {
                            this.edit(id)
                        }}><EditOutlined /></a>
                    </Space>
                ),
            },

        ];


        if (this.props.objects && this.props.objects.length > 0) {

            this.districts = Array.from(this.props.objects, ({district}) => district);
            this.districts = Array.from(new Set( this.districts))

            this.loading = false;
            this.Arr = this.props.objects.map((el, index) => {

                return (
                    {
                        id: el.id,
                        key: index + 1,
                        name: el.name,
                        long_name: el.long_name,
                        description: el.description,
                        description_private: el.description_private,
                        contacts: el.contacts,
                        district: el.district,
                        contact_name: el.contact_name,
                        contacts_private: el.contacts_private,
                        contact_name_private: el.contact_name_private,
                        locate: el.locate,
                        nationality: el.nationality,
                        avalible_time: el.avalible_time,
                        avalible_day: el.avalible_day,
                        type: el.type,
                        service_int: el.service_int,
                        visible: el.visible,
                        access: el.access,
                        access_cont: el.access_cont,
                        auto_water: el.auto_water,
                        date_last_service: el.date_last_service ? moment(Number(el.date_last_service)*1000).format('LLLL'): 'н/д',
                        add: {id: el.id, text: '+'}
                    }
                )
            })
        } else {
            this.loading = antIcon;
            this.Arr = [{
                key: 'index',
                name: 'name',
                id: 'id',
            }]
        }

        if (this.state.editing){
            return (
                <AddObject
                    close={() => {
                        this.close()
                    }}
                    post={this.props.post}
                    districts={this.districts}
                    current={this.state.current}
                    editing={this.state.editing}
                />
            )
        } else {
            return (
                <ObjectsListP
                    loading={this.loading}
                    columns={this.columns}
                    dataSource={this.Arr}
                    onChange={this.handleChange}
                    post={this.props.post}
                    districts={this.districts}
                    current={this.current}
                    editing={this.editing}

                />
            )
        }

    }
}


const mapStateToProps = (state) => {
    return {
        objects: state.objectsRDCR.objects,
        page: state.objectsRDCR.page
    }
};


export default connect(mapStateToProps, {
    getObjectsThunkCreator,
    SETPAGETOOBJAC
})(ObjectsContainer);


