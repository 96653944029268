import React, {useState} from "react";
import s from "../../../staff/styles/staff.module.css";
import {Button, ConfigProvider, DatePicker, Divider, Form, Input, Select, Steps, Switch} from "antd";
import locale from "antd/lib/locale/ru_RU";
import moment from "moment";

const AddStaffFinanceExpensive = (props) => {


    let cars = props.cars;


    let defaultState = {
        car_id: cars[0].id,
        car_name: cars[0].name,
        name: '',
        type: 1,
        period: 0,
        cost: 0,
        nds: 0,
        enable: 0,
        id: 0

    }

    let [state, setState] = useState(defaultState)

    let render = cars.map(el => {
        return  <Select.Option value={el.id}>{el.name}</Select.Option>
    })

    const handleChange = (e) => {
        let tempstate = {...state}
        tempstate.id =  Number(e)
        for (let i = 0, len = cars.length; i < len; i++){
            if (Number(cars[i].id) === Number(e)){
                tempstate.car_name = cars[i].name
            }
        }

        setState(tempstate)
        console.log(e)
    }

    const handleChangeType = (e) => {
        let tempstate = {...state}
        tempstate.type =  Number(e)
        setState(tempstate)
        console.log(e)
    }

    const handleChangePeriod = (e) => {
        let tempstate = {...state}
        tempstate.period =  Number(e)
        setState(tempstate)
        console.log(e)
    }

    const handleChangeNds = (e) => {
        let tempstate = {...state}
        tempstate.nds =  Number(e)
        setState(tempstate)
        console.log(e)
    }



    const change = (name, value) => {
        let tempstate = {...state}
        tempstate[name] = value
        setState(tempstate)
    }

    function onChangeEnable(checked) {
        let tempstate = {...state}
        tempstate.enable = checked ? 1 : 0
        setState(tempstate)
    }


    const post = () => {
        console.log(state)
        props.postCars(state, 0);
        props.close()

    }




    return (
        <div className={s.addStaffCont}>

            <div className={s.exit}><a onClick={() => {
                props.close()
            }}>Выход</a></div>
            <Form
                labelCol={{
                    span: 4,
                }}
                wrapperCol={{
                    span: 14,
                }}
                layout="horizontal"
                size={'small'}
            >
                <Divider plain>Основные данные</Divider>
                <Form.Item label="Выберите автомобиль">
                    <Select onChange={handleChange}>
                        {render}
                    </Select>
                </Form.Item>
                <Form.Item label="Укажите пункт расходов">
                    <Input value={state.name} onChange={(val) => {
                        change('name', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Переодичность">
                    <Select onChange={handleChangePeriod}>
                        <Select.Option value={1}>Ежедневно</Select.Option>
                        <Select.Option value={2}>Еженедельно</Select.Option>
                        <Select.Option value={3}>Ежемесячно</Select.Option>
                        <Select.Option value={4}>Ежегодно</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Тип оплаты">
                    <Select onChange={handleChangeType}>
                        <Select.Option value={1}>Наличные</Select.Option>
                        <Select.Option value={2}>Безнал</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Стоимость">
                    <Input value={state.cost} onChange={(val) => {
                        change('cost', val.target.value)
                    }}/>
                </Form.Item>

                {state.type === 2 &&
                <Form.Item label="НДС">
                    <Select onChange={handleChangeNds}>
                        <Select.Option value={0}>БЕЗ НДС</Select.Option>
                        <Select.Option value={7}>НДС 7%</Select.Option>
                        <Select.Option value={21}>НДС 21%</Select.Option>
                    </Select>
                </Form.Item>}


                <Form.Item label="Выплата активна" valuePropName="checked">
                    <Switch checked={Number(state.enable) === 1} onChange={onChangeEnable}/>
                </Form.Item>


                <Button style={{margin: '0 auto', display: 'block'}} onClick={() => {
                    post()
                }}>{Number(props.page) !== 3 ? 'Добавить пункт' : 'Обновить данные'}</Button>
            </Form></div>
    )
}

export default AddStaffFinanceExpensive