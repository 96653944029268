import React, {useState} from "react";
import {CSVLink} from 'react-csv'
import {Button, Select } from "antd";
import s from './styles/analitics.module.css'
import moment from "moment";
import ModalForm from "./forms/ModalForm";
import {storeAPI} from "../../API/API";

const {Option} = Select;

export const ExportReactCSV = ({data, headers, fileName, load}) => {
    return (
        <div>
            {!load && <Button variant="warning" className={s.button_down}>
                <CSVLink data={data} separator={";"} headers={headers} filename={fileName}>Выгрузить таблицу</CSVLink>
            </Button>}
        </div>

    )
}

const AnaliticsMain = (props) => {
    const [modal, setModal] = useState({status: false, data: {}})
    const [interval, setInterval] = useState([0, 0]);
    const [objId, setObjId] = useState(0);
    const [loadingStore, setLoadingStore] = useState(true)
    const [storeArray, setStoreArray] = useState({headers: [], data: [], cat: []})
    const getDataFinance = function(startOfYear, endOfYear){
        props.getData_3(startOfYear, endOfYear)
    }

    let headersTab1 = [
        {label: "Номер", key: "number"},
        {label: "Дата визита/Оплаты", key: "date"},
        {label: "Время", key: "time"},
        {label: "Тип", key: "type"},
        {label: "Сумма", key: "cost"}

    ];
    let headersTab2 = [
        {label: "Номер", key: "number"},
        {label: "Дата визита", key: "date"},
        {label: "Время", key: "time"},
        {label: "Работы", key: "works"}

    ];
    let headersTab3 = [
        {label: "Месяц", key: "month"},
        {label: "Фонд заработной платы", key: "salary"},
        {label: "Разовые траты", key: "one_expenses"},
        {label: "Разовые доходы", key: "one_income"},
        {label: "Платежи (абонентские)", key: "income"},
        {label: "Итог за месяц", key: "result"}
    ];
    let headersTab4 = [
        {label: "Артикль", key: "id"},
        {label: "Наименование", key: "name"},
        {label: "Единицы измерения", key: "measure"},
        {label: "Категория", key: "cat"},
        {label: "Примечание", key: "text"}
    ];
    let store = []
    const getStore = () => {
        const setCategory = (cat, id) => {
            const mainCategoryList =  cat.mainCategory
            const subCategoryList =  cat.subCategory
            let categoriesName = ''

            const setSubCategory = () => {
                let categoryName = ''
                for (let i = 0, l = mainCategoryList.length; i < l; i++){
                    const mainCategoryId = Number(mainCategoryList[i].id)
                    if (mainCategoryId === Number(id) ){
                        categoryName = mainCategoryList[i].name
                    }
                }
                return categoryName
            }

            for (let i = 0, l = subCategoryList.length; i < l; i++){
                const subCategoryId = Number(subCategoryList[i].id)
                if (subCategoryId === Number(id) ){
                    categoriesName = `${setSubCategory(subCategoryList[i].parent_id)} | ${subCategoryList[i].name}`
                }
            }
            return categoriesName
        }
        storeAPI.getStore()
            .then(response => {
                const data = response.data
                const stores = typeof data.stores !== "undefined" ? data.stores : []
                const currentStore =  typeof data.store !== "undefined" ? data.store : []
                const currentCategory = typeof data.category.mainCategory !== "undefined" ? data.category.mainCategory : ''

                for (let i = 0, l = stores.length; i < l; i++) {
                    headersTab4.push({
                        label: stores[i].name,
                        key: `store_${stores[i].name_db}`
                    })
                }

                store = currentStore.map(el => {
                let obj = {}
                for (let item in el) {
                    if (item.includes('store')) {
                        obj[item] = el[item].replace('.',',')
                    }
                }

                return {...obj,
                    id: el.id,
                    name: el.name,
                    measure: `${el.package}${el.package_name}`,
                    cat: setCategory(data.category,el.sub_category),
                    text: el.description
                }
                })

                setLoadingStore(false)
                setStoreArray({headers: headersTab4, data: store, cat: currentCategory})

            })
            .catch(error => console.log(error.message))
    }
    const getName = (id) => {
        const objects = props.objects
        for (let i = 0, len = objects.length; i < len; i++) {
            const currentId = Number(objects[i].id)
            if (currentId === Number(id)) {
                return objects[i].name
            }
        }
    }
    const getDate = (e) => {
        let currentDate = new Date(Number(e) * 1000);
        currentDate.setDate(currentDate.getDate());
        let day = currentDate.getDate()
        let month = currentDate.getMonth() + 1
        let year = currentDate.getFullYear()
        return `${day}.${month}.${year}`
    }
    const getTime = (start, end) => {
        let timeStart = new Date(Number(start) * 1000)
        let timeStartHour = timeStart.getHours() > 9 ? timeStart.getHours() : `0${timeStart.getHours()}`
        let timeStartMinute = timeStart.getMinutes() > 9 ? timeStart.getMinutes() : `0${timeStart.getMinutes()}`
        let timeEnd = new Date(Number(end) * 1000);
        let timeEndHour = timeEnd.getHours() > 9 ? timeEnd.getHours() : `0${timeEnd.getHours()}`
        let timeEndMinute = timeEnd.getMinutes() > 9 ? timeEnd.getMinutes() : `0${timeEnd.getMinutes()}`
        return `${timeStartHour}:${timeStartMinute} - ${timeEndHour}:${timeEndMinute}`
    }
    const getWorks = (data, comments) => {
        const comm = comments.split('`');
        const worksList = typeof props.works !== "undefined" ? props.works : []
        let workInfo = ''

        for (let i = 0, len = data.length; i < len; i++) {
            for (let e = 0, len1 = worksList.length; e < len1; e++) {
                const workName = worksList[e].name
                const workKomment = comm[i] !== undefined && comm[i] !== '' ? '(' + comm[i] + ')' : ''
                const workId = Number(worksList[e].id)
                if (Number(data[i]) === workId) {
                    workInfo += `${workName} ${workKomment} \n`
                }
            }
        }

        return workInfo
    }

    const setYearGen = (year) => {
        let startOfYear = moment().year(year).startOf('year').format('X')
        let endOfYear = moment().year(year).endOf('year').format('X')
        getDataFinance(startOfYear, endOfYear)
    }
    const setFinanceData = (array) => {
        const nameOfMonth = n => moment(n.toString(), 'M').format('MMMM')
        const setSalary = () => {
            const salaryList = typeof array.salary !== "undefined" ? array.salary : []
            return salaryList.reduce((sum, current) => sum + current.rate, 0);
        }

        const setOne_expenses = (n) => {
            let start = moment(n.toString(), 'M').startOf('month').format('X')
            let stop = moment(n.toString(), 'M').endOf('month').format('X')
            let oneExpensesList = typeof array.one_expenses !== "undefined" ? array.one_expenses : []
            return oneExpensesList.reduce((sum, current) => sum + (+current.date > start && +current.date < stop) ? current.cost : 0, 0);
        }

        const setOne_income = (n) => {
            let start = moment(n.toString(), 'M').startOf('month').format('X')
            let stop = moment(n.toString(), 'M').endOf('month').format('X')
            let oneIncomeList = typeof array.oneIncomeList !== "undefined" ? array.oneIncomeList : []
            return oneIncomeList.reduce((sum, current) => sum + (+current.date > start && +current.date < stop) ? current.cost : 0, 0);
        }

        const setIncome = (n) => {
            let start = moment(n.toString(), 'M').startOf('month').format('X')
            let stop = moment(n.toString(), 'M').endOf('month').format('X')
            let payments = typeof array.payments !== "undefined" ? array.payments : []
            return payments.reduce((sum, current) => sum + (+current.date > start && +current.date < stop) ? current.cost : 0, 0);
        }

        const setResult = (i) => {
            return (setOne_income(i) + setIncome(i)) - (setSalary() + setOne_expenses(i))
        }

        let financeData = []
        for (let i = 1, len = 13; i < len; i++) {
            financeData.push({
                month: nameOfMonth(i),
                salary: setSalary().toFixed(2),
                one_expenses: setOne_expenses(i).toFixed(2),
                one_income: setOne_income(i).toFixed(2),
                income: setIncome(i).toFixed(2),
                result: setResult(i).toFixed(2)
            })
        }

        return financeData
    }
    const financeData = setFinanceData(props.finance)

    let obj = props.objects.length > 1 ? props.objects.map(el => (
        <Option key={el.id} value={el.id}>{`${el.name} ${el.long_name !== '' ? '(' + el.long_name + ')' : ''}`}</Option>
    )) : []

    let years = []
    for (let i = 0, len = 5; i < len; i++) {
        years.push(<Option key={i} value={2020 + i}>{2020 + i}</Option>)
    }
    let reports = props.data.reports && props.data.reports.length > 0 ? props.data.reports.map((el, index) => {
        return {
            number: index + 1,
            date: getDate(el.time_plan),
            time: getTime(el.time_start, el.time_end),
            type: 'Обслуживание',
            works: getWorks(el.works_id.split(','), el.comments_id)
        }
    }) : []
    let payments = props.data.payments && props.data.payments.length > 0 ? props.data.payments.map((el, index) => {
        return {number: index + 1, date: getDate(el.date), type: 'Оплата', cost: el.cost}
    }) : []
    let dataTable1 = [...reports, ...payments]


    const giveDate = (data) => {
        let startTime, stopTime = 0;
        if (data && data[0] !== null) {
            startTime = moment(data[0].startOf('day').toString()).format('X')
        }
        if (data && data[1] !== null) {
            stopTime = moment(data[1].endOf('day').toString()).format('X')
        }
        setInterval([startTime, stopTime])
    }
    const getData = (id) => {
        props.getData_1(interval[0], interval[1], id)
    }
    const cancel = () => {
        console.log('cancel')
        setModal({status: false, data: {}})
    }
    const openModal = (id) => {

        let title = 'Таблица: '
        switch (id) {
            case 1:
                title += 'Даты обслуживания и оплаты';
                break;
            case 2:
                title += 'Даты обслуживания и работы';
                break;
            case 3:
                title += 'Финансовые показатели';
                break;
            case 4:
                title += 'Склад';
                break;
            default:
                title = ''
        }

        let data = {
            title: title,
            id: id
        }
        if (id === 4) {
            getStore()
        }
        setModal({status: true, data: data})
    }


    return (
        <div className={s.main__cont}>
            <div className="col-md-4 center">
                <Button className={s.button_down} onClick={() => openModal(1)}>
                    Даты обслуживания и оплаты
                </Button>
            </div>
            <div className="col-md-4 center">
                <Button className={s.button_down} onClick={() => openModal(2)}>
                    Даты обслуживания и виды работ
                </Button>
            </div>
            <div className="col-md-4 center">
                <Button className={s.button_down} onClick={() => openModal(3)}>
                    Финансовые показатели
                </Button>
            </div>
            <div className="col-md-4 center">
                <Button className={s.button_down} onClick={() => openModal(4)}>
                    Склад
                </Button>
            </div>

            {modal.status && modal.data.id === 1 && <ModalForm
                visible={modal.status}
                selectVisible={true}
                title={modal.data.title}
                cancel={cancel}
                okText={'Закрыть'}
                cancelText={'Отмена'}
                rangepicker={true}
                rpChange={giveDate}
                options={obj}
                onChangeSel={setObjId}
                placeholderSel={'Выберите объект'}
                buttonOnClick={getData}
                buttonLoad={props.loading}
                objects={props.objects}
                loading={props.loading}
                data={dataTable1}
                headers={headersTab1}
                filename={`${getName(objId)} ${getDate(interval[0])} - ${getDate(interval[1])}.csv`}

            />}
            {modal.status && modal.data.id === 2 && <ModalForm
                visible={modal.status}
                selectVisible={true}
                title={modal.data.title}
                cancel={cancel}
                okText={'Закрыть'}
                cancelText={'Отмена'}
                rangepicker={true}
                rpChange={giveDate}
                options={obj}
                onChangeSel={setObjId}
                placeholderSel={'Выберите объект'}
                buttonOnClick={getData}
                buttonLoad={props.loading}
                objects={props.objects}
                loading={props.loading}
                data={reports}
                headers={headersTab2}
                filename={`${getName(objId)} ${getDate(interval[0])} - ${getDate(interval[1])}.csv`}

            />}
            {modal.status && modal.data.id === 3 && <ModalForm
                visible={modal.status}
                title={modal.data.title}
                selectVisible={true}
                cancel={cancel}
                okText={'Закрыть'}
                cancelText={'Отмена'}
                rangepicker={false}
                rpChange={giveDate}
                options={years}
                onChangeSel={setYearGen}
                placeholderSel={'Выберите год'}
                buttonOnClick={()=>{}}
                buttonLoad={props.loading}
                objects={props.objects}
                loading={props.loading}
                data={financeData}
                headers={headersTab3}
                filename={`Финансовые показатели.csv`}

            />}

            {modal.status && modal.data.id === 4 && <ModalForm
                visible={modal.status}
                title={modal.data.title}
                selectVisible={false}
                cancel={cancel}
                okText={'Закрыть'}
                cancelText={'Отмена'}
                rangepicker={false}
                rpChange={giveDate}
                options={years}
                onChangeSel={setYearGen}
                placeholderSel={'Выберите год'}
                buttonOnClick={getData}
                buttonLoad={loadingStore}
                objects={props.objects}
                loading={loadingStore}
                data={storeArray.data}
                headers={storeArray.headers}
                checkboxData={storeArray.cat}
                filename={`Склад ${moment().format('DD.MM.YYYY')}.csv`}

            />}


        </div>
    )
}


export default AnaliticsMain