import React, {useState} from "react";
import Title from "antd/lib/typography/Title";
import {Checkbox, Row, Col, Collapse, Modal, Tag, Popover} from 'antd';
import '../styles/planning.change.css'
import { InfoCircleOutlined, StarFilled, StarTwoTone } from '@ant-design/icons';
import moment from "moment";
const { Panel } = Collapse;

const PlanObjectsChange = (props) => {



    let [changedObjects, setchangedObjects] = useState([])

    const onChange = (checkedValues) => {
        let tempArray = changedObjects;
        for (let i = 0, len = checkedValues.length; i < len; i++){
            if (!changedObjects.includes(checkedValues[i])){
                tempArray.push(checkedValues[i])
            }
        }
        setchangedObjects(tempArray)
    }
    const post = (e) => {
        props.post(changedObjects)
        props.close(0)
    }
    const cancelEvent = (e) => {
        props.close(0)
    }

    const Service = ({date_last_service}) => {

        const now = moment()
        const serviceDate = moment(Number(date_last_service)*1000)
        const deff = now.diff(serviceDate, 'days')
        const color = Number(deff) > 5 ? 'volcano' : 'lime'

        return(

                <Tag color={color}>{moment(Number(date_last_service)*1000).format('L')}</Tag>


        )
    }

    let districts = Array.from(props.objects, ({district}) => district);
    districts = Array.from(new Set(districts))
    districts.push('Разовые объекты')

    const objects = props.objects.map((el) => {

        let flag = false;
        let comment =   el.comment_last_service !== '' ? <div><p>{el.comment_last_service}</p></div> : '';

        let name = el.long_name !== '' ? `${el.name} (${el.long_name})`: `${el.name}`
        let visiblestr = <div className={'label_cont'}>

            <div className={'label_name'}>{!el.disabled ? name  : <span style={{color: 'red'}}>{name}</span>}</div>
            <div className={'label_props__one'}>
                {el.date_last_service !=='' ? <Service date_last_service={el.date_last_service}/>: ''}
                {comment !== '' &&
                <Popover content={comment} title="Комментарий с последнего сервиса">
                    <InfoCircleOutlined style={{color: 'red'}}/>
                </Popover>}
            </div>
            { comment !== '' &&
            <div className={'label_props'}></div>}
        </div>;
        for (let i = 0, len = props.defaultObjects.length; i < len; i++) {
            if (Number(props.defaultObjects[i].id) === Number(el.id)) {
                flag = true;
            }
        }
        return flag
            ?
            <Col key={el.id} type={el.type} district={el.district} span={14}><Checkbox disabled={true} value={el.id}>{visiblestr}</Checkbox></Col>
            :
            <Col key={el.id} type={el.type} district={el.district} span={14}><Checkbox  disabled={false} value={el.id}>{visiblestr}</Checkbox></Col>

    }).filter((el) => {
        return el !== ''
    });


    let render = districts.map((el,index) => {

        let obj = []
        for (let i = 0, len = objects.length; i < len; i++) {
            if ((objects[i].props.district === el && Number (objects[i].props.type) === 1) || (el === 'Разовые объекты' && Number (objects[i].props.type) === 2)) {
                obj.push(objects[i])
            }
        }
       return (

        <Panel header={el} key={index}>
               <Checkbox.Group style={{width: '100%', userSelect: 'none'}} onChange={onChange}>
                   <Row>
                       {obj}
                   </Row>
               </Checkbox.Group>
           </Panel>
       )
    }

)









    return (
        <Modal
            title={<Title level={5}>{`Добавление / изменение объектов | ${props.title}`}</Title>}
            centered
            visible={true}
            onOk={() => post()}
            onCancel={() => cancelEvent()}
            okText="Сохранить"
            cancelText="Отмена"

        >




            <Collapse accordion>
                {render}
            </Collapse>







{/*            <Checkbox.Group style={{width: '100%', userSelect: 'none'}} onChange={onChange}>
                <Row>
                    {objects}
                </Row>
            </Checkbox.Group>*/}

            {/*            { alert ? <Alert message="Нельзя переместить больше, чем есть..." type="error"  showIcon/> : ''}
            { isNull ? <Alert message="Укажите корректное количество..." type="error"  showIcon/> : ''}
            { transfer ? <Alert message="Перемещение выполнено!" type="success" showIcon/> : ''}*/}
        </Modal>
    )

}


export default PlanObjectsChange