
import React from "react";
import {Divider, Form} from "antd";
import s from '../../styles/finance.module.css'


const OneFinanceInfo = (props) => {

    let summRateCash = 0;
    let summRateCashless = 0;
    let summAll = 0;
    let summAllNds = 0;




    for (let i = 0, len = props.one_expenses.length; i < len; i++){
        summRateCash +=  Number(props.one_expenses[i].type) === 1 ? Number(props.one_expenses[i].cost) : 0
        summRateCashless +=  Number(props.one_expenses[i].type) === 2 ? Number(props.one_expenses[i].cost) : 0
        summAll +=  Number(props.one_expenses[i].cost)
        summAllNds +=  Number(props.one_expenses[i].type) === 2 ? Number(props.one_expenses[i].cost) * (1+(Number(props.one_expenses[i].nds)/100)) : 0
    }




    return (
        <div>
            <div className={s.maintitle}>Единоразовые расходы</div>
            <div className={s.mainCont}>
                <div className={s.financeStaffCont}>
                    <div className={s.financeStaffTitle}>Затраты всего</div>
                    <div className={s.financeStaffContent}>{`${summAll.toFixed(2)} €`}</div>
                </div>
                <div className={s.financeStaffCont}>
                    <div className={s.financeStaffTitle}>Из них наличными</div>
                    <div className={s.financeStaffContent}>{`${summRateCash.toFixed(2)} €`}</div>
                </div>
                <div className={s.financeStaffCont}>
                    <div className={s.financeStaffTitle}>Из них безнал</div>
                    <div className={s.financeStaffContent}>{`${summRateCashless.toFixed(2)} €`}</div>
                </div>

                <div className={s.financeStaffCont}>
                    <div className={s.financeStaffTitle}>Всего с учетом НДС</div>
                    <div className={s.financeStaffContent}>{`${summAllNds.toFixed(2)} €`}</div>
                </div>
            </div>
        </div>

    )
}

export default OneFinanceInfo