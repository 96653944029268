import {dataAPI, financeAPI, objectAPI} from "../../API/API";
import {GETOBJAC} from "./objectsReducer";
import {GETRULESSTAC, GETSTAFFAC} from "./staffReducer";
import {GETCARSAC} from "./planningReducer";

const GETARTICLES = 'GET-ARTICLES-FIN'
const GETSTAFFFIN = 'GET-STAFF-FIN'
const GETSALARYFIN = 'GET-SALARY-FIN'
const SETLOADFIN = 'SET-LOAD-FIN'
const GETCARSIN = 'GET-CARS-FIN'
const GETCARSEXPENSESFIN = 'GET-ARSEXPENSES-FIN'
const GETRENTIN = 'GET-RENT-FIN'
const GETOPERFIN = 'GET-OPER-FIN'
const GETINCOMEFIN = 'GET-INCOME-FIN'
const GETOBJFIN = 'GET-OBJ-FIN'
const GETONEIN = 'GET-ONE-FIN'
const GETONEINCOMEIN = 'GET-ONEINCOME-FIN'
const GETPAYMENTSFIN = 'GET-PAYMENTS-FIN'



let initialState = {
    articles_expenses: [],
    staff: [],
    salary: [],
    loading: true,
    cars: [],
    cars_expenses: [],
    rent_expenses: [],
    oper_expenses: [],
    income: [],
    objects: [],
    one_expenses: [],
    one_income: [],
    payments: []

};


// Редюссер конфигурации
const FinanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case GETARTICLES: {
            return {
                ...state,
                articles_expenses: action.articles_expenses
            }
        }
        case GETPAYMENTSFIN: {
            return {
                ...state,
                payments: action.payments
            }
        }
        case GETONEINCOMEIN: {
            return {
                ...state,
                one_income: action.one_income
            }
        }
        case GETONEIN: {
            return {
                ...state,
                one_expenses: action.one_expenses
            }
        }
        case GETOBJFIN: {
            return {
                ...state,
                objects: action.objects
            }
        }
        case GETRENTIN: {
            return {
                ...state,
                rent_expenses: action.rent_expenses
            }
        }
        case GETOPERFIN: {
            return {
                ...state,
                oper_expenses: action.oper_expenses
            }
        }
        case GETINCOMEFIN: {
            return {
                ...state,
                income: action.income
            }
        }
        case GETCARSIN: {
            return {
                ...state,
                cars: action.cars
            }
        }
        case GETCARSEXPENSESFIN: {
            return {
                ...state,
                cars_expenses: action.cars_expenses
            }
        }
        case SETLOADFIN: {
            return {
                ...state,
                loading: action.loading
            }
        }
        case GETSTAFFFIN: {
            return {
                ...state,
                staff: action.staff
            }
        }
        case GETSALARYFIN: {
            return {
                ...state,
                salary: action.salary
            }
        }
        default:
            return state;
    }

};

let GETARTICLESAC = (articles_expenses) => ({type: GETARTICLES, articles_expenses});
let GETSTAFFFINAC = (staff) => ({type: GETSTAFFFIN, staff});
let GETSALARYFINAC = (salary) => ({type: GETSALARYFIN, salary});
let SETLOADFINAC = (load) => ({type: SETLOADFIN, load});
let GETCARSINAC = (cars) => ({type: GETCARSIN, cars});
let GETCARSEXPENSESFINAC = (cars_expenses) => ({type: GETCARSEXPENSESFIN, cars_expenses});
let GETRENTINAC = (rent_expenses) => ({type: GETRENTIN, rent_expenses});
let GETOPERFINAC = (oper_expenses) => ({type: GETOPERFIN, oper_expenses});
let GETINCOMEFINAC = (income) => ({type: GETINCOMEFIN, income});
let GETOBJFINAC = (objects) => ({type: GETOBJFIN, objects});
let GETONEINAC = (one_expenses) => ({type: GETONEIN, one_expenses});
let GETONEINCOMEINAC = (one_income) => ({type: GETONEINCOMEIN, one_income});
let GETPAYMENTSFINAC = (payments) => ({type: GETPAYMENTSFIN, payments});


export const delPaymentThunkCreator = (id) => async (dispatch) => {
    dispatch(SETLOADFINAC(true));
    let response = await financeAPI.delPayments(id);
    if (response !== false) {
        response = await financeAPI.getPayments();
        if (response) {
            dispatch(GETPAYMENTSFINAC(response.data.payments));
            if (response) {
                response = await financeAPI.getIncome();
                if (response) {
                    dispatch(GETINCOMEFINAC(response.data.income));
                    dispatch(SETLOADFINAC(false));
                }
            }
        }
    }

}



export const getArticlesThunkCreator = () => async (dispatch) => {
    let response = await financeAPI.getArticles();
    if (response) {
        dispatch(GETARTICLESAC(response.data.articles_expenses));
        response = await dataAPI.getStaff();
        if (response) {
            dispatch(GETSTAFFFINAC(response.data.staff));
            response = await financeAPI.getSalary();
            if (response) {
                dispatch(GETSALARYFINAC(response.data.salary));
                response = await dataAPI.getCars();
                if (response) {
                    dispatch(GETCARSINAC(response.data.cars));
                    response = await financeAPI.getCarsExpenses();
                    if (response) {
                        dispatch(GETCARSEXPENSESFINAC(response.data.cars_expenses));
                        response = await financeAPI.getRentExpenses();
                        if (response) {
                            dispatch(GETRENTINAC(response.data.rent_expenses));
                            response = await financeAPI.getOperExpenses();
                            if (response) {
                                dispatch(GETOPERFINAC(response.data.oper_expenses));
                                response = await financeAPI.getIncome();
                                if (response) {
                                    dispatch(GETINCOMEFINAC(response.data.income));
                                    response = await objectAPI.getObjects();
                                    if (response) {
                                        dispatch(GETOBJFINAC(response.data.objects));
                                        response = await financeAPI.getOneExpenses();
                                        if (response) {
                                            dispatch(GETONEINAC(response.data.one_expenses));
                                            response = await financeAPI.getOneIncome();
                                            if (response) {
                                                dispatch(GETONEINCOMEINAC(response.data.one_income));
                                                response = await financeAPI.getPayments();
                                                if (response) {
                                                    dispatch(GETPAYMENTSFINAC(response.data.payments));
                                                    dispatch(SETLOADFINAC(false));
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    }
};





export const postStaffSalaryThunkCreator = (state, update) => async (dispatch) => {
    dispatch(SETLOADFINAC(true));
    let response = await financeAPI.postSalary(state, update);
    if (response) {
        response = await financeAPI.getSalary();
        if (response) {
            dispatch(GETSALARYFINAC(response.data.salary));
            dispatch(SETLOADFINAC(false));
        }
    }

};
export const postIncomeThunkCreator = (state, update) => async (dispatch) => {
    dispatch(SETLOADFINAC(true));
    let response = await financeAPI.postIncome(state, update);
    if (response) {
        response = await financeAPI.getIncome();
        if (response) {
            dispatch(GETINCOMEFINAC(response.data.income));
            dispatch(SETLOADFINAC(false));
        }
    }

};




export const postCarsExpensesThunkCreator = (state, update) => async (dispatch) => {
    dispatch(SETLOADFINAC(true));
    let response = await financeAPI.postCarsExpenses(state, update);
    if (response) {
        response = await financeAPI.getCarsExpenses();
        if (response) {
            dispatch(GETCARSEXPENSESFINAC(response.data.cars_expenses));
            dispatch(SETLOADFINAC(false));
        }
    }

};
export const postPaymentThunkCreator = (state, update) => async (dispatch) => {
    dispatch(SETLOADFINAC(true));
    let response = await financeAPI.postPayment(state, update);
    if (response) {
        response = await financeAPI.getPayments();
        if (response) {
            dispatch(GETPAYMENTSFINAC(response.data.payments));
            if (response) {
                response = await financeAPI.getIncome();
                if (response) {
                    dispatch(GETINCOMEFINAC(response.data.income));
                    dispatch(SETLOADFINAC(false));
                }
            }
        }
    }

};


export const postRentExpensesThunkCreator = (state, update) => async (dispatch) => {
    dispatch(SETLOADFINAC(true));
    let response = await financeAPI.postRentExpenses(state, update);
    if (response) {
        response = await financeAPI.getRentExpenses();
        if (response) {
            dispatch(GETRENTINAC(response.data.rent_expenses));
            dispatch(SETLOADFINAC(false));
        }
    }

};



export const postOperExpensesThunkCreator = (state, update) => async (dispatch) => {
    dispatch(SETLOADFINAC(true));
    let response = await financeAPI.postOperExpenses(state, update);
    if (response) {
        response = await financeAPI.getOperExpenses();
        if (response) {
            dispatch(GETOPERFINAC(response.data.oper_expenses));
            dispatch(SETLOADFINAC(false));
        }
    }

};

export const postOneExpensesThunkCreator = (state, update) => async (dispatch) => {
    dispatch(SETLOADFINAC(true));
    let response = await financeAPI.postOneExpenses(state, update);
    if (response) {
        response = await financeAPI.getOneExpenses();
        if (response) {
            dispatch(GETONEINAC(response.data.one_expenses));
            dispatch(SETLOADFINAC(false));
        }
    }

};

export const postOneIncomeThunkCreator = (state, update) => async (dispatch) => {
    dispatch(SETLOADFINAC(true));
    let response = await financeAPI.postOneIncome(state, update);
    if (response) {
        response = await financeAPI.getOneIncome();
        if (response) {
            dispatch(GETONEINCOMEINAC(response.data.one_income));
            dispatch(SETLOADFINAC(false));
        }
    }

};

export default FinanceReducer;

