


import React from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ReactDOM from "react-dom";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import store from "./redux/redux-store";
import {Provider} from "react-redux";


ReactDOM.render(

        <BrowserRouter>
            <Provider store={store}>
                <App store = {store} />
            </Provider>
        </BrowserRouter>, document.getElementById('root')
);

reportWebVitals();




