import React, {Component} from 'react';
import {Button} from "antd";
import {connect} from "react-redux";
import {getReportsByObjectIdThunkCreator, SETFILTEROBJAC} from "../../redux/reducers/objectsReducer";
import ObjectProfile from "./ObjectProfile";
import ObjectProfileTest from "./ObjectProfileTest";
import ObjectReports from "./ObjectReports";
import moment from 'moment';

class Objects_item extends Component {

    constructor (props){
        super(props)
    }
    onSelect = () => {
        console.log()
        let monthStart = (moment().startOf('month')).format('L').replace(/[\.]/g,'-')
        let monthEnd = (moment().endOf('month')).format('L').replace(/[\.]/g,'-')
        monthStart = monthStart.split('-')
        monthStart = `${monthStart[2]}-${monthStart[1]}-${monthStart[0]}`
        monthStart = new Date(monthStart).getTime()
        monthStart = (monthStart/1000).toFixed(0)
        monthEnd = monthEnd.split('-')
        monthEnd = `${monthEnd[2]}-${monthEnd[1]}-${monthEnd[0]}`
        monthEnd = new Date(monthEnd).getTime()
        monthEnd = (monthEnd/1000).toFixed(0)
        this.props.getReportsByObjectIdThunkCreator(Number(this.props.elem.id),Number(monthStart), Number(monthEnd))

    };

    componentDidMount() {
        this.onSelect()
    }
    render() {
        if (this.props.elem.id) {
            return (
                <ObjectProfileTest
                    reports={this.props.reports}
                    elem={this.props.elem}
                    setPage={this.props.setPage}
                    filter={this.props.SETFILTEROBJAC}
                    start={this.props.filter.start}
                    end={this.props.filter.end}
                    getLinks={this.props.getLinks}
                    links={this.props.links}
                    getReports={this.props.getReports}
                />
            )
        } else {
            return (
                <div></div>
            )
        }

    }
}

const mapStateToProps = (state) => {
    return {
        reports: state.objectsRDCR.reports,
        filter: state.objectsRDCR.filter,
        links: state.objectsRDCR.links,
    }
};


export default connect(mapStateToProps, {
    getReportsByObjectIdThunkCreator,
    SETFILTEROBJAC
})(Objects_item);