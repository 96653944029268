import React, {useState} from "react";
import {Divider, Tag, Descriptions, ConfigProvider, Collapse, Calendar, Badge} from "antd";
import s from './styles/profile.module.css'
import {CloseSquareOutlined} from '@ant-design/icons';
import locale from 'antd/lib/locale/ru_RU';
import './styles/objects.css'
import ModalReports from "./ModalReports";
import moment from 'moment'

const {Panel} = Collapse;

const ObjectProfileTest = (props) => {

    let [prewiewElem, setPreviewElem] = useState({visible: false, elem: {}})
    let [time, setTime] = useState({month: moment().format('MMMM'), year: moment().format('YYYY ')})




    const onSelect = (value) => {
        console.log(value)
        let monthStart = (value.startOf('month')).format('L').replace(/[\.]/g,'-')
        let monthEnd = (value.endOf('month')).format('L').replace(/[\.]/g,'-')
        monthStart = monthStart.split('-')
        monthStart = `${monthStart[2]}-${monthStart[1]}-${monthStart[0]}`
        monthStart = new Date(monthStart).getTime()
        monthStart = (monthStart/1000).toFixed(0)
        monthEnd = monthEnd.split('-')
        monthEnd = `${monthEnd[2]}-${monthEnd[1]}-${monthEnd[0]}`
        monthEnd = new Date(monthEnd).getTime()
        monthEnd = (monthEnd/1000).toFixed(0)
        props.getReports(Number(props.elem.id),Number(monthStart), Number(monthEnd))

    };



    const onTest = (e) => {
        let ns = new moment(e);
        let ne = new moment(e);
        let starttime = (ns.startOf('month').subtract(1,'month')).format('X')
        let endtime = (ne.endOf('month').add(1,'month')).format('X')
        setTime ({month: e.format('MMMM'), year: e.format('YYYY ')})
        props.getReports(Number(props.elem.id),Number(starttime), Number(endtime))
    }

    const prewiewReport = (e) => {
        let t_start = moment(Number(e.time_plan)*1000).startOf('day').format('X')
        let t_end = moment(Number(e.time_plan)*1000).endOf('day').format('X')
        setPreviewElem({visible: true, elem: e})
        props.getLinks(Number(e.object_id),Number(t_start),Number(t_end))
    }


    function getListData(value) {

        //функция возвращает дату по таймштамп
        const setDate = (timestamp) => {
            let d = new Date(Number(timestamp) * 1000)
            return d.getDate()
        }
        //функция возвращает месяц по таймштамп
        const setMonth = (timestamp) => {
            let d = new Date(Number(timestamp) * 1000)
            return Number(d.getMonth()) + 1
        }


        const setHours = (start,end) => {
            let timestart = new Date(Number(start) * 1000)
            let timestartHour = timestart.getHours() > 9 ? timestart.getHours() : `0${timestart.getHours()}`
            let timestartMinute = timestart.getMinutes() > 9 ? timestart.getMinutes() : `0${timestart.getMinutes()}`
            let timeend = new Date(Number(end) * 1000)
            let timeendHour = timeend.getHours() > 9 ? timeend.getHours() : `0${timeend.getHours()}`
            let timeendMinute = timeend.getMinutes() > 9 ? timeend.getMinutes() : `0${timeend.getMinutes()}`
            return `${timestartHour}:${timestartMinute} - ${timeendHour}:${timeendMinute}`

        }


        return props.reports.reports.map(el => {
                return setDate(el.time_plan) === value.date() && setMonth(el.time_plan) === Number(value.format('M')) ? {type: 'success', content: <div className={s.report_calendar_item_all} onClick={() => {prewiewReport(el)}}>{setHours(el.time_start,el.time_end)}</div>} : ''
        }).filter(el => el !== '') || [];
    }

    function dateCellRender(value) {
        const listData = getListData(value);
        return (
            <ul className="events">
                {listData.map(item => (
                    <li key={item.content}>
                        <Badge status={item.type} text={item.content}/>
                    </li>
                ))}
            </ul>
        );
    }
    function disabledDate(current) {
        // Can not select days before today and today
        return current.format('MMMM') !== time.month;
    }
    function getMonthData(value) {
        console.log(value)
    }

    function monthCellRender(value) {
        const num = getMonthData(value);
        return num ? (
            <div className="notes-month">
                <section>{num}</section>
                <span>Backlog number</span>
            </div>
        ) : null;
    }
    const setType = (id) => {
        switch (Number(id)) {
            case 1:
                return 'постоянный'
            case 2:
                return 'разовый'
            case 3:
                return 'архивный'
            default:
                return 'не определен'
        }
    }
    const setAccess = (id) => {
        switch (Number(id)) {
            case 1:
                return 'открытый'
            case 2:
                return 'ключ у садовников'
            case 3:
                return 'через контактное лицо'
            case 4:
                return 'ключ на объекте'
            default:
                return 'не определен'
        }
    }
    const setAvalibleDay = (str) => {
        const setDay = (id) => {
            switch (Number(id)) {
                case 1:
                    return 'пн '
                case 2:
                    return 'вт '
                case 3:
                    return 'ср '
                case 4:
                    return 'чт '
                case 5:
                    return 'пт '
                case 6:
                    return 'сб '
                case 7:
                    return 'вс '
                default:
                    return 'Не определен'
            }
        }
        let arr = str ? str.split(',') : [];
        let result = ''
        if (arr.length > 1) {
            arr.forEach((el, index) => {
                result += Number(el) !== 0 ? setDay(index + 1) : ''
            })
        } else {
            result = 'ежедневно'
        }


        return result
    }
    const setDate = () => {

        let len = props.reports.reports.length
        let currentDate = props.reports.reports[len - 1] ? Number(props.reports.reports[len - 1].time_plan) : 0
        if (currentDate !== 0 && currentDate !== []) {
            currentDate = new Date(currentDate * 1000)
            let timestart = new Date(Number(props.reports.reports[len - 1].time_start) * 1000)
            let timeend = new Date(Number(props.reports.reports[len - 1].time_end) * 1000)
            let day = currentDate.getDate()
            let month = currentDate.getMonth() + 1
            let year = currentDate.getFullYear()
            return `${day}.${month}.${year} ${timestart.getHours()}:${timestart.getMinutes() < 10 ? `0${timestart.getMinutes()}` : timestart.getMinutes()}-${timeend.getHours()}:${timeend.getMinutes() < 10 ? `0${timeend.getMinutes()}` : timeend.getMinutes()}`
        } else {
            return 'Не известно'
        }

    }
    const setCoutOfService = () => {
        let now = new Date()
        let nowMonth = now.getMonth() + 1
        let count = 0;
        const check = (time) => {
            let currentDate = new Date(time * 1000)
            let month = currentDate.getMonth() + 1
            return month === nowMonth
        }
        for (let i = 0, len = props.reports.reports.length; i < len; i++) {
            if (check(Number(props.reports.reports[i].time_plan))) {
                count++
            }
        }
        return count
    }

    const getObjectNameById = (id) => {
        let name = ''
        props.objects.forEach(el => {
            name += Number(el.id) === Number(id) ? el.name : ''
        })
        return name
    }



    return (

        <>
            <div onClick={() => {
                props.setPage(0)
            }}><CloseSquareOutlined height={20} style={{
                color: 'red',
                display: 'block',
                float: 'right',
                marginRight: '20px',
                marginTop: '10px',
                fontSize: '20px',
                userSelect: 'none',
                cursor: 'pointer'


            }}/></div>
            <Divider className={s.obj_name} orientation="left" plain>
                {props.elem.name}
            </Divider>
            <div className={s.profile_container_main}>


                <div className={s.profile_container}>
                    <Collapse accordion>
                        <Panel header={"Общая информация"} key={'1'}>
                            <Descriptions size={'small'} column={1} title="Общая информация" layout="horizontal">
                                <Descriptions.Item
                                    label="Рабочее название"><b>{props.elem.long_name}</b></Descriptions.Item>
                                <Descriptions.Item
                                    label="Тип объекта"><b>{setType(props.elem.type)}</b></Descriptions.Item>
                                <Descriptions.Item
                                    label="Описание (скрытое)"><b>{props.elem.description_private}</b></Descriptions.Item>
                                <Descriptions.Item label="Описание"><b>{props.elem.description}</b></Descriptions.Item>
                                <Descriptions.Item
                                    label="Доступ"><b>{setAccess(props.elem.access)}</b></Descriptions.Item>
                                <Descriptions.Item
                                    label="Рабочие дни"><b>{setAvalibleDay(props.elem.avalible_day)}</b></Descriptions.Item>
                                <Descriptions.Item
                                    label="Рабочее время"><b>{+props.elem.avalible_time === 0 ? 'весь день' : props.elem.avalible_time}</b></Descriptions.Item>
                                <Descriptions.Item label="Автополив"><b>{+props.elem.auto_water === 0 ?
                                    <Tag color="red">Нет</Tag> :
                                    <Tag color="green">Есть</Tag>}</b></Descriptions.Item>
                                <Descriptions.Item label="Локация"><b>{props.elem.locate}</b></Descriptions.Item>
                                <Descriptions.Item
                                    label="Видимость"><b>{Number(props.elem.visible) === 1 ? 'видимый' : 'скрытый'}</b></Descriptions.Item>
                            </Descriptions>
                        </Panel>

                        <Panel header={"Контактная информация"} key={'2'}>
                            <Descriptions size={'small'} column={1} title="Контактная информация" layout="horizontal">
                                <Descriptions.Item
                                    label="Контактное лицо (скрытый)"><b>{props.elem.contact_name_private}</b></Descriptions.Item>
                                <Descriptions.Item
                                    label="Телефон (скрытый)"><b>{props.elem.contacts_private}</b></Descriptions.Item>
                                <Descriptions.Item
                                    label="Контактное лицо (видимый)"><b>{props.elem.contact_name}</b></Descriptions.Item>
                                <Descriptions.Item
                                    label="Телефон (видимый)"><b>{props.elem.contacts}</b></Descriptions.Item>
                                <Descriptions.Item label="Адрес"><b>{``}</b></Descriptions.Item>
                                <Descriptions.Item label="Национальность владельца">{props.elem.nationality !== '' ?
                                    <Tag color="blue">{(props.elem.nationality).toUpperCase()}</Tag> : ''}</Descriptions.Item>
                            </Descriptions>
                        </Panel>
                        <Panel header={"Обслуживание"} key={'3'}>
                            <Descriptions size={'small'} column={1} title="Обслуживание" layout="horizontal">
                                <Descriptions.Item
                                    label="Дата последнего обслуживания"><b>{setDate()}</b></Descriptions.Item>
                                <Descriptions.Item
                                    label="Комментарий с последнего обслуживания"><b>{props.elem.comment_last_service}</b></Descriptions.Item>
                                <Descriptions.Item label="Обслуживаний за месяц"><Tag
                                    color="blue">{setCoutOfService()}</Tag></Descriptions.Item>
                            </Descriptions>
                        </Panel>
                        <Panel header={"Календарь"} key={'4'}>
                            <div className={s.report__title}>Обслуживание объекта</div>
                            <ConfigProvider locale={locale}>
                                <Calendar dateCellRender={dateCellRender}
                                          monthCellRender={monthCellRender}
                                          disabledDate={disabledDate}
                                          onPanelChange={onTest}/>
                            </ConfigProvider>
                        </Panel>
                    </Collapse>

                </div>
            </div>

            {prewiewElem.visible ? <ModalReports     title={props.elem.name} links={props.links} elem={prewiewElem.elem} template={props.reports.template} close={()=>{setPreviewElem(prev => ({...prev,visible: false}))}} /> : ''}


        </>
    )
}
export default ObjectProfileTest