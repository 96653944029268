import {Empty, Table, Tag} from 'antd';
import React from "react";
const columns = [
    {
        title: 'Наименование роли',
        dataIndex: 'name',
        key: 'name',

    },
    {
        title: 'Статус',
        dataIndex: 'value',
        key: 'value',

    },
];

const Roles = (props) => {


    const getStatus = (id) => {

        let temparr = props.data[1].split(',');
        return temparr.includes(id.toString())
    }

    let rulesArr = props.roles.map(el => {
        return {name: el.long_name, value: getStatus(Number(el.id)) ?  <Tag color="green">Активна</Tag> :  <Tag color="red">Не активна</Tag>}
    })




    if (props.data.length > 0) {
        return  <Table pagination={false} columns={columns} dataSource={rulesArr} />
    } else {
        return   <Empty description={<span> Доступ к разделу запрещен.</span>} />
    }
};

export default Roles;