import React from "react";
import {Card, Select} from 'antd';
import s from '../styles/planning.module.css'

const {Meta} = Card;
const {Option} = Select;


const PlanTitleCard = (props) => {
    console.log('render: PlanTitleCard');

    function onSearch(val) {
        console.log('search:', val);
    }

    let staff = props.data.map(el => {
        return (<Option key={el.id} value={el.id}>{el.name}</Option>)
    });

    return (

        <Card

            style={
                {
                    width: 230,
                    margin: '10px auto',
                    padding: 10,
                    display: 'block',
                    float: 'left',
                    textAlign: 'left',
                    height: 212
                }
            }
        >
            <Meta
                style={{textAlign: 'center'}}
                title={'Роли'}
                //description={props.data.carName}
            />
            <div className={s.planTitle__select__cont}>
                <div className={s.planTitle__select__title}>
                    Водитель
                </div>
                <Select
                    style={{
                        minWidth: '90%'
                    }}
                    showSearch
                    size={'small'}
                    placeholder="Выберите водителя"
                    optionFilterProp="children"
                    onChange={(val) => {
                        props.eventChangeDriver(props.id, val)
                    }}
                    onSearch={onSearch}
                    defaultActiveFirstOption={true}
                    defaultValue={props.driver}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {staff}
                </Select>
            </div>
            <div className={s.planTitle__select__cont}>
                <div className={s.planTitle__select__title}>
                    Старший
                </div>
                <Select
                    style={{
                        minWidth: '90%'
                    }}
                    size={'small'}
                    showSearch
                    placeholder="Выберите старшего"
                    optionFilterProp="children"
                    onChange={(val) => {
                        props.eventChangeLead(props.id, val)
                    }}
                    defaultValue={props.lead}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {staff}
                </Select>
            </div>
        </Card>
    )

}

export default PlanTitleCard