import React, {useState} from "react";
import s from "../../../staff/styles/staff.module.css";
import {Button, ConfigProvider, DatePicker, Divider, Form, Input, Select, Steps, Switch} from "antd";
import locale from "antd/lib/locale/ru_RU";
import moment from "moment";

const AddStaffFinanceExpensive = (props) => {


    let staff = props.staff.map(el => {

        let flag = true;
        for (let i = 0, len = props.salary.length; i < len; i++){
            if (Number(props.salary[i].personal_id) === Number(el.id)){
                flag = false;
            }
        }
          return flag ? el : ''
    }).filter(el => el !== '')


    let defaultState = {
        id: staff[0].id,
        name: `${staff[0].lastname} ${staff[0].firstname}`,
        post: '',
        enable: 1,
        rate: 0,
        cash: 0,
        cashless: 0

    }

    let [state, setState] = useState(defaultState)

    let render = staff.map(el => {
        return  <Select.Option value={el.id}>{`${el.lastname} ${el.firstname}`}</Select.Option>
    })

    const handleChange = (e) => {
        let tempstate = {...state}
        tempstate.id =  Number(e)
        for (let i = 0, len = staff.length; i < len; i++){
            if (Number(staff[i].id) === Number(e)){
                tempstate.name = `${staff[i].lastname} ${staff[i].firstname}`
            }
        }

        setState(tempstate)
        console.log(e)
    }

    const change = (name, value) => {
        let tempstate = {...state}
        tempstate[name] = value
        tempstate['rate'] =  Number(tempstate['cash']) + Number(tempstate['cashless'])
        setState(tempstate)
    }

/*    function onChangeEnable(checked) {
        let tempstate = {...state}
        tempstate.enable = checked ? 1 : 0
        setState(tempstate)
    }*/

    const post = () => {
        props.postSalary(state, 0);
        props.close()

    }




    return (
        <div className={s.addStaffCont}>

            <div className={s.exit}><a onClick={() => {
                props.close()
            }}>Выход</a></div>
            <Form
                labelCol={{
                    span: 4,
                }}
                wrapperCol={{
                    span: 14,
                }}
                layout="horizontal"
                size={'small'}
            >
                <Divider plain>Основные данные</Divider>
                <Form.Item label="Выберите сотрудника">
                    <Select onChange={handleChange}>
                        {render}
                    </Select>
                </Form.Item>
                <Form.Item label="Должность">
                    <Input value={state.post} onChange={(val) => {
                        change('post', val.target.value)
                    }}/>
                </Form.Item>
{/*                <Form.Item label="Выплата активна" valuePropName="checked">
                    <Switch checked={Number(state.enable) === 1} onChange={onChangeEnable}/>
                </Form.Item>*/}
                <Form.Item label="Наличными">
                    <Input value={state.cash} onChange={(val) => {
                        change('cash', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Безнал">
                    <Input value={state.cashless} onChange={(val) => {
                        change('cashless', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Общая сумма">
                    <Input value={state.rate} disabled={true}/>
                </Form.Item>

                <Button style={{margin: '0 auto', display: 'block'}} onClick={() => {
                    post()
                }}>{Number(props.page) !== 3 ? 'Добавить сотрудника' : 'Обновить данные'}</Button>
            </Form></div>
    )
}

export default AddStaffFinanceExpensive