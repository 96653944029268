import React, {useState} from "react";
import { Table, Switch, Button, Collapse, Space } from 'antd';
import OperFinanceInfo from "./OperFinanceInfo";
import AddOperFinanceExpensive from "./AddOperFinanceExpensive";
const { Panel } = Collapse;
const OperFinanceExpensive = (props) => {

    let [page, setPage] = useState(1)

    console.log(props)
    let summRate = 0, summAll = 0;




    const setPeriod = (period, cost) => {

        switch (Number(period)) {
            case 1: return Number(cost) * 30
            case 2: return  Number(cost) * 4
            case 3: return  Number(cost)
            case 4: return  Number(cost) / 12
        }
    }

    const setPeriodNameById = (period) => {
        switch (Number(period)) {
            case 1: return 'Ежедневно'
            case 2: return 'Еженедельно'
            case 3: return 'Ежемесячно'
            case 4: return 'Ежегодно'
        }
    }

    const defaultData = props.oper_expenses.map(el => {
        summRate += Number(el.enable) === 1 ? setPeriod(el.period, el.cost) : 0
        console.log(summRate)
        summAll += Number(el.enable) === 1 ? setPeriod(el.period, el.cost) * (1+(Number(el.nds)/100)) : 0
        return {id: el.id, name: el.name, period: setPeriodNameById(el.period), type: el.type, cost: setPeriod(el.period, el.cost), nds: el.nds, enable: el.enable, all: setPeriod(el.period, el.cost) * (1+(el.nds/100))}
    })
    defaultData.push({id: 0, name: 'ИТОГО:', period: '', type: '', cost: summRate, nds: '', enable: '', all: summAll})

    let [state, setState] = useState(defaultData)




    const onChangeEnable = (e) => {
        summRate = 0;
        summAll = 0;


        let temp = [...state]
        for (let i = 0, len = state.length; i < len; i++){
            if (Number(e) === Number(temp[i].id)){
                console.log(Number(temp[i].enable) === 0)
                temp[i].enable = Number(temp[i].enable) === 0 ? 1 : 0
            }
        }


        for (let i = 0, len = state.length; i < len; i++){
            summRate += Number(state[i].enable) === 1 ? Number(state[i].cost) : 0
            summAll += Number(state[i].enable) === 1 ? Number(state[i].all) : 0

        }
        temp[temp.length - 1] = {car_id: 0, car_name: 'ИТОГО:', name: '', period: '', type: '', cost: summRate, nds: '', enable: '', all: summAll}
        setState(temp)

    }


    const columns = [
        {
            title: 'Пункт затрат',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Периодичность',
            dataIndex: 'period',
            key: 'period',
            /*            render: (rate) => {return (<span>{`${Number(rate).toFixed(2)} €`}</span>)},*/
        },
        {
            title: 'Тип платежа',
            dataIndex: 'type',
            key: 'type',
            render: (type, e) => {return Number(e.id !== 0) ?  (<span>{Number(type) === 1 ? 'Наличные' : 'Безнал'}</span>) : ''},
        },
        {
            title: 'Стоимость (мес)',
            dataIndex: 'cost',
            key: 'cost',
            render: (cost) => {return (<span>{`${Number(cost).toFixed(2)} €`}</span>)},
        },
        {
            title: 'НДС',
            dataIndex: 'nds',
            key: 'nds',
            render: (nds, elem) => {return  Number(elem.id !== 0) ? <span>{`${nds} %`}</span> : ''},
        },
        {
            title: 'Всего с НДС',
            dataIndex: 'all',
            key: 'all',
            render: (all) => {return (<span>{`${Number(all).toFixed(2)} €`}</span>)},
        },
        {
            title: 'Выплата активна',
            dataIndex: 'enable',
            key: 'enable',
            render: (enable, elem) => {
                return Number(elem.id !== 0) ?  <Switch size="small" onChange={()=>{onChangeEnable(elem.id)}} checked={Number(enable) === 1} /> : '';
            },
        },




    ];



    if (page === 1){
        return (
            <>
                <Collapse defaultActiveKey={['1']}>
                    <Panel header="Общая информация" key="1">
                        <OperFinanceInfo oper_expenses={props.oper_expenses}/>
                    </Panel>
                    <Panel header="Подробная информация" key="2">
                        <Button onClick={()=>{setPage(2)}} style={{marginRight: '10px', marginLeft: '10px',}} type="primary" >
                            Добавить пункт расходов
                        </Button>
                        <Button type="primary" >
                            Сохранить изменения
                        </Button>
                        <Table
                            dataSource={state}
                            columns={columns}
                            scroll={{ y: 400 }}
                            style={{ padding: 20 }}
                            size={'small'}
                            pagination={false}
                        />
                    </Panel>
                </Collapse>
            </>
        )
    } else {
        return <AddOperFinanceExpensive close={() => {setPage(1)}} postOper={props.postOper}  oper_expenses={props.oper_expenses}/>
    }

}

export default OperFinanceExpensive