import React from "react";
import {connect} from "react-redux";
import {
    getPdThunkCreator,
    getStaffThunkCreator, postStaffThunkCreator,
    SETPAGESTAFFAC
} from "../../redux/reducers/staffReducer";
import {Anketa} from "./StaffAnketa/Anketa";
import StaffList from "./StaffList/StaffList";
import AddStaff from "./AddStaff";
import './styles/staff.css'


class Staff extends React.Component {

    componentDidMount() {
        this.props.getStaffThunkCreator();
        this.props.SETPAGESTAFFAC(0);
    }
    openCard = (id) => {
        console.log(id);
        this.props.getPdThunkCreator(id.key);
        this.setPage(1);
        this.firstname = id.firstname;
        this.lastname = id.secondname;
    };
    setPage = (page) => {

        this.props.SETPAGESTAFFAC(page);
    };

    post = (state, id) => {


        this.props.postStaffThunkCreator(state, id);
        this.props.SETPAGESTAFFAC(0);
    }
    edit = (id) => {
        console.log(Number(id.key))
        this.props.getPdThunkCreator(id.key);
        this.props.SETPAGESTAFFAC(3);
        this.id = id.key
    }


    render() {
        return (
            <>
                {this.props.currentPage === 0 ? <StaffList rules={this.props.rules} edit={this.edit} setPage={this.setPage} openCard={this.openCard} data={this.props.staff}/> : ''}
                {this.props.currentPage === 1 ? <Anketa rules={this.props.rules}  setPage={this.setPage} fn={this.firstname} ln={this.lastname} data={this.props.pd}/> : ''}
                {this.props.currentPage === 2 ||  this.props.currentPage === 3 ? <AddStaff post={this.post} data={this.props.staff} id = {this.id || 0} pd={this.props.pd} setPage={this.setPage} page={this.props.currentPage}/> : ''}

            </>
        );
    }
}


// маппинг пропсов в компонент
const mapStateToProps = (state) => {
    return {
        staff: state.staffRDCR.staff,
        currentPage: state.staffRDCR.currentPage,
        pd: state.staffRDCR.pd,
        rules: state.staffRDCR.rules,

    }
};


export default connect(mapStateToProps, {
    getStaffThunkCreator,
    getPdThunkCreator,
    SETPAGESTAFFAC,
    postStaffThunkCreator

})(Staff);
