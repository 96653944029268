import {connect} from "react-redux";
import {
    getObjectsThunkCreator,
    getPlansByTime,
    postNewWorkThunkCreator,
    postPlansThunkCreator
} from "../../redux/reducers/planningReducer";
import React, {Component} from "react";
import {Spin} from "antd";
import s from './styles/planning.module.css'
import PlansList from "./PlansList";
import Planning from "./Planning";
import PlansListTest from "./PlansListTest";
import moment from "moment";
import WorksList from "./WorksList";


class GeneralPlanning extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.getObjectsThunkCreator(1);

    }

    render() {
        console.log('render Planning')
        if (this.props.page === '31') {
            return this.props.loading ? <div className={s.example}><Spin size={'large'}/></div> : <Planning
                staff={this.props.staff}
                plans={this.props.plans}
                cars={this.props.cars}
                objects={this.props.objects}
                write={this.props.write}
                post={this.props.postPlansThunkCreator}
                old={false}
                close={() => {
                    this.props.setPage('31')
                }}

            />
        } else if (this.props.page === '32') {
            return this.props.loading ? <div className={s.example}><Spin size={'large'}/></div> :
                <PlansListTest
                    content={this.props.plans}
                    staff={this.props.staff}
                    plans={this.props.plans}
                    cars={this.props.cars}
                    objects={this.props.objects}
                    post={this.props.postPlansThunkCreator}
                    getPlansByTime={this.props.getPlansByTime}
                    setPage={this.props.setPage}

                />
        } else if (this.props.page === '33') {
            return this.props.loading ? <div className={s.example}><Spin size={'large'}/></div> :
                <WorksList works={this.props.reportsTemp} setPage={this.props.setPage} post={this.props.postNewWorkThunkCreator}/>
        }
    }
}

const mapStateToProps = (state) => {
    return {
        objects: state.planningRDCR.objects,
        staff: state.planningRDCR.staff,
        plans: state.planningRDCR.plans,
        cars: state.planningRDCR.cars,
        loading: state.planningRDCR.loading,
        reportsTemp: state.planningRDCR.reportsTemp,
    }
};

export default connect(mapStateToProps, {
    getObjectsThunkCreator,
    postPlansThunkCreator,
    getPlansByTime,
    postNewWorkThunkCreator
})(GeneralPlanning);