import React, {Component} from "react";
import {connect} from "react-redux";
import {SETPAGEAC} from "../../redux/reducers/mainReducer";
import Header from "../header/Header";
import s from "./main.module.css";
import MainMenu from "./MainMenu";

class Main extends Component {


    constructor(props){
        super(props)
    }
    setPage = (value) => {
        this.props.SETPAGEAC(Number(value));
    };

    render() {
        return (
            <>
                <Header exit={this.props.exit} username={this.props.username}/>
                <div className={s.content}>
                    <MainMenu setPage={this.setPage} page={this.props.page} role={this.props.role}/>
                </div>
            </>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        page: state.mainRDCR.page,
        role: state.authRDCR.role
    }
};


export default connect(mapStateToProps, {
    SETPAGEAC,
})(Main);
