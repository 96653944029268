import {Empty, Table, Tag} from 'antd';
import React from "react";
import moment from "moment";

const  getData = (props) => {
    console.log(props)




    if (props) {
        //const staffArr = Object.entries(props[0]);
        let result = [
            {
                key: 1,
                name: 'Гражданство',
                value: props.nationality || ''
            },
            {
                key: 2,
                name: 'День рождения',
                value: moment(Number(props.birthday_date)*1000).format('L')
            },
            {
                key: 3,
                name: 'Дата начала работы',
                value: moment(Number(props.work_start_date)*1000).format('L')
    },
            {
                key: 4,
                name: 'Рабочий контракт',
                value: Number(props.contract) === 1 ?  <Tag color="green">Есть</Tag> : <Tag color="red">Нет</Tag> ,

            },
            {
                key: 5,
                name: 'Окончание срока действия контракта: ' ,
                value: props.contract_fdate ? <Tag color="green">{moment(Number(props.contract_fdate)*1000).format('L')}</Tag> : <Tag color="red">Нет данных</Tag> ,
            },



        ];
        return result
    } else {
        return [{
            key: 0,
            name: 'init',
            value: 'init'
        }]
    }

};
const General = (props) => {

    const columns = [
        {
            title: '',
            dataIndex: 'name',
            key: 'name',

        },
        {
            title: '',
            dataIndex: 'value',
            key: 'value',
        },
    ];






    if (props.data.length > 0) {
        return  <Table pagination={false} columns={columns} dataSource={getData(props.data[0])} />
    } else {
        return   <Empty description={<span> Доступ к разделу запрещен.</span>} />
    }

};

export default General;