import {Card, Avatar, List} from 'antd';
import {EditOutlined, EllipsisOutlined, SettingOutlined, DeleteOutlined, BankOutlined} from '@ant-design/icons';
import React from "react";

const {Meta} = Card;

const PlanObjectsCard = (props) => {

    const objectsDelete = (e) => {
        props.delete(e,props.id)
        console.log(e)
    }
/*
    props.staff.map((el) => {
        return ({name: el.name})
    })
*/


    return (
        <Card
            style={
                {
                    width: 230,
                    margin: '10px auto',
                    padding: 10,
                    display: 'block',
                    float: 'left',
                    textAlign: 'left',
                    height: 212
                }
            }
            /*            cover={
                            <img
                                alt="example"
                                src={props.title.imgSrc}
                             /!*   style={{ width: 150 }}*!/
                            />
                        }*/
            actions={[

                <EditOutlined onClick={()=>{props.change(Number(props.id))}} key="setting"/>,

            ]}
/*            actions={[
                <SettingOutlined key="setting"/>,
                <EditOutlined key="edit"/>,
                <EllipsisOutlined key="ellipsis"/>,
            ]}*/
        >
            <Meta
                style={{textAlign: 'center'}}
                /*                avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}*/
                title={'Объекты'}
                description={
                    <List
                        style={{textAlign: 'left', height: 150, overflowY: 'scroll'}}
                        itemLayout="horizontal"
                        dataSource={props.objects}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    style={{fontWeight: 400}}
                                    avatar={<BankOutlined  />}
                                    description={item.name}
                                    /*description="Ant Design, a design language for background applications, is refined by Ant UED Team"*/
                                />
                                <DeleteOutlined onClick={()=>{objectsDelete(item.id)}} style={{cursor: 'pointer'}}

                                />
                            </List.Item>
                        )}
                    />
                }
            />

        </Card>
    )

}

export default PlanObjectsCard