import React, {useState} from "react";
import s from './auth.module.css'
import GeneralMobile from "../mobile/GeneralMobile";
import Main from "../main/Main";
import {Button, Form} from "antd";

const Select = (props) => {

    let [mobile, setMobile] = useState(0)

    if (mobile === 0) {
        return (
            <div className={s.select__cont}>
                <Button className={s.select__btn} style={{margin: '10px auto', display: 'block'}} onClick={()=>{setMobile(1)}}>Садовник</Button>
                <Button className={s.select__btn} style={{margin: '10px auto', display: 'block'}} onClick={()=>{setMobile(2)}}>Администратор</Button>
            </div>
        )
    } else if (mobile === 1) {
        return (
            <GeneralMobile exit={props.exit} id={props.id} username={props.username}/>

        )
    } else if (mobile === 2) {
        return (
            <Main exit={props.exit} username={props.username}/>

        )
    }

}

export default Select;