import {Empty, Table, Tag} from 'antd';
import {Component} from "react";
import {connect} from "react-redux";
import {getRulesThunkCreator} from "../../redux/reducers/rulesReducer";
import React from "react";
const columns = [
    {
        title: 'Роль',
        dataIndex: 'name',
        key: 'name',

    },
    {
        title: 'Описание',
        dataIndex: 'desc',
        key: 'desc',
    },
    {
        title: 'Список сотрудников',
        dataIndex: 'staff',
        key: 'staff',
    },
    {
        title: 'Карточки сотрудников ',
        dataIndex: 'card',
        key: 'card',
    },
    {
        title: 'Роли в системе',
        dataIndex: 'role',
        key: 'role',
    },
    {
        title: 'Склад',
        dataIndex: 'store',
        key: 'store',
    },

];
const isTrue = (type) => {
    switch (type) {
        case 1: {
            return <Tag color="green"> Есть доступ </Tag>
        }
        case 0: {
            return <Tag color="red"> Нет доступа </Tag>
        }
        default:
            return 'no data'

    }
};

class Rules extends Component {

    constructor(props){
        super(props)
    }

    componentDidMount() {
        this.props.getRulesThunkCreator();
    }

    render() {
        let result = [{}];
        if (this.props.rules.length > 0) {
            result = this.props.rules.map((el, index) => {
                return {
                    key: index,
                    name: el.long_name,
                    desc: el.description,
                    staff: isTrue(Number(el.stafflist_read)),
                    card: isTrue(Number(el.personalData_read)),
                    role: isTrue(Number(el.role_read)),
                    store: isTrue(Number(el.store_read))
                }
            });

            return <Table style={{ padding: 20 }} pagination={false} columns={columns} dataSource={result}/>
        } else {
            return <Empty description={<span> Доступ к разделу запрещен.</span>}/>
        }


    }
}

const mapStateToProps = (state) => {
    return {
        rules: state.rulesRDCR.rules,
    }
};


export default connect(mapStateToProps, {
    getRulesThunkCreator,
})(Rules);