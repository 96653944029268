import {dataAPI, storeAPI} from "../../API/API";

const SETPAGE = 'SET-PAGE-M';

const GETSTOREMAIN = 'GET-STORE-MAIN';
const GETSTORESMAIN = 'GET-STORES-MAIN';
const GETSTAFFMAIN = 'GET-STAFF-MAIN';


const GETTRANSFER = 'GET-TRANSFER-M';
let initialState = {
    page: 1,
    transfer: {},
    storeData: [{}],
    storesConfig: {
        stores: {},
        types: {}
    },
    staff: {},
};


// Редюссер конфигурации
const MainReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETPAGE: {
            return {
                ...state,
                page: action.page
            }
        }
        case GETTRANSFER: {
            return {
                ...state,
                transfer: action.transfer
            }
        }
        case GETSTAFFMAIN: {
            return {
                ...state,
                staff: action.staff
            }
        }
        case GETSTOREMAIN: {
            return {
                ...state,
                storeData: action.store
            }
        }
        case GETSTORESMAIN: {
            return {
                ...state,
                storesConfig: {stores: action.stores, types: action.stores_types}
            }
        }

        default:
            return state;
    }

};


export let GETTRANSFERAC = (transfer) => ({type: GETTRANSFER, transfer});

export let GETSTOREMAINAC = (store) => ({type: GETSTOREMAIN, store});
export let GETSTORESMAINAC = (stores, stores_types) => ({type: GETSTORESMAIN, stores, stores_types});

export let GETSTAFFMAINAC = (staff) => ({type: GETSTAFFMAIN, staff});

export const getTransferThunkCreator = () => async (dispatch) => {
    let response = await storeAPI.getTransfer();
    if (response) {
        dispatch(GETTRANSFERAC(response.data.transfer));
    }
};

export const getStoreMainThunkCreator = () => async (dispatch) => {
    let response = await storeAPI.getStore();
    if (response) {
        dispatch(GETSTORESMAINAC(response.data.stores, response.data.stores_types));
        dispatch(GETSTOREMAINAC(response.data.store));

    }
};


export const getStaffMainThunkCreator = () => async (dispatch) => {
    let response = await dataAPI.getStaff();
    if (response) {
        dispatch(GETSTAFFMAINAC(response.data.staff));
    }
};


export let SETPAGEAC = (page) => ({type: SETPAGE, page});

export default MainReducer;





