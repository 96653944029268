import React, {useState} from "react";
import {Tabs, Tag} from 'antd';
import {FileSyncOutlined, FileTextOutlined, TeamOutlined, DeleteOutlined} from '@ant-design/icons';
import StaffFinanceExpensive from "./components/staff/StaffFinanceExpensive";
import CarsFinanceExpensive from "./components/cars/CarsFinanceExpensive";
import RentFinanceExpensive from "./components/rent/RentFinanceExpensive";
import OperFinanceExpensive from "./components/oper/OperFinanceExpensive";
import PeriodicIncome from "./components/income/PeriodicIncome";
import OneIncome from "./components/income/OneIncome";


const {TabPane} = Tabs;
const Income = (props) => {

    let [page, setPage] = useState(1)

    let expensesType =
        <>
            <TabPane
                tab={<span><FileSyncOutlined/>Периодические доходы</span>}
                key={'1'}
            />
            <TabPane
                tab={<span><FileTextOutlined/>Единоразовые доходы</span>}
                key={'2'}
            />
        </>


    return (
        <>
            <div className={'store_tabs'}>
                <Tabs onChange={(val) => {
                    setPage(Number(val))
                }} className={'tabs_of_storeTypes'} defaultActiveKey={1}>
                    {expensesType}
                </Tabs>
            </div>





            {Number(page === 1) ?
                <PeriodicIncome postIncome={props.postIncome}
                                objects={props.objects}
                                income={props.income}
                                payments={props.payments}
                                postPayment={props.postPayment}
                                delPayment={props.delPayment}

                />: ''}
            {Number(page === 2) ?
                <OneIncome  one_income={props.one_income}
                            postOneIncome={props.postOneIncome}
                />: ''}

        </>

    )
}

export default Income