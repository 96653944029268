import React, {useState} from 'react';
import {Button, Table} from "antd";
import s from './styles/profile.module.css'
import AddObject from "./AddObject";

const ObjectsListP = (props) => {


    let [page, setPage] = useState(0)


    if (page === 0 && !props.editing) {

        return (
            <>
                <div className={s.addStaffBtn}><Button type="primary" onClick={() => {
                    setPage(1)
                }}>Добавить объект</Button></div>
                <div className={s.listTable}>
                    <Table
                        style={{padding: 20}}
                        sticky={true}
                        loading={props.loading}
                        size={'small'}
                        bordered={true}
                        pagination={false}
                        scroll={{y: '80vh'}}
                        columns={props.columns}
                        dataSource={props.dataSource}
                        onChange={props.onChange}
                    />
                </div>
            </>
        )
    } else {
        return (
            <AddObject
                close={() => {
                    setPage(0)
                }}
                post={props.post}
                districts={props.districts}
                current={props.current}
                editing={props.editing}
            />
        )
    }


};


export default ObjectsListP;

