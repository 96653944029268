import React, {useState} from "react";
import s from "./styles/profile.module.css";
import {Badge, Calendar, ConfigProvider, Popover, Tag} from "antd";
import locale from "antd/lib/locale/ru_RU";
import ModalReports from "./ModalReports";
import moment from "moment";

const ObjectReports = (props) => {

    let [prewiewElem, setPreviewElem] = useState({visible: false, elem: {}})
    let [time, setTime] = useState({month: moment().format('MMMM'), year: moment().format('YYYY ')})


/*

    const onSelect = (value) => {
        let monthStart = (value.startOf('month')).format('L').replace(/[\.]/g, '-')
        let monthEnd = (value.endOf('month')).format('L').replace(/[\.]/g, '-')
        monthStart = monthStart.split('-')
        monthStart = `${monthStart[2]}-${monthStart[1]}-${monthStart[0]}`
        monthStart = new Date(monthStart).getTime()
        monthStart = (monthStart / 1000).toFixed(0)
        monthEnd = monthEnd.split('-')
        monthEnd = `${monthEnd[2]}-${monthEnd[1]}-${monthEnd[0]}`
        monthEnd = new Date(monthEnd).getTime()
        monthEnd = (monthEnd / 1000).toFixed(0)
        props.getReports(Number(monthStart), Number(monthEnd))
    };*/

    const prewiewReport = (e) => {
        let t_start = moment(Number(e.time_plan)*1000).startOf('day').format('X')
        let t_end = moment(Number(e.time_plan)*1000).endOf('day').format('X')
        setPreviewElem({visible: true, elem: e})
        props.getLinks(Number(e.object_id), Number(t_start), Number(t_end))
    }


    const getObjectNameById = (id) => {
        let name = ''
            props.objects.forEach(el => {
                name += Number(el.id) === Number(id) ? el.name : ''
            })
         return name
    }

    const onTestM = (e) => {
        let ns = new moment(e);
        let ne = new moment(e);
        let starttime = (ns.startOf('month').subtract(1,'month')).format('X')
        let endtime = (ne.endOf('month').add(1,'month')).format('X')
        setTime ({month: e.format('MMMM'), year: e.format('YYYY ')})
        props.getReports(Number(starttime), Number(endtime))
    }

    function getListData(value) {


        //функция возвращает дату по таймштамп
        const setDate = (timestamp) => {
            let d = new Date(Number(timestamp) * 1000)
            return d.getDate()
        }
        //функция возвращает месяц по таймштамп
        const setMonth = (timestamp) => {
            let d = new Date(Number(timestamp) * 1000)
            return Number(d.getMonth()) + 1
        }

        const setYear = (timestamp) => {
            let d = new Date(Number(timestamp) * 1000)
            return Number(d.getFullYear())
        }

        const setHours = (start, end) => {
            let timestart = new Date(Number(start) * 1000)
            let timestartHour = timestart.getHours() > 9 ? timestart.getHours() : `0${timestart.getHours()}`
            let timestartMinute = timestart.getMinutes() > 9 ? timestart.getMinutes() : `0${timestart.getMinutes()}`
            let timeend = new Date(Number(end) * 1000)
            let timeendHour = timeend.getHours() > 9 ? timeend.getHours() : `0${timeend.getHours()}`
            let timeendMinute = timeend.getMinutes() > 9 ? timeend.getMinutes() : `0${timeend.getMinutes()}`
            return `${timestartHour}:${timestartMinute} - ${timeendHour}:${timeendMinute}`

        }






        let listData = props.data.map((el, index) => {

            const content = (
                <div className={s.change__popover}>
                    <Tag className={s.change__popover__read}> {setHours(el.time_start, el.time_end)}</Tag>
                </div>
            );

            return ((setDate(el.time_plan) === value.date() && setMonth(el.time_plan) === Number(value.format('M'))) && setYear(el.time_plan) == setYear(value.format('X'))) ? {
                type: Number(el.time_start) === 0 ? 'error' : 'success',
                key: el.id,
                content: <Popover content={content} title=""><div className={s.report_calendar_item_all} onClick={() => {prewiewReport(el)}}>{getObjectNameById(el.object_id)}</div></Popover>
            } : ''

        }).filter(el => el !== '') || [];

        return listData
    }

    function dateCellRender(value) {
        const listData = getListData(value);
        return (
            <ul className="events">
                {listData.map(item => (
                    <li key={item.key}>
                        <Badge status={item.type} text={item.content}/>
                    </li>
                ))}
            </ul>
        );
    }

    function getMonthData(value) {
        /*        if (value.month() === 8) {
                    return 1394;
                }*/
    }

    function monthCellRender(value) {
        const num = getMonthData(value);
        return num ? (
            <div className="notes-month">
                <section>{num}</section>
                <span>Backlog number</span>
            </div>
        ) : null;
    }
    function disabledDate(current) {
        // Can not select days before today and today
        return current.format('MMMM') !== time.month;
    }

    return (
        <>
            <div className={s.report__title}>Обслуживание объектов</div>
            <ConfigProvider locale={locale}>
                <Calendar className={s.calendar_all}
                          dateCellRender={dateCellRender}
                          monthCellRender={monthCellRender}
                          onPanelChange={onTestM}
                          disabledDate={disabledDate}
                        />
            </ConfigProvider>

            {prewiewElem.visible ? <ModalReports    title={getObjectNameById(prewiewElem.elem.object_id)} links={props.links} elem={prewiewElem.elem} template={props.template} close={()=>{setPreviewElem(prev => ({...prev,visible: false}))}} /> : ''}
        </>
    )
}


export default ObjectReports