import {Button, Input, Tabs, Tag} from 'antd';


import React, {useState} from "react";
import StoreItem from "./StoreItem";
import AddWin from "./AddWin";
import s from './styles/store.module.css'


const {TabPane} = Tabs;

const StoreCategoryTab = (props) => {

    let [tab, ssetTab] = useState(1)
    let [filter, setfilter] = useState(null)
    let [addElem, setAddElem] = useState(false);

    const  onChange = (pagination, filters, sorter, extra) => {
       setfilter(filters.subCategoryName)
    }

    const setTab = (e) => {
        setfilter(null)
        ssetTab(e)
    }

    const findDateAvalibleFieldMod = (id) => {
        let modificator = null;
        for (let i = 0, len = props.category.mainCategory.length; i < len; i++) {
            if (Number(props.category.mainCategory[i].id) === id) {
                modificator = props.category.mainCategory[i].date_avalible
            }
             }
        return Number(modificator)
    };


    const filterCat = props.category.subCategory.map((el) => {
        return Number(el.parent_id) === Number(tab) ? {
            text: el.name,
            value: el.name,
            key: el.id
        } : ''
    }).filter((el) => {
        return el !== ''
    });

    let columns = props.columns;

    for (let i = 0, len = columns.length; i < len; i++){
        if (columns[i].dataIndex === 'subCategoryName') {
            columns[i].filters = filterCat;
            columns[i].filteredValue = filter || null;
        }
    }

    let date_avalible = findDateAvalibleFieldMod(tab);
    if (date_avalible === 0) {
        columns = props.columns.filter(col => col.dataIndex !== 'date_avalible')
    }



    const storeColumns = props.stores.map((el) => {
        return Number(el.type) === props.storesTypeId ? {
            title: el.name,
            dataIndex: `store_${el.name_db}`,
            key: el.id,
            width: '120px',
            align: 'center',
            render: (val) => {
                if ((val === 0)){
                    return (Number(el.type) === 1 ? <Tag color={'red'} key={Number(el.id)}>{'Нет в наличии'}</Tag> : '')
                } else {
                    return val
                }

            }
        } : ''
    }).filter((el) => {
        return '' !== el
    });

    for (let i = 0, len = columns.length; i < len; i++){
        if (columns[i].title === 'Количество') {
            columns[i].children = storeColumns;
        }
    }

    const changeFilterEmpty = () => {
        props.empty === 2 ? props.setEmpty(0) : props.setEmpty(props.empty + 1);
    };
    const addElems = () => {
        setAddElem(!addElem)
    };

    const setArr = (arr, id) => {
        return arr.map((el) => {
                return Number(el.maincatId) === id ? el : ''

        }).filter((el) => {
            return el !== ''
        })


    };
    let subCatArr = props.category.mainCategory.map((el) => {
        let span = <span >{el.name}</span>
        return <TabPane tab={span} key={Number(el.id)}></TabPane>
    });


    /*

        let addBtnArr = props.stores ? props.stores.map((el) => {
            return Number(el.type) === 1 ? <Button onClick={() => {
                addElem(el.name)
            }} className={'addBtn'} type={'primary'}>{`Добавить позицию на ${el.name}`}</Button> : ''
        }).filter((el) => {
            return el !== ''
        }) : [];
    */
    const [values, setValue] = useState('');
    let farr = []
        for (let i = 0, len = props.Arr.length; i < len; i++) {
            let name = props.Arr[i].name.toUpperCase().indexOf(values.toUpperCase()) > -1;
            let desc = props.Arr[i].description.toUpperCase().indexOf(values.toUpperCase()) > -1;
            if (name || desc) {
                farr.push(props.Arr[i])
            }
        }
    const FilterByNameInput = (
        <Input
            placeholder={"Поиск..."}
            className={'search'}
            value={values}
            onChange={e => {
                const currValue = e.target.value;
                setValue(currValue);
                //setDataSource(filteredData);
            }}
        />
    );



    return (
        <>  <div className={s.store_btn}>
            <Button onClick={() => {
                changeFilterEmpty()
            }} type={'primary'}>
                {props.empty === 0 ? 'Скрыть пустые' : ''}
                {props.empty === 1 ? 'Показать только пустые' : ''}
                {props.empty === 2 ? 'Показать все' : ''}
            </Button>

            <Button onClick={() => {
                addElems('Склад Будва')
            }} type={'primary'}>{`Добавить позицию на склад (Будва)`}</Button>
        </div>

            <div className={'store_tabs'}>
            <Tabs onChange={(val) => {
                setTab(Number(val))
            }} defaultActiveKey={tab}>
                {subCatArr}
            </Tabs>
            </div>
            {FilterByNameInput}
            <StoreItem
                columns={columns}
                dataSource={setArr(farr, tab)}
                onChange={onChange}
            />
            {addElem ? <AddWin setModal={() => {
                    setAddElem()
                }}
                                     category={props.category}
                                     post={props.post}
                                     addModal={addElem}

                />
                : ''}

        </>
    )
}

export default StoreCategoryTab;