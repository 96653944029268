import s from './main.module.css';
import {
    EuroCircleOutlined,
    BankOutlined,
    AreaChartOutlined,
    HomeOutlined,
    TeamOutlined,
    FileImageOutlined,
    ScheduleOutlined,
    CarOutlined,
    BarcodeOutlined,
    LeftOutlined,
    RightOutlined
} from '@ant-design/icons';
import './main.module.css';
import React, {useState} from "react";
import {Layout, Menu} from 'antd';
import Staff from "../staff/Staff";
import Rules from "../rules/Rules";
import Finance from "../finance/Finance";
import ObjectsContainer from "../objects/ObjectsContainer";
import Home from "../home/Home";
import StoreTypeTab from "../store/StoreTypeTab";
import TransferLog from "../store/TransferLog";
import {connect} from "react-redux";
import {
    getStaffMainThunkCreator,
    getStoreMainThunkCreator,
    getTransferThunkCreator
} from "../../redux/reducers/mainReducer";
import GeneralStore from "../store/GeneralStore";
import GeneralPlanning from "../planning/GeneralPlanning";
import PlansList from "../planning/PlansList";
import TestPlanSend from "../test/testPlanSend";
import CurrentPlan from "../planning/preview/CurrentPlan";
import ObjectsGeneral from "../objects/ObjectsGeneral";
import AnaliticsMain from "../analitics/AnaliticsMain";
import AnaliticsGeneral from "../analitics/AnaliticsGeneral";
import PhotoArchive from "../photo/PhotoArchive";


const {Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;

const MainMenu = (props) => {


    let [collapsed, setCollapsed] = useState(false)
    let [page, setPage] = useState('1')


    const onCollapse = () => {
        setCollapsed(!collapsed);
    };


    return (
        <Layout style={{maxHeight: '95vh', userSelect: 'none'}}>
            <Sider collapsible trigger={null} breakpoint="lg" collapsedWidth="100" collapsed={collapsed}
                   onCollapse={onCollapse}>
                <div className="logo"/>
                <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                    <Menu.Item onClick={() => {
                        setPage('1')
                    }} className={s.slider} key="1" icon={<HomeOutlined/>}>
                        Home
                    </Menu.Item>
                    {props.role.indexOf('1') !== -1 ? <SubMenu key="sub1" icon={<TeamOutlined/>} title="Сотрудники">
                        <Menu.Item onClick={() => {
                            setPage('11')
                        }} key="11">Список</Menu.Item>
                        <Menu.Item onClick={() => {
                            setPage('12')
                        }} key="12">Роли</Menu.Item>
                        <Menu.Item onClick={() => {
                            setPage('13')
                        }} key="13">График отпусков</Menu.Item>
                    </SubMenu> :''}
                    {(props.role.indexOf('1') !== -1) || (props.role.indexOf('6') !== -1) ?
                        <SubMenu key="sub2" icon={<BarcodeOutlined/>} title="Склад">
                            <Menu.Item onClick={() => {
                                setPage('21')
                            }} key="21">Склад</Menu.Item>
                            <Menu.Item onClick={() => {
                                setPage('22')
                            }} key="22">История действий</Menu.Item>
                            <Menu.Item onClick={() => {
                                setPage('23')
                            }} key="23">Планирование закупок</Menu.Item>
                        </SubMenu>
                        : ''}


                    {(props.role.indexOf('1') !== -1) || (props.role.indexOf('8') !== -1) ?
                        <SubMenu key="sub3" icon={<ScheduleOutlined/>} title="Планирование">
                            <Menu.Item onClick={() => {
                                setPage('31')
                            }} key="31">Создать новый план</Menu.Item>
                            <Menu.Item onClick={() => {
                                setPage('32')
                            }} key="32">Просмотр планов</Menu.Item>
                            <Menu.Item onClick={() => {
                                setPage('33')
                            }} key="33">Перечень работ</Menu.Item>

                        </SubMenu>
                        :''}
                    {(props.role.indexOf('1') !== -1) || props.role.indexOf('8') !== -1 ?
                    <SubMenu key="sub4" icon={<BankOutlined/>} title="Объекты">
                        <Menu.Item onClick={() => {
                            setPage('41')
                        }} key="41">Список объектов</Menu.Item>
                        <Menu.Item onClick={() => {
                            setPage('42')
                        }} key="42">Отчеты</Menu.Item>


                    </SubMenu>
                        :''}
                    {props.role.indexOf('1') !== -1 ?
                    <SubMenu key="sub5" icon={<EuroCircleOutlined/>} title="Финансы">
                        <Menu.Item onClick={() => {
                            setPage('51')
                        }} key="51">Сводка</Menu.Item>
                        <Menu.Item onClick={() => {
                            setPage('52')
                        }} key="52">Расходы</Menu.Item>
                        <Menu.Item onClick={() => {
                            setPage('53')
                        }} key="53">Доходы</Menu.Item>
                        <Menu.Item onClick={() => {
                            setPage('54')
                        }} key="54">Настройки финансов</Menu.Item>

                    </SubMenu>
                        :''}

                    {(props.role.indexOf('1') !== -1) || props.role.indexOf('8') !== -1 ?
                            <Menu.Item icon={<FileImageOutlined />} onClick={() => {
                                setPage('7')
                            }} key="7">Фото Архив</Menu.Item>

                        :''}



                    {props.role.indexOf('1') !== -1 ?
                        <SubMenu key="sub6" icon={<AreaChartOutlined />} title="Аналитика">
                            <Menu.Item onClick={() => {
                                setPage('61')
                            }} key="61">Выгрузки</Menu.Item>
{/*                            <Menu.Item onClick={() => {
                                setPage('62')
                            }} key="52">Расходы</Menu.Item>
                            <Menu.Item onClick={() => {
                                setPage('63')
                            }} key="53">Доходы</Menu.Item>
                            <Menu.Item onClick={() => {
                                setPage('64')
                            }} key="54">Настройки финансов</Menu.Item>*/}

                        </SubMenu>
                        :''}
                    {/*                  <Menu.Item onClick={() => {
                        setPage('7')
                    }} className={s.slider} key="7" icon={<ReadOutlined/>}>
                        Инструкции
                    </Menu.Item>
                    <Menu.Item onClick={() => {
                        setPage('8')
                    }} className={s.slider} key="8" icon={<CarOutlined/>}>
                        Автотранспорт
                    </Menu.Item>*/}
                    {/*                            <Menu.Item onClick={()=>{setPage('99')}} className={s.slider} key="99" icon={<CarOutlined />}>
                                TEST
                            </Menu.Item>*/}
                    <Menu.Item onClick={() => {
                        onCollapse()
                    }} className={s.slider} key="9" icon={!collapsed ? <LeftOutlined/> : <RightOutlined/>}>
                        {!collapsed ? 'Скрыть' : 'Показать'}
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout className="site-layout">

                <Content style={{}}>
                    <div className="site-layout-background">
                        {page === '99' ? <TestPlanSend/> : ''}
                        {page === '1' ? <Home/> : ''}
                        {page === '7' ? <PhotoArchive /> : ''}
                        {page === '11' ? <Staff style={{padding: 20}} setPage={setPage}/> : ''}
                        {page === '12' ? <Rules setPage={setPage}/> : ''}
                        {page === '21' || page === '22' ? <GeneralStore page={page}/> : ''}
                        {page === '41' || page === '42' ? <ObjectsGeneral mainPage={page}/> : ''}
                        {page === '31' || page === '32' || page === '33' ?
                            <GeneralPlanning setPage={setPage} page={page} write={false}/> : ''}
                        {page === '51' || page === '52' || page === '53' || page === '54' ?
                            <Finance setPage={setPage} page={page}/> : ''}
                        {page === '61' ? <AnaliticsGeneral /> : ''}

                        {/*    {page === '22' ? <GeneralStore page={2} /> : ''}*/}
                        {/*                          {this.props.page === 23 ?<div>Планирование закупок</div> : ''}
                            {this.props.page === 2 ? <div>Аналитика</div> : ''}



                            {this.props.page === 6 ? <div>Склад</div> : ''}
                            {this.props.page === 7 ? <div>Инструкции</div> : ''}
                            {this.props.page === 8 ? <div>Автотранспорт</div> : ''}*/}
                    </div>
                </Content>
                <Footer style={{textAlign: 'center', padding: 5}}>Garden Design App©2021 Created by Carselan Commerce
                    DOO</Footer>
            </Layout>
        </Layout>
    );


}


export default MainMenu;



