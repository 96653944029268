import {dataAPI, objectAPI, plansAPI, reportsAPI, whatsappApi} from "../../API/API";
import moment from "moment";
import {SETREPORTEMPTOBJAC} from "./objectsReducer";
import {GETSTAFFAC} from "./staffReducer";


const GETOBJPLAN = 'GET-OBJ-PLAN';
const GETPLANS = 'GET-PLANS-PLAN';
const GETCARS = 'GET-CARS-PLAN';
const GETSTAFFPLAN = 'GET-STAFF-PLAN';
const SETLOADING = 'SET-LOADING-PLAN';
const GETREPORTEMPTPLAN = 'GET-REPORTSTEMPPLAN-PLAN';


let initialState = {
    objects: {},
    page: 1,
    staff: {},
    plans: {},
    cars: {},
    currentPlane: {cars: ''},
    loading: true,
    reportsTemp: []
};


const PlanningReducer = (state = initialState, action) => {
    switch (action.type) {
        case GETOBJPLAN: {
            return {
                ...state,
                objects: action.obj
            }
        }
        case GETREPORTEMPTPLAN: {
            return {
                ...state,
                reportsTemp: action.reportsTemp
            }
        }
        case SETLOADING: {
            return {
                ...state,
                loading: action.loading
            }
        }
        case GETSTAFFPLAN: {
            return {
                ...state,
                staff: action.staff
            }
        }
        case GETPLANS: {
            return {
                ...state,
                plans: action.plans
            }
        }
        case GETCARS: {
            return {
                ...state,
                cars: action.cars
            }
        }
        default:
            return state;
    }

};

export let GETCARSAC = (cars) => ({type: GETCARS, cars});
export let GETPLANSAC = (plans) => ({type: GETPLANS, plans});
export let GETOBJPLANAC = (obj) => ({type: GETOBJPLAN, obj});
let SETLOADINGAC = (loading) => ({type: SETLOADING, loading});
let GETREPORTEMPTPLANAC = (reportsTemp) => ({type: GETREPORTEMPTPLAN, reportsTemp});

let GETSTAFFPLANAC = (staff) => ({type: GETSTAFFPLAN, staff});


let monthStart = (moment().startOf('month').subtract(1, 'month')).format('X')
let monthEnd = (moment().endOf('month').add(1, 'month')).format('X')


export const getReportsTemplanThunkCreator = () => async (dispatch) => {
    let response = await reportsAPI.getReportsTemp();
    if (response) {
        dispatch(GETREPORTEMPTPLANAC(response.data.template));
    }
};



export const getObjectsThunkCreator = () => async (dispatch) => {
    let response = await objectAPI.getObjects(1);
    if (response) {
        dispatch(GETOBJPLANAC(response.data.objects));
        response = await plansAPI.getPlansByTime(monthStart, monthEnd);
        if (response) {
            dispatch(GETPLANSAC(response.data.plans));
            response = await dataAPI.getStaff();
            if (response) {
                dispatch(GETSTAFFPLANAC(response.data.staff));
                response = await dataAPI.getCars();
                if (response) {
                    dispatch(GETCARSAC(response.data.cars));
                    response = await reportsAPI.getReportsTemp();
                    if (response) {
                        dispatch(GETREPORTEMPTPLANAC(response.data.template));
                        dispatch(SETLOADINGAC(false));
                    }


                }
            }
        }


    }
};
export const getPlansByTime = (start, end) => async (dispatch) => {
    let response = await plansAPI.getPlansByTime(start, end);
    if (response) {
        dispatch(GETPLANSAC(response.data.plans));
    }
};


export const postPlansThunkCreator = (postArr, dates, id, body, maincomment) => async (dispatch) => {

    let response = await plansAPI.newElem(postArr, dates, id, maincomment);
    console.log(response)
    if (response.status) {
        body = {...body, link: `https://gdapp.org/current_plan?id=${response.id}`}
        response = await whatsappApi.sendPlan(body);
        if (response) {
            response = await plansAPI.getPlansByTime(monthStart, monthEnd);
            if (response) {
                dispatch(GETPLANSAC(response.data.plans));
            }
    }
}

};



export const postNewWorkThunkCreator = (state, update) => async (dispatch) => {
    let response = await plansAPI.postNewWork(state, update);
    if (response) {
        response = await reportsAPI.getReportsTemp();
        if (response) {
            dispatch(GETREPORTEMPTPLANAC(response.data.template));
            dispatch(SETLOADINGAC(false));
        }
    }

};

export default PlanningReducer;




