
import React from "react";
import {Divider, Form} from "antd";
import s from '../../styles/finance.module.css'
import moment from "moment";

const IncomeFinanceInfo = (props) => {

    let summRateCash = 0;
    let summRateCashless = 0;
    let summAll = 0;
    let summAllNds = 0;

    const setPeriod = (period, cost) => {
        switch (Number(period)) {
            case 1: return cost * 30
            case 2: return cost * 4
            case 3: return cost
            case 4: return cost / 12
        }
    }

    const setCost = (cost) => {

        let month = Number(moment().format("M"))
        let tempcost = cost.split('`')
        tempcost = tempcost.map(el => {
            return el.replace(',','.')
        })
        return  Number(tempcost[month - 1])
    }

    if (props.type === 1) {

        for (let i = 0, len = props.income.length; i < len; i++){
            summRateCash +=  (Number(props.income[i].enable) === 1 &&  Number(props.income[i].type) === 1) ? setCost(props.income[i].cost)  : 0
            summRateCashless +=  (Number(props.income[i].enable) === 1 &&  Number(props.income[i].type) === 2) ? setCost(props.income[i].cost)  : 0
            summAll +=  Number(props.income[i].enable) === 1 ? setCost(props.income[i].cost)  : 0
            summAllNds +=  (Number(props.income[i].enable) === 1 &&  Number(props.income[i].type) === 2) ? setCost(props.income[i].cost)  * Number(props.income[i].nds)/100 : 0
            /*        console.log(summRateCash)
                    console.log('summRateCash')
                    console.log(summRateCashless)
                    console.log('summRateCashless')
                    console.log(summAll)
                    console.log('summRateCashless')
                    console.log(summAllNds)*/
        }
    } else {
        for (let i = 0, len = props.income.length; i < len; i++){
            summRateCash +=  (Number(props.income[i].enable) === 1 &&  Number(props.income[i].type) === 1 && Number(props.income[i].balance) >= 0) ? setCost(props.income[i].cost)  : 0
            summRateCashless +=  (Number(props.income[i].enable) === 1 &&  Number(props.income[i].type) === 2 && Number(props.income[i].balance) >= 0) ? setCost(props.income[i].cost)  : 0
            summAll +=  (Number(props.income[i].enable) === 1  && Number(props.income[i].balance) >= 0)? setCost(props.income[i].cost)  : 0
            summAllNds +=  (Number(props.income[i].enable) === 1 &&  Number(props.income[i].type) === 2  && Number(props.income[i].balance) >= 0) ? setCost(props.income[i].cost)  * Number(props.income[i].nds)/100 : 0
            /*        console.log(summRateCash)
                    console.log('summRateCash')
                    console.log(summRateCashless)
                    console.log('summRateCashless')
                    console.log(summAll)
                    console.log('summRateCashless')
                    console.log(summAllNds)*/
        }
    }





    return (
        <div>
            <div className={s.maintitle}>{`${props.type === 1 ? 'Планируемые' : 'Фактические'} доходы от постоянных объектов в текущем месяце`} </div>
            <div className={s.mainCont}>
                <div className={s.financeStaffCont}>
                    <div className={s.financeStaffTitle}>Наличными</div>
                    <div className={s.financeStaffContent}>{`${summRateCash.toFixed(2)} €`}</div>
                </div>
                <div className={s.financeStaffCont}>
                    <div className={s.financeStaffTitle}>Безнал</div>
                    <div className={s.financeStaffContent}>{`${summRateCashless.toFixed(2)} €`}</div>
                </div>

                <div className={s.financeStaffCont}>
                    <div className={s.financeStaffTitle}>Ндс</div>
                    <div className={s.financeStaffContent}>{`${summAllNds.toFixed(2)} €`}</div>
                </div>
                <div className={s.financeStaffCont}>
                    <div className={s.financeStaffTitle}>Всего</div>
                    <div className={s.financeStaffContent}>{`${(summAll + summAllNds).toFixed(2)} €`}</div>
                </div>

            </div>
        </div>

    )
}

export default IncomeFinanceInfo