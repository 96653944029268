import React, {useState} from "react";
import s from "./mobile.module.css";
import {Button, Collapse, Divider} from "antd";
import MobileReport from "./MobileReport";
import moment from 'moment';
import {UserOutlined, BankOutlined} from '@ant-design/icons';
import Report from "./Report";
import OldReports from "./OldReports";

const timeStampToDate = (timestamp) => {

    if (timestamp) {
        let currentDate = timestamp.toString().length > 10 ? new Date(Number(timestamp)) : new Date(Number(timestamp) * 1000);
        let day = currentDate.getDate();
        let month = currentDate.getMonth() + 1;
        let year = currentDate.getFullYear();
        return `${day}.${month}.${year}`;
    } else {
        let currentDate = new Date();
        let day = currentDate.getDate();
        let month = currentDate.getMonth() + 1;
        let year = currentDate.getFullYear();
        return `${day}.${month}.${year}`;
    }


};

const {Panel} = Collapse;


const MobilePlansList = (props) => {

    const currentTeam = (array) => {
        return array.filter(el => {
            return Number(el.lead) === Number(props.id)
        })
    }
    let [team, setTeam] = useState(currentTeam(props.plans[0].props)[0] || [])
    let [page, setPage] = useState({page: 1, currentElemId: 0, name: '',report_id: 0});
    let [oldReport, setOldReport] = useState(null)
    let [photo, setPhoto] = useState(props.photo || [])
    console.log(team)
    console.log(props.id)
    const setPlanDateForTitle = (timestamp) => {
        return moment(Number(timestamp) * 1000).format('L')
    }
    const setStaffName = (staffId) => {
        let name = '';
        for (let i = 0, len = props.staff.length; i < len; i++) {
            name += Number(props.staff[i].id) === Number(staffId) ? `${props.staff[i].firstname} ${props.staff[i].lastname}` : ''
        }
        return name;
    }
    const serStaffArray = (staffString) => {

        const setStaffName = (staffId) => {
            let name = '';
            for (let i = 0, len = props.staff.length; i < len; i++) {
                name += Number(props.staff[i].id) === Number(staffId) ? `${props.staff[i].firstname} ${props.staff[i].lastname}` : ''
            }
            return name;
        }
        let staffArray = staffString.split(',')
        return staffArray.map(el => {
            return setStaffName(el)
        })

    }
    const setObjectsArray = (objectsString) => {
        const setObjectsProps = (objectId,index) => {
            let currentObj = {}
            const setAccessById = (id) => {
                switch (id) {
                    case 1:
                        return 'Открытый'
                    case 2:
                        return 'Ключ у нас'
                    case 3:
                        return 'Ключ на объекте'
                    case 4:
                        return 'Через контактное лицо'
                    default:
                        return 'Нет данных'

                }
            }
            const setCommentsById = () => {
                let commentsArray = team.comment.split('`')
                return commentsArray[index]
            }
            const setReportByObjId = (id) => {
                let reportId = [];
                const setTimeDownload = (time) => {
                    return moment(Number(time) * 1000).format('LT');
                }
                for (let i = 0, len = props.reports.length; i < len; i++){
                    if (Number(props.reports[i].object_id) === id){
                        reportId.push({id: props.reports[i].id, time: setTimeDownload(props.reports[i].time_download)})
                    }
                }

                return reportId
            }
            for (let i = 0, len = props.objects.length; i < len; i++) {
                if (Number(props.objects[i].id) === Number(objectId)) {
                    currentObj.name = `${props.objects[i].name} ${props.objects[i].long_name !== '' ? ' | '+props.objects[i].long_name : ''} `
                    currentObj.access = setAccessById(Number(props.objects[i].access))
                    currentObj.comment_last_service = props.objects[i].comment_last_service
                    currentObj.contact_name = props.objects[i].contact_name
                    currentObj.contacts = props.objects[i].contacts
                    currentObj.date_last_service = props.objects[i].date_last_service !== '' ? moment(Number(props.objects[i].date_last_service) * 1000).format('L') : ''
                    currentObj.description = props.objects[i].description
                    currentObj.comment = setCommentsById()
                    currentObj.locate = props.objects[i].locate
                    currentObj.id = props.objects[i].id
                    currentObj.report = setReportByObjId(Number(props.objects[i].id))
                }
            }
            return currentObj;
        }
        let objectsArray = objectsString.split(',')

        return objectsArray.map((el,index) => {
            return setObjectsProps(el,index)
        })


    }
    const objectsRender = () => {
        const setButtonChangeReport = (array) => {
            return array.map(el => {
                return <Button key={el.id} onClick={()=>{changeReport(Number(el.id))}} className={s.new__plan__mobile_btn} type="primary">{`Изменить отчет ${el.time}`}</Button>
            })
        }


        return setObjectsArray(team.objects_id).map(el => {

            return (
                <Panel header={`${el.name} ${el.report.length > 0 ? '(отчет загружен)' : ''}`} key={el.id}>
                    <div className={s.new__plan__objects_item}>Доступ: <b>{el.access}</b></div>
                    {el.contact_name !== '' && <div className={s.new__plan__objects_item}>Контактное лицо: <b>{el.contact_name}</b></div>}
                    {el.contacts !== ''  && <div className={s.new__plan__objects_item}>Телефон: <b>{el.contacts}</b></div>}
                    {el.description !== ''  && <div className={s.new__plan__objects_item}>Правила: <b>{el.description}</b></div>}
                    {el.comment !== ''  && <div className={s.new__plan__objects_item}>Комментарий: <b>{el.comment}</b></div>}
                    {el.locate !== ''  && <div className={s.new__plan__objects_item}>Локация: <b>{el.locate}</b></div>}
                    {el.date_last_service !== ''  && <div className={s.new__plan__objects_item}>Дата посл. обсл.: <b>{el.date_last_service}</b></div>}
                    {el.comment_last_service !== ''  && <div className={s.new__plan__objects_item}>Коммент с посл. обсл.: <b>{el.comment_last_service}</b></div>}
                    {el.report.length > 0  ? setButtonChangeReport(el.report) : ''}
                    <Button key='1' className={s.new__plan__mobile_btn} onClick={()=>{addReport(Number(el.id),el.name)}} type="primary">{`Загрузить отчет`}</Button>
                    <Button key='2' className={s.new__plan__mobile_btn} onClick={()=>{oldReports(Number(el.id),el.name)}} type="primary">{`Прошлые отчеты`}</Button>
                </Panel>

            )
        })
    }
    const staffRender = () => {
        return serStaffArray(team.staff_id).map((el,index) => {
            return <div key={index} className={s.new__plan__staff_item}><UserOutlined/>{` ${el}`}</div>
        })
    }
    const close =() => {
        setPage(prev =>({...prev, page: 1}))
        setOldReport(null)
    }
    const addReport = (e,name) => {
        let photos = props.photo.filter(el => {
            return Number(el.object_id) === Number(e)
        })
        setPhoto (photos)

       setPage({page: 2, currentElemId: e, name: name})
    }

    const oldReports = (e, name) => {
        props.get(Number(e),moment().subtract(1, 'months').format('X'),moment().format('X'))
        setPage({page: 3, currentElemId: e, name: name})
    }

    const changeReport = (e) => {
        let currentReport = {}
        for (let i = 0,len = props.reports.length; i < len; i++){
            if (Number(props.reports[i].id) === Number(e)){
                currentReport = props.reports[i]
            }
        }
        setOldReport(currentReport)
        let name = ''
        for (let i = 0, len = props.objects.length; i < len; i++) {
            if (Number(props.objects[i].id) === Number(currentReport.object_id)) {
                name = `${props.objects[i].name} ${props.objects[i].long_name !== '' ? ' | '+props.objects[i].long_name : ''} `

            }
        }


        let photos = props.photo.filter(el => {
            return Number(el.object_id) === Number(currentReport.object_id)
        })
        setPhoto (photos)




        setPage({page: 2, currentElemId: currentReport.object_id, name: name, report_id: currentReport.id})


    }

    if (page.page === 1 && team !== []){
        return (

            <div className={s.new__plan__container}>
                <div className={s.new__plan__title}>
                    <Divider plain>{`План работ на ${setPlanDateForTitle(props.plans[0].used_date)}`}</Divider>
                </div>
                <div className={s.new__plan__staff_container}>
                    <div className={s.new__plan__staff_title}>Информация</div>
                    <div className={s.new__plan__staff_content}>
                        <Collapse>
                            <Panel header={`Старший / Водитель`} key={'1'}>
                                <div className={s.new__plan__staff_item}>{`Старший: ${setStaffName(Number(team.lead))}`}</div>
                                <div className={s.new__plan__staff_item}>{`Водитель: ${setStaffName(Number(team.driver))}`}</div>
                            </Panel>

                            <Panel header={`Сотрудники`} key={'2'}>
                                {staffRender()}
                            </Panel>
                            <Panel header={`Комментарии к плану`} key={'3'}>
                                <div className={s.new__plan__staff_item}>{`Для всех: ${props.plans[0].maincomment}`}</div>
                                <div className={s.new__plan__staff_item}>{`Для Вашей команды: ${team.teamcomment}`}</div>
                            </Panel>
                        </Collapse>
                    </div>
                </div>
                <div className={s.new__plan__objects_container}>
                    <div className={s.new__plan__objects_title}>Объекты</div>
                    <div className={s.new__plan__objects_content}>

                        <Collapse>
                            {objectsRender()}
                        </Collapse>


                    </div>
                </div>
            </div>

        )

    } else if (page.page === 1 && team === []){
        return (
            <div className={s.noaction}>...нет активных задач...</div>
        )
    } else if (page.page === 2){
        return <MobileReport
            elId={page.currentElemId}
            reportsTemp={props.reportsTemp}
            page={page.page}
            name={page.name}
            setPage={(val) => {setPage(prev =>({...prev, page: val}) )}}
            plans={{id: props.plans[0].id, used_date:props.plans[0].used_date }}
            post={props.post}
            oldReport={oldReport}
            close={()=>{close()}}
            report_id={page.report_id}
            photo={photo}

        />
    } else if (page.page === 3){
        return <OldReports
            elId={page.currentElemId}
            close={()=>{close()}}
            reportsTemp={props.reportsTemp}
            get={props.get}
            oldreports={props.oldreports}
        />
    }


}


export default MobilePlansList;