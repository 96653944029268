import React, {useState} from "react";
import s from "../../../staff/styles/staff.module.css";
import {Button, ConfigProvider, DatePicker, Divider, Form, Input, Select, Space, Switch} from "antd";
import locale from "antd/lib/locale/ru_RU";
import moment from "moment";



const AddOneExpenses = (props) => {

    let defaultState = {
        name: '',
        type: 1,
        article: 0,
        cost: 0,
        nds: 0,
        id: 0,
        date: 0

    }

    let [state, setState] = useState(defaultState)


    let articles = props.articles_expenses.map(el => {
        return <Select.Option value={Number(el.id)}>{el.name}</Select.Option>
    })


    const handleChangeType = (e) => {
        let tempstate = {...state}
        tempstate.type = Number(e)
        setState(tempstate)
        console.log(e)
    }

    const handleChangeArticle = (e) => {
        let tempstate = {...state}
        tempstate.article = Number(e)
        setState(tempstate)
        console.log(e)
    }

    const handleChangeNds = (e) => {
        let tempstate = {...state}
        tempstate.nds = Number(e)
        setState(tempstate)
        console.log(e)
    }


    const change = (name, value) => {
        let tempstate = {...state}
        tempstate[name] = value
        setState(tempstate)
    }

    function onChange(date) {
        if (date !== null){
            let tempstate = {...state}
            tempstate.date = date.format('X')
            setState(tempstate)
        }
    }

    const post = () => {
        console.log(state)
        props.postOne(state, 0);
        props.close()

    }




    return (
        <div className={s.addStaffCont}>

            <div className={s.exit}><a onClick={() => {
                props.close()
            }}>Выход</a></div>
            <Form
                labelCol={{
                    span: 4,
                }}
                wrapperCol={{
                    span: 14,
                }}
                layout="horizontal"
                size={'small'}
            >
                <Divider plain>Основные данные</Divider>
                <Form.Item label="Наименование">
                    <Input value={state.name} onChange={(val) => {
                        change('name', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Статья расходов">
                    <Select onChange={handleChangeArticle}>
                        {articles}
                    </Select>
                </Form.Item>
                <Form.Item label="Тип оплаты">
                    <Select onChange={handleChangeType}>
                        <Select.Option value={1}>Наличные</Select.Option>
                        <Select.Option value={2}>Безнал</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Стоимость">
                    <Input value={state.cost} onChange={(val) => {
                        change('cost', val.target.value)
                    }}/>
                </Form.Item>
                {state.type === 2 &&
                <Form.Item label="НДС">
                    <Select onChange={handleChangeNds}>
                        <Select.Option value={0}>БЕЗ НДС</Select.Option>
                        <Select.Option value={7}>НДС 7%</Select.Option>
                        <Select.Option value={21}>НДС 21%</Select.Option>
                    </Select>
                </Form.Item>}
                <Space direction="vertical">
                    <ConfigProvider locale={locale}>
                        <DatePicker placeholder={'Укажите дату платежа'} onChange={onChange} />
                    </ConfigProvider>

                </Space>,

{/*                <Form.Item label="Выплата активна" valuePropName="checked">
                    <Switch checked={Number(state.enable) === 1} onChange={onChangeEnable}/>
                </Form.Item>*/}


                <Button style={{margin: '0 auto', display: 'block'}} onClick={() => {
                    post()
                }}>{Number(props.page) !== 3 ? 'Добавить пункт' : 'Обновить данные'}</Button>
            </Form></div>
    )
}

export default AddOneExpenses