import React from "react";
import * as axios from "axios";
import {
    Document,
    Font,
    Image,
    Page,
    PDFDownloadLink,
    PDFViewer,
    renderToFile,
    StyleSheet,
    Text,
    View
} from "@react-pdf/renderer";
import logo from "../../assets/logo.png";
import * as ReactDOMServer from "react-dom";


const TestPlanSend = (props) => {

    Font.register({
        family: "Roboto",
        src:
            "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
    });


    const styles = StyleSheet.create({

        page: {
            flexDirection: 'column',
            backgroundColor: '#fff',
            width: '100%',
            fontFamily: "Roboto",
            fontSize: 12,
            padding: 10
        },
        section: {},
        sectionLogo: {
            margin: [0, 10, 0, 5],
            flexGrow: 1,
            alignItems: 'center',

        },
        sectionTitle: {
            margin: [0, 0, 0, 10],
            flexGrow: 2,

        },
        text: {
            width: '100%',
            backgroundColor: '#fff',
            color: '#5c5658',
            textAlign: 'left',
            fontSize: 10,
        },
        text_1: {
            width: '100%',
            backgroundColor: '#005f05',
            color: '#fff',
            textAlign: 'center',
            marginTop: 15
        },
        text_5: {
            width: '100%',
            backgroundColor: '#fff',
            color: '#5c5658',
            textAlign: 'center',
            marginTop: 15
        },
        text_3: {
            width: '100%',
            backgroundColor: '#fff',
            color: '#5c5658',
            textAlign: 'left',
            marginTop: 10,
            marginBottom: 10
        },
        text_4: {
            width: '100%',
            backgroundColor: '#fff',
            color: '#a4220c',
            textAlign: 'left',
            marginTop: 10,
            marginBottom: 10,
            fontSize: 10,
        },
        image: {
            width: '100px',
            padding: 10,
        },
    });


// Create Document Component
    const MyDocument = () => (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.sectionLogo}>

                    </View>
                    <View style={styles.sectionTitle}>
                        <Text style={styles.text_5}>222</Text>
                    </View>

                    <View style={styles.sectionTitle}>
                        <Text style={styles.text_1}>11</Text>
                        <Text style={styles.text_3}>{'Объекты:'}</Text>

                        <Text style={styles.text_3}>{'Комментарий:'}</Text>
                        <Text style={styles.text}>11</Text>
                    </View>


                </View>


            </Page>
        </Document>

    );


    const App = () => (
        <PDFViewer className={'pdfview'} showToolbar={false}>
            <MyDocument/>
        </PDFViewer>
    );

    let s =  ReactDOMServer.renderToStaticMarkup(<App />)
    let blob = new Blob([s], {type: 'application/pdf'})
    console.log(s)

    const transfer = (blob) => {
        var formdata = new FormData();

        formdata.append("file", blob);
        formdata.append('fname', 'plan.pdf');
        axios.post(`http://89.108.77.132/API/1.0/test.php`, formdata)
            .then(response => console.log(response));

    }


    const send = () => {

/*
        const html = '<!doctype html>\n' +
            ' \n' +
            '<html lang="en">\n' +
            '<head>\n' +
            '  <meta charset="utf-8">\n' +
            ' \n' +
            '  <title>The HTML5 Herald</title>\n' +
            '  <meta name="description" content="The HTML5 Herald">\n' +
            '  <meta name="author" content="SitePoint">\n' +
            ' \n' +
            '  <link rel="stylesheet" href="css/styles.css?v=1.0">\n' +
            ' \n' +
            '  <!--[if lt IE 9]>\n' +
            '    <script src="//html5shiv.googlecode.com/svn/trunk/html5.js"></script>\n' +
            '  <![endif]-->\n' +
            '</head>\n' +
            ' \n' +
            '<body>\n' +
            '<div>Yeeeep!</div>\n' +
            '</div>\n' +
            '</html>'
        let blob = new Blob([html], {type: 'text/html'})*/
        transfer(blob)


    }


    return (
        <div>
            <input type="button" onClick={() => {
                send()
            }} value={'SEND'}/>
        </div>
    )
}

export default TestPlanSend