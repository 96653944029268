import {Typography, Table} from 'antd';
import React from "react";

const TransferLog = (props) => {

    const {Title} = Typography;
    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'age',
        },
        {
            title: 'Позиция',
            dataIndex: 'item',
            key: 'age',
        },
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'address',
        },
        {
            title: 'Из Склада',
            dataIndex: 'from',
            key: 'address',
        },
        {
            title: 'На Склад',
            dataIndex: 'to',
            key: 'address',
        },
        {
            title: 'Инициатор',
            dataIndex: 'owner',
            key: 'address',
        },
        {
            title: 'Комментарий',
            dataIndex: 'description',
            key: 'address',
        },
    ];

    const timestampToDate = (time) => {
        let date = new Date(Number(time))
        let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
        let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
        let hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
        let minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()

        return `${day}.${month}.${date.getFullYear()}  ${hours}:${minutes}`
    }

    const encode_store = (n) => {
        let store = ''
        for (let i = 0; i < props.stores.length; i++) {
            store +=  Number(props.stores[i].name_db) === Number(n.substr(6)) ? props.stores[i].name : ''
        }

        return store
    };

    const encode_staff = (n) => {
        let store = ''
        for (let i = 0; i < props.staff.length; i++) {
            store += props.staff[i].login.toUpperCase() === n.toUpperCase() ? ` ${props.staff[i].firstname} ${props.staff[i].lastname}` : '';
        }
        return store
    };

    const setName = (id) => {
        let name = ''
        for (let i = 0; i < props.data.length; i++) {
            name += Number(props.data[i].id) === id ? props.data[i].name : ''
        }
        return name
    };

    let arr = [];
    if (props.data && props.data.length > 1 && props.stores && props.stores.length > 1) {



        arr = props.transfer ? props.transfer.reverse().map((el, index) => {
            let name = setName(Number(el.item_id))
            let store = encode_store(el.from_store)
            let staff = encode_staff(el.owner)
            return {
                index:  (props.transfer.length - index),
                key: el.id,
                item: name,
                date: timestampToDate(el.date),
                from: store,
                to: encode_store(el.to_store),
                owner: staff,
                description: el.description
            }
        }) : [];
    }

    return (<div className={'transfer'}>
        <Title level={4}>История переносов товаров между складами</Title>
        <Table columns={columns}
               dataSource={arr}
               sticky={true}
               size={'small'}
               bordered={true}
               pagination={false}
               scroll={{y: '70vh'}}
        />
    </div>)

}


export default TransferLog