import React, {useEffect, useState} from "react";
import s from './styles/planning.module.css'
import './styles/planning.css'
import {Typography, Divider, Button, message} from 'antd';
import Caddy from '../../assets/Caddy.png'
import Crafter from '../../assets/Crafter.png'
import Touran from '../../assets/Touran.png'
import Kangoo from '../../assets/kangoo2.png'
import Man from '../../assets/Man.png'
import PlanTitleCard from "./cards/PlanTitleCard";
import PlanStaffCard from "./cards/PlanStaffCard";
import PlanObjectsCard from "./cards/PlanObjectsCard";
import PlanCommentsCard from "./cards/PlanCommentsCard";
import PlanStaffChange from "./changeWindows/PlanStaffChange";
import PlanObjectsChange from "./changeWindows/PlanObjectsChange";
import PlanCommentsChange from "./changeWindows/PlanCommentsChange";
import {DeleteOutlined} from '@ant-design/icons';
import CurrentPlan from "./preview/CurrentPlan";
import moment from "moment";

const {Title} = Typography;


const Planning = (props) => {


    const objectsArr = props.objects.filter((el) => {
        return Number(el.visible) === 1
    });
/*    const [invise, setInvise] = useState([])

    const invisible = (id) => {
        const temp = invise
        const index = temp.indexOf(id)
        if (index !== -1){
            temp.splice(index, 1);
        } else {
            temp.push(id)
        }
        console.log(invise)



        setInvise(temp)

    }*/



    const [mainComment, setMainComment] = useState(props.maincomment || '')

    const defaultState = props.defaultState || {
        cars: props.cars,
        teams: [],
        staff: props.staff.map(el => ({...el, disabled: false})),
        objects: objectsArr,
        count: 1,
        comment: mainComment
    }
    const [state, setState] = useState(defaultState);
    const [staffChange, setstaffChange] = useState(0);
    const [objectsChange, setObjectsChange] = useState(0);
    const [commentsChange, setCommentsChange] = useState(0);
    const [preView, setPreView] = useState(false)
    const [sending, setSending] = useState(false)


    const planTitle = () => {
        let datestr = moment().add(1, 'days').format('L');
        return `План работ на ${datestr}`
    };
    const setImg = (id) => {
        switch (id) {
            case 1:
                return Crafter;
            case 2:
                return Crafter;
            case 3:
                return Caddy;
            case 4:
                return Touran;
            case 5:
                return Kangoo;
            default:
                return Man
        }
    };
    const eventAddNewTeam = (elemId, name) => {
        //убираем из меню выбранный автомобиль
        let teamsArr = state.teams;
        let id = state.count;
        teamsArr.push({
            id: id,
            carId: elemId,
            carName: name,
            imgSrc: setImg(Number(elemId)),
            staff: [],
            objects: [],
            comment: []
        })
        setState(prev => {
            return {
                ...prev,
                cars: Number(elemId) !== 99 ? prev.cars.filter(el => Number(el.id) !== Number(elemId)) : prev.cars,
                teams: teamsArr,
                count: state.count + 1
            }
        });
    };
    const setCurrentElem = (id) => {
        let currentElem = state.teams.filter((el) => Number(el.id) === Number(id));
        return currentElem[0]
    }
    let carsArrayToRender = state.cars.map((el) => {
        return (
            <div key={el.id} onClick={() => {
                eventAddNewTeam(el.id, el.name)
            }} className={s.planning__menu__item}>
                <img src={setImg(Number(el.id))} alt=""/>
                <div className={s.planning__menu__item__title}>{el.name}</div>
            </div>
        )
    });
    const eventChangeDriver = (id, val) => {
        let tempArray = state.teams.map(el => el.id === id ? {...el, driver: Number(val)} : el);
        setState(prev => {
            return {...prev, teams: tempArray}
        })
    }
    const eventChangeLead = (id, val) => {
        let tempArray = state.teams.map(el => el.id === id ? {...el, lead: Number(val)} : el);
        setState(prev => {
            return {...prev, teams: tempArray}
        })
    }
    const checkName = (id) => {
        return props.staff.map(el => Number(el.id) === Number(id) ? `${el.firstname} ${el.lastname}` : '').filter(el => el !== '')
    };
    const staffPost = (array) => {
        let sarr = state.teams.filter(el => Number(el.id) === Number(staffChange));
        sarr = sarr[0].staff;
        let deletedStaffArray = state.staff;
        for (let i = 0, len = array.length; i < len; i++) {
            sarr.push({name: checkName(array[i]), id: Number(array[i]), driver: false})
            deletedStaffArray = deletedStaffArray.map(el => Number(el.id) !== Number(array[i]) ? el : {
                ...el,
                disabled: true
            })
        }
        let currentStaffArray = state.teams.map((el) => {
            return Number(el.id) === Number(staffChange) ? {...el, staff: sarr} : el
        });
        setState(prev => {
            return {
                ...prev,
                teams: currentStaffArray,
                staff: deletedStaffArray
            }
        });
        console.log(state)
    };
    const staffDelete = (id, state_id) => {
        let deletedStaffArray = state.teams.map(el => Number(el.id) !== Number(state_id) ?
            el
            :
            {
                ...el,
                staff: el.staff.filter(el => Number(el.id) !== Number(id)),
                driver: Number(el.driver) === Number(id) ? '' : el.driver,
                lead: Number(el.lead) === Number(id) ? '' : el.lead
            });

        let staffArray = state.staff.map(el => Number(el.id) === Number(id) ? {...el, disabled: false} : el);

        setState(prev => {
            return {
                ...prev,
                teams: deletedStaffArray,
                staff: staffArray
            }
        });
    };
    const checkObject = (id) => {
        let obj = {};
        for (let i = 0, len = props.objects.length; i < len; i++) {
            if (Number(props.objects[i].id) === Number(id)) {
                obj = {
                    id: Number(props.objects[i].id),
                    name: props.objects[i].name,
                    type: Number(props.objects[i].type),
                    district: props.objects[i].district,
                    avalible_day: props.objects[i].avalible_day,
                    long_name: props.objects[i].long_name,
                    access: props.objects[i].access,
                    contacts: props.objects[i].contacts,
                    contact_name: props.objects[i].contact_name,
                    description: props.objects[i].description,
                }
            }
        }
        return obj
    }
    const objectsPost = (array) => {

        let sarr = state.teams.filter(el => Number(el.id) === Number(objectsChange));
        sarr = sarr[0].objects;
        let deletedObjectsArray = state.objects;
        for (let i = 0, len = array.length; i < len; i++) {
            sarr.push({name: checkObject(array[i]).name, id: Number(checkObject(array[i]).id), comment: ''})
            deletedObjectsArray = deletedObjectsArray.map(el => Number(el.id) !== Number(array[i]) ? el : {
                ...el,
                disabled: true
            })
        }
        let currentObjectsArray = state.teams.map((el) => {
            return Number(el.id) === Number(objectsChange) ? {...el, objects: sarr} : el
        });
        setState(prev => {
            return {
                ...prev,
                teams: currentObjectsArray,
                objects: deletedObjectsArray
            }
        });
    };
    const objectsDelete = (id, state_id) => {
        let deletedObjectsArray = state.teams.map(el => Number(el.id) !== Number(state_id) ?
            el
            :
            {...el, objects: el.objects.filter(el => Number(el.id) !== Number(id))});

        let objectsArray = state.objects.map(el => Number(el.id) === Number(id) ? {...el, disabled: false} : el);
        setState(prev => {
            return {
                ...prev,
                teams: deletedObjectsArray,
                objects: objectsArray
            }
        });
    };
    const compare = (a, b) => {
        if (a.id < b.id) {
            return -1;
        }
        if (a.id > b.id) {
            return 1;
        }
        return 0;
    }
    const commentsPost = (text, teamId, objId) => {
        let s;
        if (Number(objId) === 99999){
            s = state.teams.map(el => Number(el.id) === Number(teamId) ? {...el, comment: text} : el);
        } else {
            let setObj = (e) => e.map(el => Number(el.id) === Number(objId) ? {...el, comment: text} : el);
            s = state.teams.map(el => Number(el.id) === Number(teamId) ? {...el, objects: setObj(el.objects)} : el);
        }

        setState(prev => {
            return {
                ...prev,
                teams: s
            }
        });
    }
    const deleteTeam = (id) => {

        let elem = setCurrentElem(id)
        let staffArray = state.staff, objectsArray = state.objects, teams = [];
        for (let i = 0, len = elem.staff.length; i < len; i++) {
            staffArray = staffArray.map(el => Number(elem.staff[i].id) === Number(el.id) ? {
                ...el,
                disabled: false
            } : el);
        }

        let tempObjArr = []

        for (let i = 0, len = state.teams.length; i < len; i++) {
            if (Number(state.teams[i].id) !== Number(id)) {
                for (let count = 0, len_2 = state.teams[i].objects.length; count < len_2; count++) {
                    tempObjArr.push(state.teams[i].objects[count].id)
                }

            }
        }
        objectsArray = state.objects.map(el => {
            let isDisabledObj = false;
            for (let i = 0, len = tempObjArr.length; i < len; i++) {
                if (Number(el.id) === Number(tempObjArr[i])) {
                    isDisabledObj = true
                }
            }
            return isDisabledObj ? el : {...el, disabled: false}
        });


        teams = state.teams.map(el => {
            return Number(el.id) !== Number(id) ? el : ''
        }).filter(el => el !== '')

        let carsArray = state.cars;
        if (Number(elem.carId) !== 99) {
            let currentCarElem = props.cars.filter((el) => Number(el.id) === Number(elem.carId));
            carsArray.push(currentCarElem[0])
        }
        carsArray.sort(compare);

        setState(prev => {
            return {
                ...prev,
                teams: teams,
                objects: objectsArray,
                staff: staffArray,
                cars: carsArray
            }
        });
    };


    const key = 'updatable';
    const openMessage = () => {
        message.loading({content: 'Загрузка...', key});
        setTimeout(() => {
            message.success({content: 'Готово!', key, duration: 2});
        }, 1000);
        setTimeout(() => {
            setSending(false)
        }, 1000)
    };
    const send = () => {
        setSending(true)
        setTimeout(() => {
            openMessage();
        }, 1000)
        const setDates = () => {
            let currentDate = new Date();
            let create_date = +new Date()
            let used_date = currentDate.setDate(currentDate.getDate() + 1)
            return [(create_date / 1000).toFixed(0), (used_date / 1000).toFixed(0)]
        };


        const createStr = (array) => {
            let str = []
            for (let i = 0, len = array.length; i < len; i++) {
                str.push(array[i].id)
            }
            return str.join()
        }
        const createStrComment = (array) => {
            let str = []
            for (let i = 0, len = array.length; i < len; i++) {
                str.push(array[i].comment)
            }
            return str.join('`')
        }

        let postArr = []
        for (let i = 0, len = state.teams.length; i < len; i++) {
            postArr.push([i + 1, Number(state.teams[i].carId), state.teams[i].driver, state.teams[i].lead, createStr(state.teams[i].staff), createStr(state.teams[i].objects), createStrComment(state.teams[i].objects),state.teams[i].comment])
        }
        let body = {text: props.defaultState ? 'План дополнен или изменен' : 'Новый план работ', link: 'https://gdapp.org/current_plan/', change: !!props.defaultState};

        console.log(postArr)
        props.post(postArr, setDates(), props.id || 0, body, mainComment)
        props.close()

    }
    let teamsArrayToRender = state.teams.map((el, index) => {
        return (
            <div key={el.id}>
                <div className={s.team_title}>
                    <div className={s.team_title__name}>
                        {`Команда ${index + 1} | ${el.carName}`}
                    </div>
                    <div className={s.team_title__props}>
                        {`Объекты: ${setCurrentElem(el.id).objects.length}  |   Сотрудники: ${setCurrentElem(el.id).staff.length}`}
                    </div>
                    <div className={s.team_title__props}>

                        <div style={{
                            width: '90%',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            justifyContent: 'space-around',
                            alignContent: 'center',
                            alignItems: 'center',
                            marginTop: '5px'
                        }}>
                            <div>
{/*                                <Button onClick={() => {}} style={{
                                    width: '100px',
                                    height: '20px',
                                    padding: '5px',
                                    fontSize: '12px',
                                    lineHeight: '5px'
                                }}> Скрыть </Button>*/}
                            </div>
                            <div>
                                <DeleteOutlined
                                    onClick={() => {
                                        deleteTeam(Number(el.id))
                                    }}/>
                            </div>


                        </div>

                    </div>
                </div>
                <div key={el.id} className={s.card_container} >

                    <PlanStaffCard
                        title={el.name}
                        id={el.id}
                        staff={setCurrentElem(el.id).staff}
                        change={setstaffChange}
                        delete={staffDelete}
                    />
                    <PlanTitleCard
                        data={setCurrentElem(el.id).staff}
                        lead={el.lead}
                        driver={el.lead}
                        eventChangeDriver={eventChangeDriver}
                        eventChangeLead={eventChangeLead}
                        id={el.id}
                        name={el.carName}
                    />
                    <PlanObjectsCard
                        title={el.name}
                        id={el.id}
                        objects={setCurrentElem(el.id).objects}
                        change={setObjectsChange}
                        delete={objectsDelete}
                    />
                    <PlanCommentsCard
                        title={el.name}
                        id={el.id}
                        objects={setCurrentElem(el.id).objects}
                        comment={setCurrentElem(el.id).comment}
                        change={setCommentsChange}
                        post={commentsPost}
                    />
                </div>
            </div>
        )
    });
    return (<>
            <div className={s.planning__menu__header}>Для добавления в план рабочей команды - выберите транспорт...
            </div>
            <div className={s.planning__menu}>
                {carsArrayToRender}
            </div>
            <div className={s.content}>
                <Divider plain={true} orientation="left">
                    <Title className={s.title} align={'center'}
                           level={4}>{props.title || planTitle()}
                    </Title>
                </Divider>
                <div className={'buttunCont'}>
                    <Button onClick={() => {
                        setPreView(!preView)
                    }} type="primary" size={'small'}>
                        {preView ? 'Закрыть' : 'Просмотреть план'}
                    </Button>
                    <Button onClick={() => {
                        send()
                    }} type="primary" size={'small'} danger loading={sending}>
                        {sending ? 'Отправляем...' : 'Отправить план в работу'}
                    </Button>

                    {props.writes &&                     <Button onClick={() => {
                        props.writes()
                    }} type="primary" size={'small'}>Вернутся к списку планов</Button>}



                </div>

                <div className={s.inputForCommentCont}><input className={s.inputForComment} placeholder={'Общий комментарий для плана...'} value={mainComment} onChange={(val)=>{setMainComment(val.target.value)}}/></div>

                {preView ? <CurrentPlan
                        title={props.title || planTitle()}
                        close={() => {
                            setPreView(false)
                        }}
                        content={state.teams}
                        staff={state.staff}
                        objects={state.objects}
                        old={false}

                    />
                    :
                    <div className={s.row__container}>
                        {teamsArrayToRender}
                    </div>}


                {staffChange !== 0 ? <PlanStaffChange
                    staff={state.staff}
                    defaultStaff={setCurrentElem(staffChange).staff}
                    close={setstaffChange}
                    post={staffPost}
                    title={setCurrentElem(staffChange).carName}

                /> : ''}

                {objectsChange !== 0 ? <PlanObjectsChange
                    objects={state.objects}
                    defaultObjects={setCurrentElem(objectsChange).objects}
                    close={setObjectsChange}
                    post={objectsPost}
                    title={setCurrentElem(objectsChange).carName}

                /> : ''}
                {commentsChange !== 0 ? <PlanCommentsChange
                    comments={setCurrentElem(commentsChange).comments}
                    objects={setCurrentElem(commentsChange).objects}
                    close={setCommentsChange}
                    post={commentsPost}
                    title={setCurrentElem(commentsChange).carName}
                /> : ''}

            </div>
        </>
    )


};


export default Planning;