import {Empty, Space, Table, Tag, Button} from "antd";
import React, {Component} from "react";
import s from '../styles/staff.module.css'
import {connect} from "react-redux";
import { FileDoneOutlined, EditOutlined, StarTwoTone } from '@ant-design/icons';

class StaffList extends Component {
    constructor(props) {
        super(props);
    }

    columns = [
        {
            title: 'Имя',
            dataIndex: 'firstname',
            key: 'firstname',

        },
        {
            title: 'Фамилия',
            dataIndex: 'secondname',
            key: 'secondname',
        },
        {
            title: 'Роли',
            key: 'tags',
            dataIndex: 'tags',
            render: tags => (
                <span>
        {tags.map(tag => {
            let color = tag.length > 8 ? 'geekblue' : 'green';
            if (tag === 'loser') {
                color = 'volcano';
            }
            return (
                <Tag style={{margin: '0 5px', fontSize: '10px'}} color={color} key={tag}>
                    {tag.toUpperCase()}
                </Tag>
            );
        })}
      </span>
            ),
        },
        {
            title: 'Анкета',
            key: 'id',
            align: 'center',
            render: id => (
                <Space size="middle">

                    <a onClick={() => {
                        this.props.openCard(id)
                    }}><FileDoneOutlined /></a>
                    {/* <a>Удалить</a>*/}
                </Space>
            ),
        },
        {
            title: 'Редактировать',
            key: 'id',
            align: 'center',
            render: id => (
                <Space size="middle">
                    <a onClick={() => {
                        this.props.edit(id)
                    }}><EditOutlined /></a>
                    {/* <a>Удалить</a>*/}
                </Space>
            ),
        },
    ];


    setRoles = (roleArray) => {


        let result = [];
        let staffRolesArr = roleArray.split(',');
        for (let i = 0, len = staffRolesArr.length; i < len; i++){
            for (let c = 0, len1 = this.props.rules.length; c < len1; c++){
                if (Number(staffRolesArr[i]) === Number(this.props.rules[c].id)){
                    result.push(this.props.rules[c].long_name)
                }
            }
        }
        return result
    }

    render() {

        if (this.props.data.length > 0 && this.props.rules.length > 0) {
            this.staffArray = this.props.data.map((el) => {
                return {key: el.id, id: Number(el.id), firstname: el.firstname, secondname: el.lastname, tags: this.setRoles(el.role)}
            });

            return (
        <>
                <div className={s.addStaffBtn}><Button type="primary" onClick={()=>{this.props.setPage(2)}}>Добавить сотрудника</Button></div>
                <Table
                columns={this.columns}
                pagination={{position: ['none', 'bottomLeft']}}
                dataSource={this.staffArray}
                style={{ padding: 20 }}
            />
            </>)


        } else {
            return <Empty description={<span> Доступ к разделу запрещен.</span>}/>
        }
    }
}

const mapStateToProps = (state) => {
    return {
        rules: state.staffRDCR.rules,

    }
};


export default connect(mapStateToProps, {

})(StaffList);