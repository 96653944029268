import React, {useState} from "react";
import {Input, Tag, Typography, Modal, Radio, Select, Alert} from 'antd';
import './styles/store.css'
import s from './styles/store.module.css'
const {Option} = Select;
const {Title} = Typography;


const ModalWin = (props) => {
    let [measure, setMeasure] = useState(0);     //меняем единицы измерения
    let [nameStoreFrom, setNameStoreFrom] = useState(''); //сохраняем склад - отправитель
    let [nameStoreTo, setNameStoreTo] = useState(''); //сохраняем склад - получатель
    let [count, setCount] = useState(0); //устанавливаем количество перемещаемых товаров
    let [alert, setalert] = useState(false); //устанавливаем количество перемещаемых товаров
    let [transfer, settransfer] = useState(false); //устанавливаем количество перемещаемых товаров
    let [comment, setcomment] = useState(''); //устанавливаем количество перемещаемых товаров
    let [isNull, setIsNull] = useState(false); //устанавливаем количество перемещаемых товаров





    const summAllStores = (e) => {
        // Суммируем количество товаров на всех складах
        let summ = 0;
        for (let i = 0; i < props.stores.length; i++) {
            if (Number(props.stores[i].type) !== 4) {
                summ += Number(e[`store_${props.stores[i].name_db}`]);
            }

        }
        return summ;
    };

    const storesOfTypes =  props.stores ? props.stores.map((el) => {
        //Формируем массив складов - отправителей
        return Number(el.type) === props.storesTypeId ? {
            id: Number(el.id),
            name: el.name,
            name_db: `store_${el.name_db}`,
            count: props.element ? props.element[`store_${el.name_db}`] : 0
        } : ''
    }).filter((el) => {
        return el !== ''
    }) : [];

    const storesAll = props.stores ? props.stores.map((el) => {
        //Формируем массив складов - получателей
        return el.name_db !== nameStoreFrom ? {
            id: Number(el.id),
            name: el.name,
            name_db:  `store_${el.name_db}`,
            count: props.element ? props.element[`store_${el.name_db}`] : 0
        } : ''
    }).filter((el) => {
        return el !== ''
    }) : [];

    const changeMeasure = (val) => {
        //Устанавливаем единицу измерений
        setMeasure(val);
        setCount(0)
    };
    const eventSelectFrom = (input) => {
        // Событие при выборе Склада отправления
        setNameStoreFrom(input);
        setNameStoreTo('');
        setCount(0);
    };
    const eventSelectTo = (input) => {
        // Событие при выборе Склада отправления
        setNameStoreTo(input)
        setCount(0);
    };

    const SelectFrom = (props) => {
        // Выбор Склада - отправителя
        return (
            <Select
                style={{width: 200}}
                placeholder="Выберите склад"
                optionFilterProp="children"
                defaultValue={nameStoreFrom}
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
                onChange={(input) => {
                    eventSelectFrom(input)
                }}
            >
                {props.store.map((el) => {
                    return <Option key={el.id} value={el.name_db}>{el.name}</Option>
                })
                }
            </Select>
        )
    };

    const SelectTo = (props) => {
        // Выбор Склада - получателя
        return (
            <Select
                style={{width: 200}}
                placeholder="Выберите склад"
                optionFilterProp="children"
                defaultValue={nameStoreTo}
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
                onChange={(input) => {
                    eventSelectTo(input)
                }}
            >
                {props.store.map((el) => {
                    return <Option key={el.id} value={el.name_db}>{el.name}</Option>
                })
                }
            </Select>
        )
    };







    const radiomodule = (name) => {

        if (name) {
            if (name === 'L' || name === 'ml') {
                return (
                    <div align={'center'} className={'select_measure'}>
                        <p>Выберите единицу измерения:</p>
                        <div className={'radiomodule'}>
                            <Radio.Group size={'small'} defaultValue="ml" buttonStyle="solid">
                                <Radio.Button className={s.btn__1} onChange={() => {
                                    changeMeasure(0)
                                }} value="ml">Миллилитры</Radio.Button>
                                <div className={s.btn__1}></div>
                                <Radio.Button className={s.btn__1} onChange={() => {
                                    changeMeasure(1)
                                }} value="L">Литры</Radio.Button>
                                <div className={s.btn__1}></div>
                                <Radio.Button className={s.btn__1} onChange={() => {
                                    changeMeasure(2)
                                }} value="Упак">Упаковка</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                )

            } else if (name === 'Kg' || name === 'g') {
                return (
                    <>
                        <p>Выберите единицу измерения:</p>
                        <div className={'radiomodule'}>
                            <Radio.Group size={'small'} defaultValue="g" buttonStyle="solid" style={{marginTop: 16}}>
                                <Radio.Button onChange={() => {
                                    changeMeasure(0)
                                }} value="g">Граммы</Radio.Button>
                                <Radio.Button onChange={() => {
                                    changeMeasure(1)
                                }} value="Kg">Килограммы</Radio.Button>
                                <Radio.Button onChange={() => {
                                    changeMeasure(2)
                                }} value="Упак">Упаковка</Radio.Button>
                            </Radio.Group>
                        </div>
                    </>
                )
            } else if (name === 'M' || name === 'sm') {
                return (
                    <>
                        <p>Выберите единицу измерения:</p>
                        <div className={'radiomodule'}>
                            <Radio.Group size={'small'} defaultValue="sm" buttonStyle="solid" style={{marginTop: 16}}>
                                <Radio.Button onChange={() => {
                                    changeMeasure(0)
                                }} value="sm">Сантиметры</Radio.Button>
                                <Radio.Button onChange={() => {
                                    changeMeasure(1)
                                }} value="M">Метры</Radio.Button>
                                <Radio.Button onChange={() => {
                                    changeMeasure(2)
                                }} value="Упак">Упаковка</Radio.Button>
                            </Radio.Group>
                        </div>
                    </>
                )
            } else if (name === 'шт' || name === 'M2') {
                return (
                    <>

                    </>
                )
            }


        }


    }





    const cancelEvent = () => {
        props.setModal();
    };
    const post = () => {
        debugger
        let currentCount = Number(count);
        if (currentCount <= 0){
            setIsNull(true)
        } else {
            if (isNull) {
                setIsNull(false)
            }


            let result = 0;

            let nameOfPack = props.element.package_name;
            let countPackInStore = Number(props.element.package);


            let fromCount = Number(props.element[nameStoreFrom])
            let toCount = Number(props.element[nameStoreTo]);

            if (nameOfPack === 'L' || nameOfPack === 'ml' || nameOfPack === 'Kg' || nameOfPack === 'g') {
                if (measure === 0) {
                    result = nameOfPack === 'L' || nameOfPack === 'Kg' ? currentCount / (countPackInStore * 1000) : currentCount / countPackInStore;  // считаем в мм - упак в Л / мл
                } else if (measure === 1) {
                    result = nameOfPack === 'L' || nameOfPack === 'Kg' ? currentCount / countPackInStore : (currentCount * 1000) / countPackInStore;  // считаем в Л - упак в Л / мл
                } else if (measure === 2) {
                    result = currentCount;
                }
            } else if (nameOfPack === 'M' || nameOfPack === 'sm') {
                if (measure === 0) {
                    result = nameOfPack === 'M' ? currentCount / (countPackInStore * 100) : currentCount / countPackInStore;  // считаем в мм - упак в Л / мл
                } else if (measure === 1) {
                    result = nameOfPack === 'M' ? currentCount / countPackInStore : (currentCount * 100) / countPackInStore;  // считаем в Л - упак в Л / мл
                } else if (measure === 2) {
                    result = currentCount;
                }
            } else if (nameOfPack === 'шт' || nameOfPack === 'M2') {
                result = currentCount;
            }

            if ((fromCount - Number(result)) < 0) {
                setalert(true)
            } else {
                setalert(false)
                settransfer(true)
                props.update(props.element.id, nameStoreFrom, fromCount - Number(result), nameStoreTo, toCount + Number(result), comment);
                setTimeout(() => {
                    props.setModal()
                }, 1000);

            }
        }
    };


    const l = (e) => {
        setCount(Number(e.target.value))
    };

    let measurNameArr = [
        'L',
        'ml',
        'Kg',
        'g',
        'уп',
        'M',
        'sm',
        'шт',
        'M2'
    ];
    let NumName = 0;

    const ostatok = (StoreCount, PackageName, count, withName) => {
        let result = 0;

        if (PackageName === 'L' || PackageName === 'ml') {
            if (measure === 0) {
                result = PackageName === 'L' ? (count * StoreCount) * 1000 : count * StoreCount;
                NumName = 1
            } else if (measure === 1) {
                result = PackageName === 'L' ? count * StoreCount : (count * StoreCount) / 1000;
                NumName = 0
            } else if (measure === 2) {
                result = StoreCount;
                NumName = 4
            }
        } else if (PackageName === 'Kg' || PackageName === 'g') {
            if (measure === 0) {
                result = PackageName === 'Kg' ? (count * StoreCount) * 1000 : count * StoreCount;
                NumName = 3
            } else if (measure === 1) {
                result = PackageName === 'Kg' ? count * StoreCount : (count * StoreCount) / 1000;
                NumName = 2
            } else if (measure === 2) {
                result = StoreCount;
                NumName = 4
            }
        } else if (PackageName === 'M' || PackageName === 'sm') {
            if (measure === 0) {
                result = PackageName === 'M' ? (count * StoreCount) * 100 : count * StoreCount;
                NumName = 6
            } else if (measure === 1) {
                result = PackageName === 'M' ? count * StoreCount : (count * StoreCount) / 100;
                NumName = 5
            } else if (measure === 2) {
                result = StoreCount;
                NumName = 4
            }
        } else if (PackageName === 'шт' || PackageName === 'M2') {
            result = StoreCount;
            NumName = 7
        }
        return withName === 0 ? `${result.toFixed(1)} ${measurNameArr[NumName]}` : result.toFixed(1)
    };


    const remains = (mod, withName, source) => {
        let currentCount = Number(count);
        let nameOfPack = props.element.package_name;
        let countPackInStore = props.element.package;
        let storeCount = source === 1 ? Number(props.element[nameStoreFrom]) : Number(props.element[nameStoreTo]);

        let result = 0;
        if (nameOfPack === 'L' || nameOfPack === 'ml') {
            if (measure === 0) {
                result = nameOfPack === 'L' ? ((countPackInStore * storeCount) * 1000) + (currentCount * mod) : (countPackInStore * storeCount) + (currentCount * mod);
                NumName = 1
            } else if (measure === 1) {
                result = nameOfPack === 'L' ? (countPackInStore * storeCount) + (currentCount * mod) : ((countPackInStore * storeCount) / 1000) + (currentCount * mod);
                NumName = 0
            } else if (measure === 2) {
                result = storeCount + (currentCount * mod)
                NumName = 4
            }
        } else if (nameOfPack === 'Kg' || nameOfPack === 'g') {
            if (measure === 0) {
                result = nameOfPack === 'Kg' ? ((countPackInStore * storeCount) * 1000) + (currentCount * mod) : (countPackInStore * storeCount) + (currentCount * mod);
                NumName = 3
            } else if (measure === 1) {
                result = nameOfPack === 'Kg' ? (countPackInStore * storeCount) + (currentCount * mod) : ((countPackInStore * storeCount) / 1000) + (currentCount * mod);
                NumName = 2
            } else if (measure === 2) {
                result = storeCount + (currentCount * mod);
                NumName = 4
            }
        } else if (nameOfPack === 'M' || nameOfPack === 'sm') {
            if (measure === 0) {
                result = nameOfPack === 'M' ? ((countPackInStore * storeCount) * 1000) + (currentCount * mod) : (countPackInStore * storeCount) + (currentCount * mod);
                NumName = 6
            } else if (measure === 1) {
                result = nameOfPack === 'M' ? (countPackInStore * storeCount) + (currentCount * mod) : ((countPackInStore * storeCount) / 1000) + (currentCount * mod);
                NumName = 5
            } else if (measure === 2) {
                result = storeCount + (currentCount * mod);
                NumName = 4
            }
        } else if (nameOfPack === 'шт' || nameOfPack === 'M2') {
            result = storeCount + (currentCount * mod);
            NumName = 7

        }
        return withName === 0 ? `${result.toFixed(1)} ${measurNameArr[NumName]}` : result.toFixed(1)
    };


    return (

        <>
            {props.element && props.modal ?

                <Modal
                    title={<Title level={5}>{`${props.element.catname} | ${props.element.name}`}</Title>}
                    centered
                    visible={props.modal}
                    onOk={() => post()}
                    onCancel={() => cancelEvent()}
                    okText="Переместить"
                    cancelText="Отмена"

                >
                    <p>Производитель: <Tag color="gold">{props.element.brand}</Tag></p>
                    <p>Упаковка: <Tag color="green">{`${props.element.package}${props.element.package_name}`}</Tag></p>

                    {radiomodule(props.element.package_name)}

                    <p>В наличии на всех складах: <Tag
                        color="purple">{ostatok(summAllStores(props.element), props.element.package_name, props.element.package, 0)}</Tag>
                    </p>


                    <div className={'label_cont'}>
                        <div className={'label'}>{`Переместить из:  `}</div>
                        <SelectFrom store={storesOfTypes} size={'small'}/>
                        {
                            remains( -1, 1, 1) >= 0 ?
                            <div className={'label_2'}>
                                {` Остаток: ${!isNaN(remains( -1, 1, 1)) ? remains( -1, 0, 1) : ''}`}
                            </div>
                            :
                            <div className={'label_2'}>
                                <Tag color="red">
                                {` Остаток: ${!isNaN(remains( -1, 1, 1)) ? remains( -1, 0, 1) : ''}`}
                                </Tag>
                            </div>
                        }
                    </div>
                    <div className={'label_cont'}>
                        <div className={'label'}>{`Переместить в:  `}</div>
                        <SelectTo store={storesAll} size={'small'}/>
                        <div
                            className={'label_2'}>{`Остаток: ${!isNaN(remains(1, 1, 2)) ? remains(1, 0, 2) : ''}`}</div>
                        </div>
                            <div className={'label_cont'}>
                            <div className={'label'}>{`Количество:  `}</div>
                            <Input type={'number'} onChange={l} value={count} min={0} step={1}
                            placeholder={"Укажите количество для перемещения..."}/>
                    </div>
                    <div className={'label_cont'}>
                        <div className={'label'}>{`Комментарий:  `}</div>
                        <Input value={comment} onChange={(input) => {setcomment(input.target.value)}} placeholder="Комментарий"/>
                    </div>
                    { alert ? <Alert message="Нельзя переместить больше, чем есть..." type="error"  showIcon/> : ''}
                    { isNull ? <Alert message="Укажите корректное количество..." type="error"  showIcon/> : ''}
                    { transfer ? <Alert message="Перемещение выполнено!" type="success" showIcon/> : ''}
                </Modal> : ''}
        </>
    )


}


export default ModalWin;