import React, {useState} from "react";
import {Form, Input, Modal, Select} from "antd";

const AddPaymentModal = (props) => {
    let defaultAddPayments = {
        id: props.isModalVisible.id,
        type: 1,
        nds: 0,
        cost: 0
    }

    let [addpaymentsstate, setaddpaymentsstate] = useState(defaultAddPayments)

    const handleChangeType = (e) => {
        let tempstate = {...addpaymentsstate}
        tempstate.type = Number(e)
        setaddpaymentsstate(tempstate)
    }


    const changeCost = (value) => {
        let tempstate = {...addpaymentsstate}
        tempstate.cost = value.replace(',','.')
        setaddpaymentsstate(tempstate)
    }

    const handleChangeNds = (e) => {
        let tempstate = {...addpaymentsstate}
        tempstate.nds = Number(e)
        setaddpaymentsstate(tempstate)
    }

    return (
        <>
            <Modal title={`Добавить платеж по объекту ${props.isModalVisible.name}`} visible={props.isModalVisible.visible} onOk={()=>{props.ok(addpaymentsstate)}} onCancel={()=>{props.cancel()}}>
                <Form.Item label="Тип оплаты">
                    <Select defaultValue={Number(addpaymentsstate.type)}onChange={handleChangeType}>
                        <Select.Option value={1}>Наличные</Select.Option>
                        <Select.Option value={2}>Безнал</Select.Option>
                    </Select>
                </Form.Item>
                {Number(addpaymentsstate.type) === 2 &&
                <Form.Item label="НДС">
                    <Select defaultValue={Number(addpaymentsstate.nds)} onChange={handleChangeNds}>
                        <Select.Option value={0}>БЕЗ НДС</Select.Option>
                        <Select.Option value={7}>НДС 7%</Select.Option>
                        <Select.Option value={21}>НДС 21%</Select.Option>
                    </Select>
                </Form.Item>}
                <Form.Item label="Сумма">
                    <Input value={addpaymentsstate.cost} onChange={(val) => {
                        changeCost(val.target.value)
                    }}/>
                </Form.Item>
                <p>{`Всего с Ндс: ${addpaymentsstate.type === 1 ? Number(addpaymentsstate.cost) : (Number(addpaymentsstate.cost) + ((Number(addpaymentsstate.cost) / 100) * addpaymentsstate.nds)).toFixed(2)} €`}</p>
            </Modal>
        </>
    );
};

export default AddPaymentModal