import React, {useEffect, useState} from "react";
import {Modal, Button, Image, Divider} from 'antd';
import s from './styles/profile.module.css'

const ModalReports = (props) => {

    let [v, setV] = useState(false)

    useEffect(()=>{setTimeout(setV(true), 2000)},[props.links])


    /*    comments_id: "Летол 100 мл`Фунгуран 30 гр`Алеандры````"
        id: "22"
        object_id: "22"
        plan_id: "124"
        time_download: "1640012847"
        time_end: "1640012703"
        time_plan: "1639972028"
        time_start: "1640009400"
        works_id: "2,3,5,6,8,14,17"*/


    const setHours = (start) => {
        let timestart = new Date(Number(start) * 1000)
        let timestartHour = timestart.getHours() > 9 ? timestart.getHours() : `0${timestart.getHours()}`
        let timestartMinute = timestart.getMinutes() > 9 ? timestart.getMinutes() : `0${timestart.getMinutes()}`

        return `${timestartHour}:${timestartMinute}`

    }

    const worksName = (id) => {
        let name = ''
        props.template.forEach(el => {
            name += Number(el.id) === Number(id) ? el.name : ''
        })
        return name
    }
    let comments = props.elem.comments_id.split('`')

    let worksArr = props.elem.works_id.split(',')

    let workRender = worksArr.map((el, index) => {
        return <p>{`${index + 1}. ${worksName(Number(el))}`}{comments[index] !== '' ? ` | ${comments[index]}` : ''}</p>
    })


    const setInterval = (time) => {
        let minutes = (time / 60).toFixed(0)
        let hours = minutes > 60 ? (minutes/60).toFixed(0) : 0
        minutes = hours > 1 ? (minutes%60) : minutes
        return hours > 1 ? `${hours}ч ${minutes}мин` : `${minutes}мин`
    }

    let images = props.links.map((el,index) => {
        return (
            <Image
                key={index}
                width={200}
                src={`http://gdapp.org/data/${el.link}`}
            />
        )
    })



    return (
        <Modal
            title={`Отчет о выполненных работах | ${props.title}`}
            centered
            visible={true}
            onOk={() => props.close(false)}
            onCancel={() => props.close(false)}
            width={1000}
        >
            {Number(props.elem.time_start) === 0 ?
            <div className={s.modal_cont}>
                <div className={s.modal_item}>
                    <p><b>{`Объект был пропущен!`}</b></p>



            </div>
            </div>

            :
            <div className={s.modal_cont}>
                <div className={s.modal_item}>
                    <p>Начало работы: <b>{setHours(props.elem.time_start)}</b></p>
                    <p>Окончание работы: <b>{setHours(props.elem.time_end)}</b></p>
                    <p>Отчет загружен в : <b>{setHours(props.elem.time_download)}</b></p>
                    <p>Затраченное время: <b>{setInterval(Number(props.elem.time_end) - Number(props.elem.time_start))}</b></p>



                </div>
                <div className={s.modal_item}>
                    <p><b>Выполненные работы:</b></p>
                    {workRender}
                </div>

            </div>
            }

            <Divider orientation="left" plain>
                Фотографии
            </Divider>
            <div className={s.images}>
                {v ? images : ''}
            </div>

        </Modal>
    )


}

export default ModalReports