import React, {Component} from "react";
import logo from '../../assets/logo.png'
import s from "./mobile.module.css";
import {connect} from "react-redux";
import {
    getMobilePlanThunkCreator, getReportsByObjectIdThunkCreator,

    postReportThunkCreator
} from "../../redux/reducers/mobileReducer";
import MobilePlansList from "./MobilePlansList";

class GeneralMobile extends Component {
    constructor(props){
        super(props)
    }
    componentDidMount() {
       this.props.getMobilePlanThunkCreator()
    }
    render() {
        return (
            < div className={s.main}>
                <div className={s.status}>
                    <div className={`${s.header}`}>Garden Design APP</div>
                    <div className={s.status_line}>
                    <div className={`${s.status__label} ${s.noselect}`}>{`Login as: ${this.props.username}`} </div>
                    <div onClick={() => {
                        this.props.exit()
                    }} className={`${s.status__exit} ${s.noselect} ${s.pointer}`}>Exit
                    </div>
                    </div>
                </div>
                <div className={s.mobcontent}>
                    {this.props.loading ?  <div className={s.imgcontainer}><img className={s.logo} src={logo} alt=""/></div>
                        :
                        <MobilePlansList
                            objects={this.props.objects}
                            id={this.props.id}
                            plans={this.props.plans}
                            reportsTemp={this.props.reportsTemp}
                            post={this.props.postReportThunkCreator}
                            staff={this.props.staff}
                            reports={this.props.reports}
                            photo={this.props.photo}
                            get={this.props.getReportsByObjectIdThunkCreator}
                            oldreports={this.props.oldreports}
                        />}

                </div>
                <div className={s.mobfooter}>
                    Garden Design @ 2021
                </div>
            </div>)

    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.mobileRDCR.loading,
        plans: state.mobileRDCR.plans,
        objects: state.mobileRDCR.objects,
        reportsTemp: state.mobileRDCR.reportsTemp,
        staff: state.mobileRDCR.staff,
        reports: state.mobileRDCR.reports,
        photo: state.mobileRDCR.photoLinks,
        oldreports: state.mobileRDCR.oldreports,
    }
};


export default connect(mapStateToProps, {
    getMobilePlanThunkCreator,
    postReportThunkCreator,
    getReportsByObjectIdThunkCreator


})(GeneralMobile);
