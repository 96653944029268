import React from "react";
import {Card, Avatar, List} from 'antd';
import {EditOutlined, EllipsisOutlined, SettingOutlined, UserOutlined, DeleteOutlined} from '@ant-design/icons';

const {Meta} = Card;

const PlanStaffCard = (props) => {
    console.log('render component PlanStaffCard')
    const staffDelete = (e) => {
        props.delete(e,props.id)
        console.log(e)
    }



    return (
        <Card
            style={{minWidth: 230, margin: '10px auto', padding: 10, display: 'block', float: 'left', textAlign: 'left', height: 212}}
            actions={[
                <EditOutlined onClick={()=>{props.change(Number(props.id))}} key="setting"/>,
            ]}
        >
            <Meta
                style={{textAlign: 'center'}}
                title={'Сотрудники'}
                description={
                    <List
                        style={{textAlign: 'left', height: 150, overflowY: 'scroll', fontWeight: 400}}
                        itemLayout="horizontal"
                        dataSource={props.staff}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    style={{fontWeight: 400}}
                                    avatar={<UserOutlined />}
                                    description={item.name}
                                    /*description="Ant Design, a design language for background applications, is refined by Ant UED Team"*/
                                />
                                {item.driver ? '' :<DeleteOutlined onClick={()=>{staffDelete(item.id)}} style={{cursor: 'pointer'}}

                                />}
                            </List.Item>
                        )}
                    />
                }
            />

        </Card>
    )

}

export default PlanStaffCard