import React, {useState} from "react";
import {Table, Space, ConfigProvider, Calendar, Badge, Popover, Tag} from 'antd';
import Crafter from "../../assets/Crafter.png";
import Caddy from "../../assets/Caddy.png";
import Touran from "../../assets/Touran.png";
import PlanPdf from "./preview/PlanPdf";
import CurrentPlan from "./preview/CurrentPlan";
import Man from "../../assets/Man.png";
import Planning from "./Planning";
import locale from "antd/lib/locale/ru_RU";
import s from "../planning/styles/planning.module.css";
import moment from "moment";


const PlansList = (props) => {
    let [writes, setWrites] = useState(false);
    let [reads, setReads] = useState(false);
    let [teams, setTeams] = useState({title: '', teams: [],id:0});
    let [changePlan, setChangePlan] = useState([]);
    let [time, setTime] = useState({month: moment().format('MMMM'), year: moment().format('YYYY ')})
    let [cars, setCars] = useState(props.cars)
    let[maincomment, setMainComment] = useState('')


    let defaultState = {
        cars: cars,
        teams: teams.teams,
        staff: props.staff,
        objects: props.objects,
        count: teams.teams.length,
        id: 0,
        maincomment: maincomment
    }

    const change = (e) => {
        teams = e.action.props.map(el => {
            let staff = el.staff_id.split(',');
            let staffArr = [];
            for (let i = 0, len = staff.length; i < len; i++) {
                staffArr.push({name: checkName(staff[i]), id: Number(staff[i]), driver: false})
            }
            let objects = el.objects_id.split(',');
            let comments = el.comment.split('`');
            let objectsArr = [];
            for (let i = 0, len = objects.length; i < len; i++) {
                objectsArr.push({
                    name: checkObject(objects[i]).name,
                    id: Number(checkObject(objects[i]).id),
                    comment: comments[i]
                })
            }


            return ({
                id: defaultState.count++,
                carId: Number(el.car_id),
                driver: Number(el.driver),
                lead: Number(el.lead),
                carName: getCarName(Number(el.car_id))[0],
                imgSrc: setImg(Number(el.car_id)),
                staff: staffArr,
                objects: objectsArr,

            })
        })

        setChangePlan(defaultState)
    }


    const getCarName = (id) => {
        return props.cars.map(el => Number(el.id) === Number(id) ? el.name : '').filter(el => {
            return el !== ''
        })
    }
    const checkName = (id) => {
        return props.staff.map(el => Number(el.id) === Number(id) ? `${el.firstname} ${el.lastname}` : '').filter(el => el !== '')
    };
    const checkObject = (id) => {
        let obj = {};
        for (let i = 0, len = props.objects.length; i < len; i++) {
            if (Number(props.objects[i].id) === Number(id)) {
                obj = {
                    id: Number(props.objects[i].id),
                    name: props.objects[i].name,
                    type: Number(props.objects[i].type),
                    district: props.objects[i].district,
                    avalible_day: props.objects[i].avalible_day,
                    long_name: props.objects[i].long_name,
                    access: props.objects[i].access,
                    contacts: props.objects[i].contacts,
                    contact_name: props.objects[i].contact_name,
                    description: props.objects[i].description,
                }
            }
        }
        return obj
    }
    const setImg = (id) => {
        switch (id) {
            case 1:
                return Crafter;
            case 2:
                return Crafter;
            case 3:
                return Caddy;
            case 4:
                return Touran;
            default:
                return Man
        }
    };
    let title = '';

    const setData = (e) => {
        let teams = e.props.map(el => {
            let staff = el.staff_id.split(',');
            let staffArr = [];
            for (let i = 0, len = staff.length; i < len; i++) {
                staffArr.push({name: checkName(staff[i]), id: Number(staff[i]), driver: false})
            }
            let objects = el.objects_id.split(',');
            let comments = el.comment.split('`');
            let objectsArr = [];
            for (let i = 0, len = objects.length; i < len; i++) {
                objectsArr.push({
                    name: checkObject(objects[i]).name,
                    id: Number(checkObject(objects[i]).id),
                    comment: comments[i]
                })
            }

            setMainComment(e.maincomment);
            if (Number(el.car_id) !== 99){
                let newcars = cars.map(elem => Number(elem.id) !== Number(el.car_id) ? elem : '').filter(elem1 => elem1 !== '');
                setCars(newcars);
            }

            console.log(cars)
            return ({
                id: el.id,
                carId: Number(el.car_id),
                driver: Number(el.driver),
                lead: Number(el.lead),
                carName: getCarName(Number(el.car_id))[0],
                imgSrc: setImg(Number(el.car_id)),
                staff: staffArr,
                objects: objectsArr,
                comment: el.teamcomment

            })
        })
        return teams
    }

    const read = (e) => {
        let currentDate = new Date(Number(e.used_date) * 1000);
        currentDate.setDate(currentDate.getDate());
        let day = currentDate.getDate()
        let month = currentDate.getMonth() + 1
        let year = currentDate.getFullYear()

        title = `План работ №-${e.id} на ${day}.${month}.${year}`
        setTeams({title: title, teams: setData(e),id:e.id})
        setReads(true)

    }
    const write = (e) => {
        let currentDate = new Date(Number(e.used_date) * 1000);
        currentDate.setDate(currentDate.getDate());
        let day = currentDate.getDate()
        let month = currentDate.getMonth() + 1
        let year = currentDate.getFullYear()

        title = `План работ №-${e.id} на ${day}.${month}.${year}`

        setTeams({title: title, teams: setData(e),id:e.id})
        setWrites (true)
    }
    const onTestM = (e) => {
        let ns = new moment(e);
        let ne = new moment(e);
        let starttime = (ns.startOf('month').subtract(1,'month')).format('X')
        let endtime = (ne.endOf('month').add(1,'month')).format('X')
        setTime ({month: e.format('MMMM'), year: e.format('YYYY ')})
        props.getPlansByTime(starttime, endtime)
    }


    const getListData = (value) => {

        //функция возвращает дату по таймштамп
        const setDate = (timestamp) => {
            let d = new Date(Number(timestamp) * 1000)
            return d.getDate()
        }
        //функция возвращает месяц по таймштамп
        const setMonth = (timestamp) => {
            let d = new Date(Number(timestamp) * 1000)
            return Number(d.getMonth()) + 1
        }

        //функция создает контйнер с кнопками
        return props.plans.map(el => {
            const content = (
                <div className={s.change__popover}>
                    <Tag className={s.change__popover__read} onClick={() => {
                        read(el)
                    }}> Просмотреть</Tag>
                    <Tag className={s.change__popover__change} onClick={() => {
                        write(el)
                    }}>Изменить</Tag>
                </div>
            );
        //Возвращаем данные если совпадает дата и месяц
            return (setDate(el.used_date) === value.date() && setMonth(el.used_date) === Number(value.format('M'))) ? {
                type: 'none',
                content: <Popover content={content} title=""><div className={s.report_calendar_item} >{`План работ №-${el.id}`}</div></Popover>
            } : ''
        }).filter(el => el !== '') || [];
    }


    const dateCellRender = (value) => {
        const listData = getListData(value);
        return (
            <ul className="events">
                {listData.map(item => (
                    <li key={item.content}>
                        <Badge status={item.type} text={item.content}/>
                    </li>
                ))}
            </ul>
        );
    }
    const getMonthData = (value) => {
    }
    const monthCellRender = (value) => {
        const num = getMonthData(value);
        return num ? (
            <div className="notes-month">
                <section>{num}</section>
                <span>Backlog number</span>
            </div>
        ) : null;
    }

    const close = () => {
        setReads(false)
    }

    function disabledDate(current) {
        // Can not select days before today and today
        return current.format('MMMM') !== time.month;
    }


    if (reads) {
        return (
            <CurrentPlan
                title={teams.title}
                close={() => {
                    close()
                }}
                content={teams.teams}
                staff={props.staff}
                objects={props.objects}
                old={true}
                cars={props.cars}
                maincomment={defaultState.maincomment}

            />
        )
    } else if (writes) {
        return <Planning
            defaultState={defaultState}
            objects={defaultState.objects}
            staff={defaultState.staff}
            cars={props.cars}
            maincomment={defaultState.maincomment}
            title={teams.title}
            id={teams.id}
            post={props.post}
            close={()=>{
                setWrites(false)}
            }
            writes = {()=>{setWrites(false)}}

        />
    } else {
        return (
            <ConfigProvider locale={locale}>
                <div className={s.calendar_title}>{`${time.month} ${time.year} года`}</div>
                <Calendar
                    dateCellRender={dateCellRender}
                    monthCellRender={monthCellRender}
                    onPanelChange={onTestM}
                    disabledDate={disabledDate}

                />
            </ConfigProvider>
        )
    }


}

export default PlansList;