import {connect} from "react-redux";
import React from "react";
import {Component} from "react";
import s from './styles/finance.module.css'
import Expenses from "./Expenses";
import {
    getArticlesThunkCreator,
    postCarsExpensesThunkCreator,
    postIncomeThunkCreator,
    postOneExpensesThunkCreator, postOneIncomeThunkCreator,
    postOperExpensesThunkCreator, postPaymentThunkCreator,
    postRentExpensesThunkCreator,
    postStaffSalaryThunkCreator,
    delPaymentThunkCreator
} from "../../redux/reducers/financeReducer";
import {Spin} from 'antd';
import Income from "./Income";
import Statistic from "./Statistic";

class Finance extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.getArticlesThunkCreator()
    }

    render() {
        if (this.props.loading) {
            return <div className="example"><Spin/></div>
        } else {
            if (this.props.page === '52') {
                return (

                    <Expenses articles_expenses={this.props.articles_expenses}
                              staff={this.props.staff}
                              salary={this.props.salary}
                              postSalary={this.props.postStaffSalaryThunkCreator}
                              cars={this.props.cars}
                              cars_expenses={this.props.cars_expenses}
                              rent_expenses={this.props.rent_expenses}
                              oper_expenses={this.props.oper_expenses}
                              one_expenses={this.props.one_expenses}
                              postCars={this.props.postCarsExpensesThunkCreator}
                              postRent={this.props.postRentExpensesThunkCreator}
                              postOper={this.props.postOperExpensesThunkCreator}
                              postOne={this.props.postOneExpensesThunkCreator}

                    />
                )
            } else  if (this.props.page === '53') {
                return (

                    <Income     income={this.props.income}
                                one_income={this.props.one_income}
                                objects={this.props.objects}
                                postIncome={this.props.postIncomeThunkCreator}
                                payments={this.props.payments}
                                postPayment={this.props.postPaymentThunkCreator}
                                postOneIncome={this.props.postOneIncomeThunkCreator}
                                delPayment={this.props.delPaymentThunkCreator}

                    />
                )
            } if (this.props.page === '51') {
                return (

                    <Statistic      income={this.props.income}
                                    salary={this.props.salary}
                                    cars_expenses={this.props.cars_expenses}
                                    rent_expenses={this.props.rent_expenses}
                                    oper_expenses={this.props.oper_expenses}
                                    one_expenses={this.props.one_expenses}
                                    one_income={this.props.one_income}


                    />
                )
            } else {
                return (
                    <div></div>
                )
            }
        }


    }
}


const mapStateToProps = (state) => {


    return {
        articles_expenses: state.financeRDCR.articles_expenses,
        staff: state.financeRDCR.staff,
        salary: state.financeRDCR.salary,
        loading: state.financeRDCR.loading,
        cars: state.financeRDCR.cars,
        cars_expenses: state.financeRDCR.cars_expenses,
        rent_expenses: state.financeRDCR.rent_expenses,
        oper_expenses: state.financeRDCR.oper_expenses,
        income: state.financeRDCR.income,
        objects: state.financeRDCR.objects,
        one_expenses: state.financeRDCR.one_expenses,
        one_income: state.financeRDCR.one_income,
        payments: state.financeRDCR.payments,

    }
};


export default connect(mapStateToProps, {
    getArticlesThunkCreator,
    postStaffSalaryThunkCreator,
    postCarsExpensesThunkCreator,
    postRentExpensesThunkCreator,
    postOperExpensesThunkCreator,
    postIncomeThunkCreator,
    postOneExpensesThunkCreator,
    postPaymentThunkCreator,
    postOneIncomeThunkCreator,
    delPaymentThunkCreator
})(Finance);
