import {dataAPI} from "../../API/API";


const GETRULES = 'GET-RULES-RULES';



let initialState = {
    rules: {}
};


const RulesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GETRULES: {
            return {
                ...state,
                rules: action.rules
            }
        }
        default:
            return state;
    }

};

export let GETRULESAC = (rules) => ({type: GETRULES, rules});


export const getRulesThunkCreator = () => async (dispatch) => {
    let response = await dataAPI.getRules();
    if (response) {
        dispatch(GETRULESAC(response.data.rules));
    }
};






export default RulesReducer;




