import React, {useState} from "react";
import {Tabs, Tag} from 'antd';
import {FileSyncOutlined, FileTextOutlined, TeamOutlined, DeleteOutlined} from '@ant-design/icons';
import StaffFinanceExpensive from "./components/staff/StaffFinanceExpensive";
import CarsFinanceExpensive from "./components/cars/CarsFinanceExpensive";
import RentFinanceExpensive from "./components/rent/RentFinanceExpensive";
import OperFinanceExpensive from "./components/oper/OperFinanceExpensive";
import OneFinanceExpensive from "./components/oneExpenses/OneFinanceExpensive";


const {TabPane} = Tabs;
const Expenses = (props) => {

    let [page, setPage] = useState(1)
    let [subPage, setSubPage] = useState(1)

    let expensesType =
        <>
            <TabPane
                tab={<span><FileSyncOutlined/>Периодические расходы</span>}
                key={'1'}
            />
            <TabPane
                tab={<span><FileTextOutlined/>Единоразовые расходы</span>}
                key={'2'}
            />
        </>


    let articles = props.articles_expenses.map(el => {
        return (
            <TabPane
                tab={<span><FileSyncOutlined/>{el.name}</span>}
                key={el.id}
            />
        )

    })

    return (
        <>
            <div className={'store_tabs'}>
                <Tabs onChange={(val) => {
                    setPage(Number(val))
                }} className={'tabs_of_storeTypes'} defaultActiveKey={1}>
                    {expensesType}
                </Tabs>
            </div>


            {Number(page) === 1 && <div className={'store_tabs'}>
                <Tabs onChange={(val) => {
                    setSubPage(Number(val))
                }} className={'tabs_of_storeTypes'} defaultActiveKey={1}>
                    {articles}
                </Tabs>
            </div>}


            {Number(subPage === 2) && Number(page === 1) ?
                <CarsFinanceExpensive postCars={props.postCars} cars={props.cars}
                                      cars_expenses={props.cars_expenses}/> : ''}
            {Number(subPage === 1)  && Number(page === 1) ?
                <StaffFinanceExpensive postSalary={props.postSalary} staff={props.staff} salary={props.salary}/> : ''}
            {Number(subPage === 3)  && Number(page === 1) ?
                <RentFinanceExpensive postRent={props.postRent} rent_expenses={props.rent_expenses}/> : ''}
            {Number(subPage === 4)  && Number(page === 1) ?
                <OperFinanceExpensive postOper={props.postOper} oper_expenses={props.oper_expenses}/> : ''}


            {Number(page === 2)  ?
                <OneFinanceExpensive postOne={props.postOne}
                                     one_expenses={props.one_expenses}
                                     articles_expenses={props.articles_expenses}/> : ''}

        </>

    )
}

export default Expenses