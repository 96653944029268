import AnaliticsMain from "./AnaliticsMain";
import {Component} from "react";
import {connect} from "react-redux";

import React from "react";
import {
    getDataTable_1, getDataTable_3,
    getObjectsThunkCreator,
    getWorksThunkCreator,

} from "../../redux/reducers/analiticsReduser";




class AnaliticsGeneral extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.getObjectsThunkCreator();
        this.props.getWorksThunkCreator();
    }


    render() {
        return (
            <div>
                <AnaliticsMain
                    objects = {this.props.objects}
                    getData_1 = {this.props.getDataTable_1}
                    data = {this.props.data}
                    loading = {this.props.loading}
                    works = {this.props.works}
                    getData_3={this.props.getDataTable_3}
                    finance={this.props.finance}

                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        objects: state.analiticsRDCR.objects,
        data: state.analiticsRDCR.data,
        loading: state.analiticsRDCR.loading,
        works: state.analiticsRDCR.works,
        finance: state.analiticsRDCR.finance
    }
};

export default connect(mapStateToProps, {
    getObjectsThunkCreator,
    getDataTable_1,
    getWorksThunkCreator,
    getDataTable_3
})(AnaliticsGeneral);
