import {Select} from "antd";
import s from "../styles/analitics.module.css";
import React from "react";

const SelectField = (props) => {

    const onChange = (value) => {
        props.onChange(Number(value))
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };


    return(
        <Select
            className={s.selobj}
            showSearch
            placeholder={props.placeholder}
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        >
            {props.options}
        </Select>
    )
}

export default SelectField