import {Alert, Button, Checkbox, Form, Input} from "antd";
import s from "./auth.module.css";
import React from "react";
export const AuthForm = (props) => {
    const onFinish = (values) => {
        props.submit(values);
        //console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        //console.log('Failed:', errorInfo);
    };

    return (
        <Form
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Логин"
                name="username"
                color={'#fff'}
                style={{color: 'white !important'}}
                rules={[
                    {
                        required: true,
                        message: 'Пожалуйста, введите имя пользователя!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Пароль"
                name="password"
                style={{color: 'white !important'}}

                rules={[
                    {
                        required: true,
                        message: 'Пожалуйста, введите пароль!',
                    },
                ]}
            >
                <Input.Password />
            </Form.Item>


            <Button type="primary" htmlType="submit">
                Вход
            </Button>
            {props.message !== '' ? <Alert className={s.alert} message={props.message} type="error" /> : ''}

        </Form>
    );
};