import React, {useState} from "react";
import {Table, Switch, Button, Collapse, Space} from 'antd';
import moment from "moment";
import OneIncomeInfo from "./OneIncomeInfo";
import AddOneIncome from "./AddOneIncome";
import {EuroOutlined, EditOutlined} from '@ant-design/icons';
const {Panel} = Collapse;
const OneIncome = (props) => {

    let [page, setPage] = useState({page: 1, current: {}})

    console.log(props)
    let summRate = 0, summAll = 0;



    const setDate = (unix) => {
        return Number(unix) === 0 ? 'Нет данных' : moment(unix*1000).format('L')
    }
    const defaultData = props.one_income.map(el => {
        summRate += Number(el.cost)
        summAll += Number(el.cost) * (1 + (Number(el.nds) / 100))
        return {
            id: el.id,
            name: el.name,
            type: el.type,
            cost: el.cost,
            nds: el.nds,
            all: el.cost * (1 + (Number(el.nds) / 100)),
            date: setDate(Number(el.date)),
            edit: el,
        }
    })


    const editObject = (el) => {
        console.log(el)
        setPage({page: 3, current: el})
    }

    const columns = [

        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
            /*            render: (rate) => {return (<span>{`${Number(rate).toFixed(2)} €`}</span>)},*/
        },
        {
            title: 'Тип платежа',
            dataIndex: 'type',
            key: 'type',
            render: (type, e) => {
                return Number(e.id !== 0) ? (<span>{Number(type) === 1 ? 'Наличные' : 'Безнал'}</span>) : ''
            },
        },
        {
            title: 'Стоимость',
            dataIndex: 'cost',
            key: 'cost',
            render: (cost) => {
                return (<span>{`${Number(cost).toFixed(2)} €`}</span>)
            },
        },
        {
            title: 'НДС',
            dataIndex: 'nds',
            key: 'nds',
            render: (nds, elem) => {
                return Number(elem.id !== 0) ? <span>{`${nds} %`}</span> : ''
            },
        },
        {
            title: 'Всего с НДС',
            dataIndex: 'all',
            key: 'all',
            render: (all) => {
                return (<span>{`${Number(all).toFixed(2)} €`}</span>)
            },
        },
        {
            title: 'Дата платежа',
            dataIndex: 'date',
            key: 'date',
            /*            render: (rate) => {return (<span>{`${Number(rate).toFixed(2)} €`}</span>)},*/
        },
        {
            title: 'Редактировать',
            dataIndex:
                'edit',
            key:
                'edit',
            align: 'center',
            render:
                (edit) => {
                    return (
                        <a onClick={() => {
                            editObject(edit)
                        }}><EditOutlined/></a>)
                },
        },


    ];


    if (page.page === 1) {
        return (
            <>
                <Collapse defaultActiveKey={['1']}>
                    <Panel header="Общая информация" key="1">
                        <OneIncomeInfo one_income={props.one_income}/>
                    </Panel>
                    <Panel header="Подробная информация" key="2">
                        <Button onClick={() => {
                            setPage({page: 2, current: {}})
                        }} style={{marginRight: '10px', marginLeft: '10px',}} type="primary">
                            Добавить доход
                        </Button>
                        <Table
                            dataSource={defaultData}
                            columns={columns}
                            scroll={{y: 400}}
                            style={{padding: 20}}
                            size={'small'}
                            pagination={false}
                        />
                    </Panel>
                </Collapse>
            </>
        )
    } else {
        return <AddOneIncome close={() => {
            setPage({page: 1, current: {}})
        }} postOneIncome={props.postOneIncome} current={page} page={page.page}/>
    }

}

export default OneIncome