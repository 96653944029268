import React, {useState} from "react";
import s from "../staff/styles/staff.module.css";
import {
    Button,
    ConfigProvider,
    DatePicker,
    Divider,
    Form,
    Input,
    Select,
    Steps,
    Switch,
    Checkbox,
    Row,
    Col
} from "antd";
import locale from "antd/lib/locale/ru_RU";
import moment from "moment";

const AddObject = (props) => {
    let defaultState = {
        name: '',
        long_name: '',
        description: '',
        description_private: '',
        contacts: '',
        contact_name: '',
        contacts_private: '',
        contact_name_private: '',
        locate: '',
        district: '',
        nationality: '',
        avalible_time: '',
        avalible_day: [0, 0, 0, 0, 0, 0, 0],
        type: 1,
        service_int: 0,
        visible: 1,
        access: 1,
        access_cont: '',
        auto_water: 0,
        date_last_service: '',
        comment_last_service: ''

    }

    if (props.editing) {
        defaultState = {
            name: props.current.name,
            long_name: props.current.long_name,
            description: props.current.description,
            description_private: props.current.description_private,
            contacts: props.current.contacts,
            contact_name: props.current.contact_name,
            contacts_private: props.current.contacts_private,
            contact_name_private: props.current.contact_name_private,
            locate: props.current.locate,
            district: props.current.district,
            nationality: props.current.nationality,
            avalible_time: Number(props.current.avalible_time),
            avalible_day: Number(props.current.avalible_day) === 0 ? [1, 1, 1, 1, 1, 1, 1] : JSON.parse("[" + props.current.avalible_day + "]"),
            type: Number(props.current.type),
            service_int: Number(props.current.service_int),
            visible: Number(props.current.visible),
            access: Number(props.current.access),
            access_cont: props.current.access_cont,
            auto_water: Number(props.current.auto_water),
            date_last_service: props.current.date_last_service,
            comment_last_service: props.current.comment_last_service

        }
    }


    let districts = props.districts
    if (districts.indexOf('Другой') === -1){
        districts.push('Другой')
    }


    districts = districts.map(el => {
        return <Select.Option value={el}>{el}</Select.Option>
    })

    let [state, setState] = useState(defaultState)
    let [district, setdistrict] = useState(false)


    const change = (name, value) => {
        let tempstate = {...state}
        tempstate[name] = value
        setState(tempstate)
    }

    function onChangeType(value) {
        let tempstate = {...state}
        tempstate['type'] = Number(value)
        setState(tempstate)
    }

    function onChangeAccess(value) {
        let tempstate = {...state}
        tempstate['access'] = Number(value)
        setState(tempstate)
    }

    function onChangeVisible(checked) {
        let tempstate = {...state}
        tempstate.visible = checked ? 1 : 0
        setState(tempstate)
    }

    function onChangeAutoWater(checked) {
        let tempstate = {...state}
        tempstate.auto_water = checked ? 1 : 0
        setState(tempstate)
    }

    function onChangeAvalible(checkedValues) {

        let i = Number(checkedValues.target.value)
        let tempstate = {...state}
        tempstate.avalible_day[i - 1] = checkedValues.target.checked ? 1 : 0
        setState(tempstate)

    }

    function onChangeDistrict(value) {

        if (value === 'Другой') {
            let tempstate = {...state}
            tempstate['district'] = ''
            setState(tempstate)
            setdistrict(true)
        } else {
            let tempstate = {...state}
            tempstate['district'] = value
            setState(tempstate)
            setdistrict(false)
        }


    }


    const post = () => {
        console.log(state)
       props.post(state, props.current ? props.current.id : 0)
       props.close()
    }


    return (
        <div className={s.addStaffCont}>

            <div className={s.exit}><a onClick={() => {
                props.close()
            }}>Выход</a></div>
            <Form
                labelCol={{
                    span: 4,
                }}
                wrapperCol={{
                    span: 14,
                }}
                layout="horizontal"
                size={'small'}
            >
                <Divider plain>Основные данные</Divider>
                <Form.Item label="Название объекта">
                    <Input value={state.name} onChange={(val) => {
                        change('name', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Рабочее название">
                    <Input value={state.long_name} onChange={(val) => {
                        change('long_name', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Правила посещения">
                    <Input value={state.description} onChange={(val) => {
                        change('description', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Скрытый комментарий">
                    <Input value={state.description_private} onChange={(val) => {
                        change('description_private', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Контактное лицо (видимое)">
                    <Input value={state.contact_name} onChange={(val) => {
                        change('contact_name', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Телефон (видимый)">
                    <Input value={state.contacts} onChange={(val) => {
                        change('contacts', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Контактное лицо (скрытое)">
                    <Input value={state.contact_name_private} onChange={(val) => {
                        change('contact_name_private', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Телефон (скрытый)">
                    <Input value={state.contacts_private} onChange={(val) => {
                        change('contacts_private', val.target.value)
                    }}/>
                </Form.Item>

                <Form.Item label="Локация">
                    <Input value={state.locate} onChange={(val) => {
                        change('locate', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Район">
                    <Select defaultValue={props.districts[0]} onChange={onChangeDistrict}>
                        {districts}
                    </Select>
                </Form.Item>
                {district === true ?
                    <Form.Item label="Название района">
                        <Input value={state.district} placeholder={'Введите название нового района'} defaultValue={''}
                               onChange={(val) => {
                                   change('district', val.target.value)
                               }}/>
                    </Form.Item> : ''}

                <Form.Item label="Национальность собств.">
                    <Input value={state.nationality} placeholder={'Пример: uk или rus/mne '} onChange={(val) => {
                        change('nationality', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item name="checkbox-group" label="Доступность объекта">
                    <div className={s.checkaval}>
                        <Checkbox
                            onChange={(e) => onChangeAvalible(e)}
                            value={1}
                            checked={state.avalible_day[0] === 1}
                            style={{paddingLeft: '8px'}}
                        >
                            Понедельник
                        </Checkbox>
                        <Checkbox
                            onChange={(e) => onChangeAvalible(e)}
                            value={2}
                            defaultChecked={false}
                            checked={state.avalible_day[1] === 1}
                        >
                            Вторник
                        </Checkbox>
                        <Checkbox
                            onChange={(e) => onChangeAvalible(e)}
                            value={3}
                            checked={state.avalible_day[2] === 1}
                        >
                            Среда
                        </Checkbox>
                        <Checkbox
                            onChange={(e) => onChangeAvalible(e)}
                            value={4}
                            checked={state.avalible_day[3] === 1}
                        >
                            Четверг
                        </Checkbox>
                        <Checkbox
                            onChange={(e) => onChangeAvalible(e)}
                            value={5}
                            checked={state.avalible_day[4] === 1}
                        >
                            Пятница
                        </Checkbox>
                        <Checkbox
                            onChange={(e) => onChangeAvalible(e)}
                            value={6}
                            checked={state.avalible_day[5] === 1}
                        >
                            Суббота
                        </Checkbox>
                        <Checkbox
                            onChange={(e) => onChangeAvalible(e)}
                            value={7}
                            checked={state.avalible_day[6] === 1}
                        >
                            Воскресение
                        </Checkbox>
                    </div>

                </Form.Item>
                <Form.Item label="Тип объекта">
                    <Select defaultValue={state.type} onChange={onChangeType}>
                        <Select.Option value={1}>Постоянный</Select.Option>
                        <Select.Option value={2}>Временный</Select.Option>
                        <Select.Option value={3}>Архивный</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Видимый" valuePropName="checked">
                    <Switch checked={state.visible === 1} onChange={onChangeVisible}/>
                </Form.Item>
                <Form.Item label="Доступ на объект">
                    <Select defaultValue={state.access} onChange={onChangeAccess}>
                        <Select.Option value={1}>Открытый</Select.Option>
                        <Select.Option value={2}>Доступ с ключом (ключ у нас)</Select.Option>
                        <Select.Option value={3}>Доступ через контактное лицо</Select.Option>
                        <Select.Option value={4}>Доступ с ключом (ключ на объекте)</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Автополив" valuePropName="checked">
                    <Switch checked={state.auto_water === 1} onChange={onChangeAutoWater}/>
                </Form.Item>


                <Button style={{margin: '0 auto', display: 'block'}} onClick={() => {
                    post()
                }}>{!props.editing ? 'Добавить объект' : 'Обновить данные'}</Button>
            </Form></div>
    )
}

export default AddObject