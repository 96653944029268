import {dataAPI, storeAPI} from "../../API/API";
import {GETSTAFFMAINAC, GETTRANSFERAC} from "./mainReducer";


const GETGENERALSTORE = 'GET-STORE-GENERALSTORE';
const GETGENERALCATEGORY = 'GET-CATEGORY-GENERALSTORE';
const SETLOADGENERALSTORE = 'SET-LOAD-GENERALSTORE';
const SETPAGEGENERALSTORE = 'SET-PAGE-GENERALSTORE';
const GETGENERALSTORES = 'GET-STORES-GENERALSTORE';
const GETTRANSFERGENERALSTORES = 'GET-TRANSFER-GENERALSTORE';
const GETSTAFFGENERALSTORES = 'GET-STAFF-GENERALSTORE';


/*
const SETADDSTORE = 'SET-ADD-STORE';
const SETTABSUBCAT = 'SET-TAB-STORE';
const UPDATEELEMSTORE = 'UPDATE-ELEM-STORE';
const SETMODALSTORE = 'SET-MODAL-STORE';
const SETEMPTY = 'SET-EMPTY-STORE';
const SETCHANGEMODALSTORE = 'SET-CHANGEMODAL-STORE';*/


let initialState = {
    storeData: [{}],
    category: {},
    storesConfig: {
        stores: {},
        types: {}
    },
    loading: true,
    transfer: {},
    staff: {}


/*    currentPage: 1,

    modal: false,

    empty: 0,
    change: false,

    tabSubCat: 1,
    addModal: false,
    updateElem: {
        postId: 0,
        postSubCat: 0,
        postBrand: '',
        postName: '',
        postCost: 0,
        postDescription: '',
        postPackage_name: '',
        postDate_avalible: '',
        postPack: 0

    }*/

};


const GeneralStoreReducer = (state = initialState, action) => {
    switch (action.type) {
        case GETGENERALSTORE: {
            return {...state,storeData: action.store}
        }
        case SETLOADGENERALSTORE: {
            return {...state,loading: action.loading}
        }
        case GETGENERALSTORES: {
            return {...state, storesConfig: {stores: action.stores, types: action.stores_types}
            }
        }
        case GETGENERALCATEGORY: {
            return {...state,category: action.category}
        }
        case GETTRANSFERGENERALSTORES: {
            return {...state,transfer: action.transfer}
        }
        case GETSTAFFGENERALSTORES: {
            return {...state,staff: action.staff}
        }





/*        case UPDATEELEMSTORE: {
            return {
                ...state,
                updateElem: action.updateElem
            }
        }

        case SETADDSTORE: {
            return {
                ...state,
                addModal: action.addModal
            }
        }

        case SETEMPTY: {
            return {
                ...state,
                empty: action.empty
            }
        }

        case SETPAGESTORE: {
            return {
                ...state,
                currentPage: action.currentPage
            }
        }
        case SETTABSUBCAT: {
            return {
                ...state,
                tabSubCat: action.tabSubCat
            }
        }
        case SETMODALSTORE: {
            return {
                ...state,
                modal: !state.modal
            }
        }
        case SETCHANGEMODALSTORE: {
            return {
                ...state,
                change: !state.change
            }
        }*/
        default: return state;
    }

};


function get_cookie ( cookie_name ){
    let results = document.cookie.match ( '(^|;) ?' + cookie_name + '=([^;]*)(;|$)' );
    return results ? unescape ( results[2] )  : null
}

let SETLOADGENERALSTOREAC = (loading) => ({type: SETLOADGENERALSTORE, loading});
export let GETGENERALSTOREAC = (store) => ({type: GETGENERALSTORE, store});
export let GETGENERALSTORESAC = (stores, stores_types) => ({type: GETGENERALSTORES, stores, stores_types});
export let GETGENERALCATEGORYAC = (category) => ({type: GETGENERALCATEGORY, category});
export let GETTRANSFERGENERALSTORESAC = (transfer) => ({type: GETTRANSFERGENERALSTORES, transfer});
export let GETSTAFFGENERALSTORESAC = (staff) => ({type: GETSTAFFGENERALSTORES, staff});


export const getGeneralStoreThunkCreator = () => async (dispatch) => {
    let response = await storeAPI.getStore();
    if (response) {
        dispatch(GETGENERALSTORESAC(response.data.stores, response.data.stores_types));
        dispatch(GETGENERALSTOREAC(response.data.store));
        dispatch(GETGENERALCATEGORYAC(response.data.category));
        response = await storeAPI.getTransfer();
        if (response) {
            dispatch(GETTRANSFERGENERALSTORESAC(response.data.transfer));
            response = await dataAPI.getStaff();
            if (response) {
                dispatch(GETSTAFFGENERALSTORESAC(response.data.staff));
                dispatch(SETLOADGENERALSTOREAC(false));
            }
        }
    }
};
export const changeStoreThunkCreator = (id, from, fromCount, to, toCount,comment) => async (dispatch) => {

    let response = await storeAPI.updateStore(id, from, fromCount, to, toCount);
    if (response) {
        let response = await storeAPI.transfer(id, + new Date(), from, to, get_cookie ( "login" ), comment);
        if (response) {
            let response = await storeAPI.getStore();
            if (response) {
                dispatch(GETGENERALSTOREAC(response.data.store));
            }
        }
    }
}

export const changeStoreElemThunkCreator = (id, sub_category, brand, name, cost, description, package_name, date_avalible, pack, count) => async (dispatch) => {

    let response = await storeAPI.updateElem(id, sub_category, brand, name, cost, description, package_name, date_avalible, pack, count);
    if (response) {
        let response = await storeAPI.getStore();
        if (response) {
            dispatch(GETGENERALSTOREAC(response.data.store));

        }
    }
}
export const newStoreElemThunkCreator = (postName,postBrand,postDescription,postPackage_name,postPack,postCount,postCost,postDate_avalible,postSubCat) => async (dispatch) => {

    let response = await storeAPI.newElem(postName,postBrand,postDescription,postPackage_name,postPack,postCount,postCost,postDate_avalible,postSubCat);
    if (response) {
        let response = await storeAPI.getStore();
        if (response) {
            dispatch(GETGENERALSTOREAC(response.data.store));
        }
    }
}


/*

export let SETTABSUBCATAC = (tabSubCat) => ({type: SETTABSUBCAT, tabSubCat});




export let SETADDSTOREAC = (addModal) => ({type: SETADDSTORE, addModal});

export let UPDATEELEMSTOREAC = (updateElem) => ({type: UPDATEELEMSTORE, updateElem});



export let SETEMPTYAC = (empty) => ({type: SETEMPTY, empty});


export let SETPAGESTOREAC = (currentPage) => ({type: SETPAGESTORE, currentPage});
export let SETMODALSTOREAC = () => ({type: SETMODALSTORE});
export let SETCHANGEMODALSTOREAC = () => ({type: SETCHANGEMODALSTORE});*/





/*



export const changeStoreElemThunkCreator = (id, sub_category, brand, name, cost, description, package_name, date_avalible, pack, count) => async (dispatch) => {

    let response = await storeAPI.updateElem(id, sub_category, brand, name, cost, description, package_name, date_avalible, pack, count);
    if (response) {
        let response = await storeAPI.getStore();
        if (response) {
            dispatch(GETSTOREAC(response.data.store));
            dispatch(GETCATEGORYCAC(response.data.category));
        }
    }
}

export const newStoreElemThunkCreator = (postName,postBrand,postDescription,postPackage_name,postPack,postCount,postCost,postDate_avalible,postSubCat) => async (dispatch) => {

    let response = await storeAPI.newElem(postName,postBrand,postDescription,postPackage_name,postPack,postCount,postCost,postDate_avalible,postSubCat);
    if (response) {
        let response = await storeAPI.getStore();
        if (response) {
            dispatch(GETSTOREAC(response.data.store));
            dispatch(GETCATEGORYCAC(response.data.category));
        }
    }
}*/
export default GeneralStoreReducer;




