import React, {useState} from "react";
import {Button, Collapse, Radio} from "antd";
import s from "./mobile.module.css";
import moment from "moment";

const {Panel} = Collapse;
const OldReports =  (props) =>  {
    let [days, setDays] = useState(7)

    const getWorkNameById = (id) => {

        for (let i = 0, len = props.reportsTemp.length; i < len; i++) {
            if (Number(id) === Number(props.reportsTemp[i].id)){
                return props.reportsTemp[i].name
            }
        }
    }

    let startTime = moment().subtract(days, 'days').format('X');
    let endTime = moment().format('X');
    let filteredOldReports = props.oldreports.map(el => {
        return Number(startTime) < Number(el.time_download) && Number(el.time_download) <  Number(endTime) ? el : ''
    }).filter(el => el !== '')

    console.log(filteredOldReports)

    let render = filteredOldReports.length > 0 ? filteredOldReports.map((el,index) => {

        let works = el.works_id.split(',')
        let comments = el.comments_id.split('`')
        let times = moment(+el.time_start*1000).format('HH:mm') + ' - ' + moment(+el.time_end*1000).format('HH:mm')
        let reports = works.map((el1, index1) => {
            return (
                <div className={s.work__cont}>
                    <div className={s.oldreport_workname}>{getWorkNameById(el1)}</div>
                    {comments[index1] !== '' ? <div className={s.oldreport_comment}>{comments[index1]}</div> : ''}
                </div>
            )
        })

        return (
            <Panel header={moment(Number(el.time_download)*1000).format('LL')} key={index}>
                <div style={{textAlign: 'center', padding: '10px'}}>Время работ:  {times} </div>
                                {reports}
            </Panel>
        )
    }) : []


    const onChange = (e)  =>{
        setDays(Number(e.target.value))
    }

    return (<div className={s.oldreport_cont}>
            <div className={s.radio__cont}>
                <Radio.Group onChange={onChange} defaultValue="7" size={'small'}>
                    <Radio.Button value="7">Неделя</Radio.Button>
                    <Radio.Button value="30">Месяц</Radio.Button>
                    <Radio.Button value="180">Полгода</Radio.Button>
                </Radio.Group>
            </div>

            <Collapse>
                {render}
            </Collapse>


            <Button onClick={() => {
                props.close()
            }} className={s.mobile_btn}>Назад</Button>
        </div>

    )
}

export default OldReports;