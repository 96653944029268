import s from './header.module.css';
import React from "react";

const Header = (props) => {
    return (
        <>
            <div className={`${s.header} ${s.noselect} ${s.texthover}`}>
                Garden Design APP
            </div>
            <div className={s.status}>
                <div onClick={()=>{props.exit()}} className={`${s.status__exit} ${s.noselect} ${s.pointer}`}> Exit</div>
                <div className={s.status__loginname}> {props.username}</div>
                <div className={`${s.status__label} ${s.noselect}`}> Login as:</div>
            </div>
        </>
    )
};

export default Header;