import {applyMiddleware, combineReducers, compose, createStore} from "redux";


import thunkmiddleware from "redux-thunk";
import AuthReducer from "./reducers/authReducer";
import MainReducer from "./reducers/mainReducer";
import StaffReducer from "./reducers/staffReducer";
import RulesReducer from "./reducers/rulesReducer";
import FinanceReducer from "./reducers/financeReducer";
import ObjectsReducer from "./reducers/objectsReducer";
import PlanningReducer from "./reducers/planningReducer";
import GeneralStoreReducer from "./reducers/generalStoreReducer";
import MobileReducer from "./reducers/mobileReducer";
import AnaliticsReducer from "./reducers/analiticsReduser";


let reducers = combineReducers({
    authRDCR: AuthReducer,
    mainRDCR: MainReducer,
    staffRDCR: StaffReducer,
    rulesRDCR: RulesReducer,
    financeRDCR: FinanceReducer,
    objectsRDCR: ObjectsReducer,
    planningRDCR: PlanningReducer,
    generalStoreRDCR: GeneralStoreReducer,
    mobileRDCR: MobileReducer,
    analiticsRDCR: AnaliticsReducer

});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers,  composeEnhancers(applyMiddleware(thunkmiddleware)));
window.store = store;


export default store;
