import {Tabs, Tag} from 'antd';
import React, {useState} from 'react';
import './styles/store.css'
import {BarcodeOutlined, CarOutlined, TeamOutlined, DeleteOutlined} from '@ant-design/icons';
import Store from "./Store";
import s from "./styles/store.css";


const {TabPane} = Tabs;
const StoreTypeTab = (props) => {


    let [storeType, setStoreType] = useState(1)

    let storesTypesArr = props.storesConfig.types.map((el) => {
        // Формирование вкладок с типами складов
        return (
            <TabPane
                tab={
                    <span >
                    {Number(el.id) === 1 ? <BarcodeOutlined/> : ''}
                        {Number(el.id) === 2 ? <CarOutlined/> : ''}
                        {Number(el.id) === 3 ? <TeamOutlined/> : ''}
                        {Number(el.id) === 4 ? <DeleteOutlined/> : ''}
                        {el.app_name}
                </span>
                }
                key={`${Number(el.id)}`}
            > </TabPane>
        )
    });


    // Возвращаем вкладки по типам складов с отрисовкой внутренних компонентов
    return (
        <>
            <div className={'store_tabs'}>
            <Tabs onChange={(val)=>{setStoreType(Number(val))}} className={'tabs_of_storeTypes'} defaultActiveKey={1}>
                {storesTypesArr}
            </Tabs>
            </div>
            <Store
                storesTypeId={storeType}
                stores={props.storesConfig.stores}
                storeData={props.data}
                category={props.category}
                transfer={props.transfer}
                changeElem={props.changeElem}
                post={props.post}

            />

        </>
    )

}

export default StoreTypeTab;


