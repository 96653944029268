import React from "react";
import s from './home.module.css'

const Home = () => {
return(

<div></div>


)
};

export default Home;