import React, {useState} from "react";
import {Button, Collapse, Space, Table, Tag} from "antd";
import s from "../staff/styles/staff.module.css";
import AddWork from "./AddWork";
import { FileDoneOutlined, EditOutlined, StarTwoTone } from '@ant-design/icons';

const WorksList = (props) => {
    let [page, setPage] = useState({page: 0, ce: {}})


    const setTypeNameById = (id) => {
        switch (Number(id)) {
            case 1: return "Основные работы"
            case 2: return "Химия и удобрения"
            case 3: return "Полив"
            case 4: return "Автополив"
            case 5: return "Остальное"
            case 6: return "Финансы"

        }



        }

    let render = props.works ? props.works.sort((prev, next) => Number(prev.type) - Number(next.type)).map((el, index) => {
        return {index: index+1, name: el.name, type: setTypeNameById(el.type), id: el.id, input: el.input, input_name: el.input_name}
    }) : []

    const edit = (id) => {

        let temp = props.works.filter(el => Number(el.id) === Number(id.id))
        setPage({page: 2, ce: temp[0]})
    }
    const add = (id) => {
        setPage({page: 1, ce: {}})
    }
    const close = (id) => {
        setPage({page: 0, ce: {}})
    }
    const delWork = (id) => {
        props.post({id: id.id}, 2)
        setPage({page: 0, ce: {}})
    }


    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
        },
        {
            title: 'Наименование работы',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Тип',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Поле для комментария',
            dataIndex: 'input',
            key: 'input',
            render: input => (
                <>
                    {Number(input) === 0 ? <Tag color={'red'}>Нет</Tag> : <Tag color={'green'}>Есть</Tag>}
                </>
            ),
        },
        {
            title: 'Название поля',
            dataIndex: 'input_name',
            key: 'input_name',
        },
        {
            title: 'Редактировать',
            key: 'id',
            align: 'center',
            render: id => (
                <Space size="middle">
                    <a onClick={() => {
                        edit(id)
                    }}><EditOutlined /></a>
                    {/* <a>Удалить</a>*/}
                </Space>
            ),
        },
/*        {
            title: 'Удалить',
            key: 'id',
            align: 'center',
            render: id => (
                <Space size="middle">
                    <a onClick={() => {
                        delWork(id)
                    }}><EditOutlined /></a>
                    {/!* <a>Удалить</a>*!/}
                </Space>
            ),
        },*/
    ];

    if (page.page === 0){
        return (
            <>
                <div className={s.addStaffBtn}><Button type="primary" onClick={()=>{add()}}>Добавить работу</Button></div>
                <Table
                    columns={columns}
                    pagination={{position: ['none', 'bottomLeft']}}
                    dataSource={render}
                    style={{ padding: 20 }}
                />
            </>
        )
    } else {
        return (
            <AddWork page={page.page} id={page.ce.id} post={props.post} state={page.ce} close={()=>{close()}}/>
        )
    }

}

export default WorksList