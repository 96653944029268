import * as axios from "axios";
import moment from 'moment'
import * as md5 from "md5";

export let production = true;


//let url_1 = production ? 'http://89.108.77.132/API/2.0/' : 'http://89.108.77.132/API/2.0_test/';

export let url_1 = production ? 'https://gdapp.org/API/2.0/' : 'https://gdapp.org/API/2.0_test/';


const instance = axios.create({
    baseURL: url_1,
    timeout: 0,
    headers: {}
});


const get_cookie = (cookie_name) => {
    let results = document.cookie.match('(^|;) ?' + cookie_name + '=([^;]*)(;|$)');
    return results ? unescape(results[2]) : null
}
export const authAPI = {
    auth(login, pass) {
        return instance.get(`main/auth.php?login=${login}&pass=${pass}`)
            .then(response => {
                return response.data['data'] ? response.data['data'] : false
            })
    },
    check(login, hash) {
        return instance.get(`main/check.php?login=${login}&hash=${hash}`)
            .then(response => {
                return response.data
            })
    },


}
export const dataAPI = {
    getPersonalData(id) {
        return instance.get('main/getPersonalData.php?api-key=' + get_cookie("hash") + '&personal_id=' + id)
            .then(response => {
                return response ? response : false
            })
    },
    getStaff() {
        return instance.get('main/getStaff.php?api-key=' + get_cookie("hash") + '&name=staff')
            .then(response => {
                return response ? response : false
            })
    },
    postStaff(staff, id) {
        var formdata = new FormData();
        formdata.append("firstname", staff.name || '')
        formdata.append("lastname", staff.secondName || '')
        formdata.append("patronymic", staff.patronymic || '')
        formdata.append("wkoeff", 100)
        formdata.append("role", staff.roles.join() || '')
        formdata.append("login", staff.login || '')
        formdata.append("pass", md5(md5(staff.pass)) || '')
        formdata.append("hash", '')
        formdata.append("phone", staff.phone || '')
        formdata.append("email", staff.eMail || '')
        formdata.append("adress", staff.adress || '')
        formdata.append("nationality", staff.nationality || '')
        formdata.append("contract", staff.workContract || 0)
        formdata.append("contract_fdate", staff.workContractEnd || '')
        formdata.append("first_parrent_name", staff.contactFaceOne || '')
        formdata.append("second_parrent_name", staff.contactFaceTwo || '')
        formdata.append("first_parrent_phone", staff.contactFacePhoneOne || '')
        formdata.append("second_parrent_phone", staff.contactFacePhoneTwo || '')
        formdata.append("birthday_date", staff.birthdayDate || '')
        formdata.append("work_start_date", staff.dateStartWork || '')
        formdata.append("passport", staff.passport || '')
        formdata.append("passport_fdate", staff.passportDate || '')
        formdata.append("boravok", staff.boravok || '')
        formdata.append("boravok_fdate", staff.boravokDate || '')
        formdata.append("driver_license", staff.driverLicense || '')
        formdata.append("driver_license_fdate", staff.driverLicenseDate || '')
        formdata.append("kpi", 100)
        formdata.append("language", staff.language || '')
        formdata.append("exp", staff.exp || '')
        formdata.append("personal_car", staff.personalCar || 0)
        formdata.append("steps", staff.steps || 1)
        formdata.append("store", staff.store || 0)
        formdata.append("id", id)
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        return fetch(`${url_1}staff/postStaff.php`, requestOptions)


    },

    getRules() {
        return instance.get('main/getRules.php?api-key=' + get_cookie("hash"))
            .then(response => {
                return response ? response : false
            })
    },
    getObjects() {
        return instance.get('objects/getObjects.php?api-key=' + get_cookie("hash"))
            .then(response => {
                return response ? response : false
            })
    },
    getPlans() {
        return instance.get('plans/getPlans.php?api-key=' + get_cookie("hash") + '&type=all')
            .then(response => {
                return response ? response : false
            })
    },
    getCars() {
        return instance.get('main/getCars.php?api-key=' + get_cookie("hash"))
            .then(response => {
                return response ? response : false
            })
    }

};
export const storeAPI = {
    getStore() {
        return instance.get('store/getStoreTest.php?api-key=' + get_cookie("hash"))
            .then(response => {
                return response ? response : false
            })
    },
    getTransfer() {
        return instance.get('store/getTransferStore.php?api-key=' + get_cookie("hash"))
            .then(response => {
                return response ? response : false
            })
    },
    updateStore(id, from, fromCount, to, toCount) {
        var formdata = new FormData();
        formdata.append("id", id);
        formdata.append("from", from);
        formdata.append("fromCount", fromCount);
        formdata.append("to", to);
        formdata.append("toCount", toCount);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        return fetch(`${url_1}store/postStore.php`, requestOptions)


    },
    updateElem(id, sub_category, brand, name, cost, description, package_name, date_avalible, pack, count) {
        var formdata = new FormData();
        formdata.append("id", id);
        formdata.append("API-KEY", get_cookie("hash"));
        formdata.append("sub_category", sub_category);
        formdata.append("brand", brand);
        formdata.append("name", name);
        formdata.append("cost", cost);
        formdata.append("description", description);
        formdata.append("package_name", package_name);
        formdata.append("package", pack);
        formdata.append("date_avalible", date_avalible);
        formdata.append("count", count);
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        return fetch(`${url_1}store/postUpdateElement.php`, requestOptions)


    },
    newElem(postName, postBrand, postDescription, postPackage_name, postPack, postCount, postCost, postDate_avalible, postSubCat) {
        var formdata = new FormData();
        formdata.append("postName", postName);
        formdata.append("API-KEY", get_cookie("hash"));
        formdata.append("postBrand", postBrand);
        formdata.append("postDescription", postDescription);
        formdata.append("postPackage_name", postPackage_name);
        formdata.append("postPack", postPack);
        formdata.append("postCount", postCount);
        formdata.append("postCost", postCost);
        formdata.append("postDate_avalible", postDate_avalible);
        formdata.append("postSubCat", postSubCat);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        return fetch(`${url_1}store/postNewElement.php`, requestOptions)


    },


    transfer(item_id, date, from_store, to_store, owner, description) {
        var formdata = new FormData();
        formdata.append("API-KEY", get_cookie("hash"));
        formdata.append("item_id", item_id);
        formdata.append("date", date);
        formdata.append("from_store", from_store);
        formdata.append("to_store", to_store);
        formdata.append("owner", owner);
        formdata.append("description", description);


        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        return fetch(`${url_1}store/postTransferAction.php`, requestOptions)


    }
};
export const objectAPI = {
    getObjects(avalible = 0) {
        return instance.get(`objects/getObjects.php?avalible=${avalible}&api-key=${get_cookie("hash")}`)
            .then(response => {
                return response ? response : false
            })
    },
    postObject(state, id) {
        var formdata = new FormData();
        formdata.append("name", state.name || '')
        formdata.append("long_name", state.long_name || '')
        formdata.append("description", state.description || '')
        formdata.append("description_private", state.description_private || '')
        formdata.append("contacts", state.contacts || '')
        formdata.append("contact_name", state.contact_name || '')
        formdata.append("contacts_private", state.contacts_private || '')
        formdata.append("contact_name_private", state.contact_name_private || '')
        formdata.append("locate", state.locate || '')
        formdata.append("district", state.district || 'no data')
        formdata.append("nationality", state.nationality || '')
        formdata.append("avalible_time", 0)
        const sum = state.avalible_day.reduce((partialSum, a) => partialSum + a, 0);
        const ad = sum === 7 ? 0 : state.avalible_day.join()
        formdata.append("avalible_day", ad)
        formdata.append("type", state.type || 0)
        formdata.append("service_int", 0)
        formdata.append("visible", state.visible || 0)
        formdata.append("access", state.access || 0)
        formdata.append("access_cont", state.access_cont || 0)
        formdata.append("auto_water", state.auto_water || 0)
        formdata.append("date_last_service", state.date_last_service || '')
        formdata.append("comment_last_service", state.comment_last_service || '')
        formdata.append("id", id || 0)
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        return fetch(`${url_1}objects/postObject.php`, requestOptions)


    },
    /*getPlans() {
        return instance.get('plans/getPlans.php?api-key=' + get_cookie ( "hash" ))
            .then(response => {
                return response ? response : false
            })
    },*/
};

export const analiticsAPI = {
    getDataTable_1(start, stop, id) {
        return instance.get(`analitics/getObjectsTable_1.php?start=${start}&stop=${stop}&id=${id}&api-key=${get_cookie("hash")}`)
            .then(response => {
                return response ? response : false
            })
    },
    getDataTable_3(start, stop) {
        return instance.get(`analitics/getObjectsTable_3.php?start=${start}&stop=${stop}&api-key=${get_cookie("hash")}`)
            .then(response => {
                return response ? response : false
            })
    }
};


export const financeAPI = {
    getArticles() {
        return instance.get('finance/getArticles.php?api-key=' + get_cookie("hash"))
            .then(response => {
                return response ? response : false
            })
    },
    getSalary() {
        return instance.get('finance/getSalary.php?api-key=' + get_cookie("hash"))
            .then(response => {
                return response ? response : false
            })
    },
    getCarsExpenses() {
        return instance.get('finance/getCarsExpenses.php?api-key=' + get_cookie("hash"))
            .then(response => {
                return response ? response : false
            })
    },
    getRentExpenses() {
        return instance.get('finance/getRentExpenses.php?api-key=' + get_cookie("hash"))
            .then(response => {
                return response ? response : false
            })
    },
    getOperExpenses() {
        return instance.get('finance/getOperExpenses.php?api-key=' + get_cookie("hash"))
            .then(response => {
                return response ? response : false
            })
    },
    getOneExpenses() {
        return instance.get('finance/getOneExpenses.php?api-key=' + get_cookie("hash"))
            .then(response => {
                return response ? response : false
            })
    },
    getIncome() {
        return instance.get('finance/getIncome.php?api-key=' + get_cookie("hash"))
            .then(response => {
                return response ? response : false
            })
    },
    getOneIncome() {
        return instance.get('finance/getOneIncome.php?api-key=' + get_cookie("hash"))
            .then(response => {
                return response ? response : false
            })
    },
    getPayments() {
        return instance.get('finance/getPayment.php?api-key=' + get_cookie("hash"))
            .then(response => {
                return response ? response : false
            })
    },
    delPayments(id) {
        return instance.get('finance/delPayment.php?api-key=' + get_cookie("hash") + '&id=' + id)
            .then(response => {
                return response ? response : false
            })
    },
    postSalary(state, update) {

        var formdata = new FormData();
        formdata.append("id", state.id || 0)
        formdata.append("name", state.name || '')
        formdata.append("post", state.post || '')
        formdata.append("enable", state.enable || 0)
        formdata.append("rate", state.rate || 0)
        formdata.append("cash", state.cash || 0)
        formdata.append("cashless", state.cashless || 0)
        formdata.append("update", update || 0)

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        return fetch(`${url_1}finance/postSalary.php`, requestOptions)


    },
    postIncome(state, update) {


        var formdata = new FormData();
        formdata.append("obj_id", state.id || 0)
        formdata.append("obj_name", state.name || '')
        formdata.append("enable", state.enable || 0)
        formdata.append("cost", state.cost.join('`') || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].join('`'))
        formdata.append("type", state.type || 0)
        formdata.append("period", state.period || 0)
        formdata.append("nds", state.nds || 0)
        formdata.append("update", update || 0)
        formdata.append("balance", state.balance || 0)

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        return fetch(`${url_1}finance/postIncome.php`, requestOptions)


    },
    postPayment(state, update) {


        var formdata = new FormData();
        formdata.append("obj_id", state.obj_id || 0)
        formdata.append("cost", state.cost || 0)
        formdata.append("type", state.type || 0)
        formdata.append("nds", state.nds || 0)
        formdata.append("data", state.data || 0)

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        return fetch(`${url_1}finance/postPayment.php`, requestOptions)


    },
    postOneIncome(state, update) {


        var formdata = new FormData();
        formdata.append("name", state.name || '')
        formdata.append("cost", state.cost || 0)
        formdata.append("type", state.type || 0)
        formdata.append("nds", state.nds || 0)
        formdata.append("date", state.date || 0)
        formdata.append("id", state.id || 0)
        formdata.append("update", update || 0)

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        return fetch(`${url_1}finance/postOneIncome.php`, requestOptions)


    },
    postCarsExpenses(state, update) {


        var formdata = new FormData();
        formdata.append("car_id", state.car_id || 0)
        formdata.append("car_name", state.car_name || '')
        formdata.append("name", state.name || '')
        formdata.append("enable", state.enable || 0)
        formdata.append("type", state.type || 1)
        formdata.append("cost", state.cost || 0)
        formdata.append("nds", state.nds || 0)
        formdata.append("period", state.period || 0)
        formdata.append("update", update || 0)

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        return fetch(`${url_1}finance/postCarsExpenses.php`, requestOptions)


    },
    postRentExpenses(state, update) {


        var formdata = new FormData();
        formdata.append("name", state.name || '')
        formdata.append("enable", state.enable || 0)
        formdata.append("type", state.type || 1)
        formdata.append("cost", state.cost || 0)
        formdata.append("nds", state.nds || 0)
        formdata.append("period", state.period || 0)
        formdata.append("update", update || 0)

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        return fetch(`${url_1}finance/postRentExpenses.php`, requestOptions)


    },
    postOperExpenses(state, update) {


        var formdata = new FormData();
        formdata.append("name", state.name || '')
        formdata.append("enable", state.enable || 0)
        formdata.append("type", state.type || 1)
        formdata.append("cost", state.cost || 0)
        formdata.append("nds", state.nds || 0)
        formdata.append("period", state.period || 0)
        formdata.append("update", update || 0)

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        return fetch(`${url_1}finance/postOperExpenses.php`, requestOptions)


    },
    postOneExpenses(state, update) {


        var formdata = new FormData();
        formdata.append("name", state.name || '')
        formdata.append("type", state.type || 1)
        formdata.append("cost", state.cost || 0)
        formdata.append("nds", state.nds || 0)
        formdata.append("article", state.article || 0)
        formdata.append("update", update || 0)
        formdata.append("date", state.date || 0)
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        return fetch(`${url_1}finance/postOneExpenses.php`, requestOptions)


    },
    /*getPlans() {
        return instance.get('plans/getPlans.php?api-key=' + get_cookie ( "hash" ))
            .then(response => {
                return response ? response : false
            })
    },*/
};
export const reportsAPI = {
    getReportsByObjectId(id, start, end) {
        return instance.get(`reports/getReports.php?object_id=${id}&start=${start}&end=${end}`)
            .then(response => {
                return response ? response : false
            })
    },
    getReportsAll() {
        return instance.get('reports/getReports.php?all=true')
            .then(response => {
                return response ? response : false
            })
    },
    getReportByTime(start, end) {
        return instance.get(`reports/getReports.php?start=${start}&end=${end}`)
            .then(response => {
                return response ? response : false
            })
    },
    getReportsTemp() {
        return instance.get('reports/getWorks.php')
            .then(response => {
                return response ? response : false
            })
    },
    getLinks(object_id, start, end) {
        return instance.get(`reports/getPhoto.php?id=${object_id}&start=${start}&end=${end}`)
            .then(response => {
                return response ? response : false
            })
    },
    getLinksById(object_id) {
        return instance.get(`reports/getPhoto.php?id=${object_id}`)
            .then(response => {
                return response ? response : false
            })
    },
    getLinksByTime(start, end) {
        return instance.get(`reports/getPhotoByTime.php?start=${start}&end=${end}`)
            .then(response => {
                return response ? response : false
            })
    },
};
export const plansAPI = {
    checkChanges(oldProps, newProps) {
        let changes_0 = []
        changes_0.push(`Количество команд ${oldProps.length === newProps.length ? 'без изменений' : 'изменено'}*`)
        if (oldProps.length > newProps.length) {
            changes_0.push('Команд удалено: ' + (oldProps.length - newProps.length) + '*')
        } else if (oldProps.length < newProps.length) {
            changes_0.push('Команд добавлено: ' + (newProps.length - oldProps.length) + '*')
        }
        let commands = (oldProps.length <= newProps.length) ? oldProps.length : oldProps.length - (oldProps.length - newProps.length)

        for (let i = 0, len = commands; i < len; i++) {
            let flag = 0;
            let changes = []

            flag += Number(newProps[i][1]) === Number(oldProps[i].car_id) ? 0 : 1
            if (Number(newProps[i][1]) !== Number(oldProps[i].car_id)) {
                changes.push('Изменен автомобиль*')
            }
            flag += Number(newProps[i][2]) === Number(oldProps[i].driver) ? 0 : 1
            if (Number(newProps[i][2]) !== Number(oldProps[i].driver)) {
                changes.push('Изменен водитель*')
            }
            flag += Number(newProps[i][3]) === Number(oldProps[i].lead) ? 0 : 1
            if (Number(newProps[i][3]) !== Number(oldProps[i].lead)) {
                changes.push('Изменен старший*')
            }
            flag += newProps[i][4] === oldProps[i].staff_id ? 0 : 1
            if (newProps[i][4] !== oldProps[i].staff_id) {
                changes.push('Изменены сотрудники*')
            }
            flag += newProps[i][7] === oldProps[i].teamcomment ? 0 : 1
            if (newProps[i][7] !== oldProps[i].teamcomment) {
                changes.push('Изменен комментарий для команды*')
            }
            flag += newProps[i][6] === oldProps[i].comment ? 0 : 1
            if (newProps[i][6] !== oldProps[i].comment) {
                changes.push('Изменены комментарии для объектов*')
            }
            flag += newProps[i][5] === oldProps[i].objects_id ? 0 : 1
            console.log(`objects: ${newProps[i][5]} / ${oldProps[i].objects_id}`)
            if (newProps[i][5] !== oldProps[i].objects_id) {
                changes.push('Изменены объекты для обслуживания*')
            }
            changes_0.push(`Команда ${i + 1} ${flag === 0 ? 'без изменений*' : 'изменена*'}`)
            if (flag !== 0) {
                for (let s = 0, len = changes.length; s < len; s++) {
                    changes_0.push(`${s + 1}) ${changes[s]}`)
                }
            }

        }

        return changes_0

    },
    async newElem(postArr, dates, id, maincomment) {
        let create_date = dates[0]
        let used_date = dates[1]
        let formdata = new FormData();
        if (Number(id) === 0) {

            formdata.append("create_date", create_date);
            formdata.append("API-KEY", get_cookie("hash"));
            formdata.append("used_date", used_date);
            formdata.append("maincomment", maincomment);
            let requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };
            let response = await fetch(`${url_1}plans/postPlans.php`, requestOptions)
            if (response.ok) {
                let json = await response.json();
                return this.newElem2(json, postArr).then(response => {
                    return {status: response, id: json}
                })
            } else {
                alert("Ошибка HTTP: " + response.status);
            }

        } else {
            return instance.get(`plans/deletePlanProps.php?parent_id=${id}`)
                .then(response => {
                    if (response) {
                        let formdata = new FormData();
                        formdata.append("changes", this.checkChanges(response.data, postArr));
                        formdata.append("date", moment().format('X'));
                        formdata.append("id", id);
                        var requestOptions = {
                            method: 'POST',
                            body: formdata,
                            redirect: 'follow'
                        };

                        return fetch(`${url_1}plans/postPlansChanges.php`, requestOptions)
                            .then(response => {
                                if (response) {
                                    return this.newElem2(id, postArr).then(response => {
                                        return {status: response, id: id}
                                    })
                                }
                            })


                    }
                })
        }


    },
    async newElem2(planId, data) {

        let s = 0;
        for (let count = 0, len = data.length; count < len; count++) {
            let formdata = new FormData();
            formdata.append("parent_id", planId);
            formdata.append("carId", data[count][1]);
            formdata.append("driver", data[count][2]);
            formdata.append("lead", data[count][3]);
            formdata.append("staff_id", data[count][4]);
            formdata.append("objects_id", data[count][5]);
            formdata.append("comment", data[count][6]);
            formdata.append("teamcomment", data[count][7]);
            let requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            let response = await fetch(`${url_1}plans/postPlansProps.php`, requestOptions).then(response => {
                if (response) {
                    s++
                }
            })

        }
        if (s === data.length) {
            return true
        }


    },
    postReport(plan_id, object_id, time_start, time_end, time_download, time_plan, works_id, comments_id, updateFlag, report_id = 0) {
        var formdata = new FormData();
        formdata.append("plan_id", plan_id);
        formdata.append("object_id", object_id);
        formdata.append("time_start", time_start);
        formdata.append("time_end", time_end);
        formdata.append("time_download", time_download);
        formdata.append("time_plan", time_plan);
        formdata.append("works_id", works_id);
        formdata.append("comments_id", comments_id);
        formdata.append("updateFlag", updateFlag);
        formdata.append("report_id", report_id);
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        return fetch(`${url_1}plans/postReports.php`, requestOptions)
    },
    getPlans() {
        return instance.get(`plans/getPlans.php?api-key=${get_cookie("hash")}&type=all`)
            .then(response => {
                return response ? response : false
            })
    },
    getCurrentPlans() {
        return instance.get(`plans/getPlans.php?api-key=${get_cookie("hash")}&type=current`)
            .then(response => {
                return response ? response : false
            })
    },
    getPlansByTime(start, end) {
        return instance.get(`plans/getPlans.php?api-key=${get_cookie("hash")}&start=${start}&end=${end}`)
            .then(response => {
                return response ? response : false
            })
    },
    getObjects() {
        return instance.get('objects/getObjectsForReport.php?api-key=' + get_cookie("hash"))
            .then(response => {
                return response ? response : false
            })
    },
    getReportsTemp() {
        return instance.get('reports/getWorks.php?api-key=' + get_cookie("hash"))
            .then(response => {
                return response ? response : false
            })
    },
    postNewWork(state, update) {


        var formdata = new FormData();
        formdata.append("id", state.id);
        formdata.append("name", state.name);
        formdata.append("type", state.type);
        formdata.append("input", state.input);
        formdata.append("input_name", state.input_name);
        formdata.append("cat", state.cat);
        formdata.append("count", state.count);
        formdata.append("count_name", state.count_name);
        formdata.append("update", update);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        return fetch(`${url_1}plans/postWork.php`, requestOptions)
    }

}
export const photoAPI = {
    getLinks(object_id, start, end) {
        return instance.get(`downloads/getPhotoZip.php?id=${object_id}&start=${start}&end=${end}`)
            .then(response => {
                return response ? response : false
            })
    },


}
export const whatsappApi = {
   async sendAction(body) {
        let token = '5340989881:AAHtsO5AW36-N4Ue0tb42hgkeplGTcOusXI';
        let instanceIdGalina = '5224888648';
        let instanceIdGernam = '75092261';
        let instanceIdAlan = '428759533';

       let actionToAlan = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${instanceIdAlan}&parse_mode=html&text=Попытка отправить отчет...`;
       let result = await instance.get(actionToAlan)
       actionToAlan = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${instanceIdAlan}&parse_mode=html&text=Тело отчета ${body}`;
       result = await instance.get(actionToAlan)
       actionToAlan = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${instanceIdAlan}&parse_mode=html&text=Тело отчета было отправлено...`;
       result = await instance.get(actionToAlan)

        let actionUrltoGalina = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${instanceIdGalina}&parse_mode=html&text=${body}`;
        let actionUrltoGerman = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${instanceIdGernam}&parse_mode=html&text=${body}`;
        let sendActionToGalina = await instance.get(actionUrltoGalina)
        if (sendActionToGalina) {
            let actionToAlan = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${instanceIdAlan}&parse_mode=html&text=${sendActionToGalina?.data?.ok + ' - ' + sendActionToGalina?.data?.result?.text}`;
            let result = await instance.get(actionToAlan)
        }
        let sendActionToGerman = await instance.get(actionUrltoGerman)
       if (sendActionToGerman) {
           let actionToAlan = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${instanceIdAlan}&parse_mode=html&text=${sendActionToGerman?.data?.ok + ' - ' + sendActionToGerman?.data?.result?.text}`;
           let result = await instance.get(actionToAlan)
       }
    },

    async sendPlan(body) {
        let token = '5340989881:AAHtsO5AW36-N4Ue0tb42hgkeplGTcOusXI';
        let instanceId = '-1001647699179';
        let instanceIdAlan = '428759533';
        let text = body.change ? 'План дополнен или изменен' : `Новый план работ на ${moment().add(1, 'days').format('LL')}`
        let changeUrl = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${instanceId}&parse_mode=html&text=${text}`;
        let sendMessageChange = await instance.get(changeUrl)
        if (sendMessageChange) {
            let actionToAlan = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${instanceIdAlan}&parse_mode=html&text=${sendMessageChange?.data?.ok + ' - ' + sendMessageChange?.data?.result?.text}`;
            let result = await instance.get(actionToAlan)
        }
        text = `<a href="${body.link}"><b>ОТКРЫТЬ</b></a>`
        let linkUrl = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${instanceId}&parse_mode=html&text=${text}`;
        let sendLink = await instance.get(linkUrl)
        if (sendLink) {
            let actionToAlan = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${instanceIdAlan}&parse_mode=html&text=${sendLink?.data?.ok + ' - ' + sendLink?.data?.result?.text}`;
            let result = await instance.get(actionToAlan)
        }
     }


}


