import {Empty, Table} from 'antd';
import React from "react";
const columns = [
    {
        title: '',
        dataIndex: 'name',
        key: 'name',

    },
    {
        title: '',
        dataIndex: 'value',
        key: 'value',
    },
];

const  getData = (props) => {



    if (props) {


        let result = [
            {
                key: 1,
                name: 'Телефон',
                value: props.phone
            },
            {
                key: 2,
                name: 'E-mail',
                value: props.email
            },
            {
                key: 3,
                name: 'Адрес',
                value: props.adress
            },
            {
                key: 4,
                name: `Контактное лицо (${props.first_parrent_name})` ,
                value: props.first_parrent_phone
            },
            {
                key: 5,
                name: `Контактное лицо (${props.second_parrent_name})` ,
                value: props.second_parrent_phone
            },



        ];
        return result
    } else {
        return [{
            key: 0,
            name: 'init',
            value: 'init'
        }]
    }

};
const Contacts = (props) => {
    if (props.data.length > 0) {
        return  <Table pagination={false} columns={columns} dataSource={getData(props.data[0])} />
    } else {
        return   <Empty description={<span> Доступ к разделу запрещен.</span>} />
    }
};

export default Contacts;