import React, {Component} from "react";
import {connect} from "react-redux";
import {
    changeStoreElemThunkCreator,
    changeStoreThunkCreator,
    getGeneralStoreThunkCreator, newStoreElemThunkCreator
} from "../../redux/reducers/generalStoreReducer";
import StoreTypeTab from "./StoreTypeTab";
import TransferLog from "./TransferLog";
import {Spin} from "antd";


class GeneralStore extends Component {


    componentDidMount() {
        this.props.getGeneralStoreThunkCreator();
    }

    render() {

        if (this.props.loading === false){
            return (
                <>
                    {this.props.page === '21' ? <StoreTypeTab
                        storesConfig={this.props.storesConfig}
                        setPage={this.props.setPage}
                        category={this.props.category}
                        data={this.props.storeData}
                        stores={this.props.storesConfig.stores}
                        transfer={this.props.changeStoreThunkCreator}
                        changeElem={this.props.changeStoreElemThunkCreator}
                        post={this.props.newStoreElemThunkCreator}

                    /> : ''}


                    {this.props.page === '22' ? <TransferLog
                        data={this.props.storeData}
                        transfer={this.props.transfer}
                        stores={this.props.storesConfig.stores}
                        staff={this.props.staff}

                    /> : ''}


                </>

            )
        } else {
            return <div className="example"><Spin size={'large'}/></div>
        }

    }
}

const mapStateToProps = (state) => {
    return {
        transfer: state.generalStoreRDCR.transfer,
        storeData: state.generalStoreRDCR.storeData,
        storesConfig: state.generalStoreRDCR.storesConfig,
        staff: state.generalStoreRDCR.staff,
        loading: state.generalStoreRDCR.loading,
        category: state.generalStoreRDCR.category,

    }
};

export default connect(mapStateToProps, {
    getGeneralStoreThunkCreator,
    changeStoreThunkCreator,
    changeStoreElemThunkCreator,
    newStoreElemThunkCreator

})(GeneralStore);


