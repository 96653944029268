import React from "react";
import {Divider, DatePicker, Tag, Descriptions, ConfigProvider, Collapse} from "antd";
import s from './styles/profile.module.css'
import {CloseSquareOutlined} from '@ant-design/icons';
import moment from "moment";
import 'moment/locale/ru';
import locale from 'antd/lib/locale/ru_RU';
const { Panel } = Collapse;

const {RangePicker} = DatePicker;

const ObjectProfile = (props) => {

    console.log(props)

    const setType = (id) => {
        switch (Number(id)) {
            case 1:
                return 'Постоянный'
            case 2:
                return 'Разовый'
            case 3:
                return 'Архивный'
            default:
                return 'Не определен'
        }
    }
    const setAccess = (id) => {
        switch (Number(id)) {
            case 1:
                return 'Открытый'
            case 2:
                return 'Ключ у садовников'
            case 3:
                return 'Через контактное лицо'
            case 4:
                return 'Ключ на объекте'
            default:
                return 'Не определен'
        }
    }
    const setAvalibleDay = (str) => {
        const setDay = (id) => {
            switch (Number(id)) {
                case 1:
                    return 'пн '
                case 2:
                    return 'вт '
                case 3:
                    return 'ср '
                case 4:
                    return 'чт '
                case 5:
                    return 'пт '
                case 6:
                    return 'сб '
                case 7:
                    return 'вс '
                default:
                    return 'Не определен'
            }
        }
        let arr = str ? str.split(',') : [];
        let result = ''
        if (arr.length > 1) {
            arr.forEach((el, index) => {
                result += Number(el) !== 0 ? setDay(index + 1) : ''
            })
        } else {
            result = 'ежедневно'
        }


        return result
    }
    const setDate = () => {

        let len = props.reports.reports.length
        let currentDate = props.reports.reports[len - 1] ? Number(props.reports.reports[len - 1].time_plan) : 0
        if (currentDate !== 0 && currentDate !== []) {
            currentDate = new Date(currentDate * 1000)
            let timestart = new Date(Number(props.reports.reports[len - 1].time_start) * 1000)
            let timeend = new Date(Number(props.reports.reports[len - 1].time_end) * 1000)
            let day = currentDate.getDate()
            let month = currentDate.getMonth() + 1
            let year = currentDate.getFullYear()
            return `${day}.${month}.${year} ${timestart.getHours()}:${timestart.getMinutes() < 10 ? `0${timestart.getMinutes()}` : timestart.getMinutes()}-${timeend.getHours()}:${timeend.getMinutes() < 10 ? `0${timeend.getMinutes()}` : timeend.getMinutes()}`
        } else {
            return 'Не известно'
        }

    }
    const setCoutOfService = () => {
        let now = new Date()
        let nowMonth = now.getMonth() + 1
        let count = 0;
        const check = (time) => {
            let currentDate = new Date(time * 1000)
            let month = currentDate.getMonth() + 1
            return month === nowMonth
        }
        for (let i = 0, len = props.reports.reports.length; i < len; i++) {
            if (check(Number(props.reports.reports[i].time_plan))) {
                count++
            }
        }
        return count
    }

    function onChange(dates, dateStrings) {
        if (dateStrings) {

            if (dateStrings[0] !== ''){
                let startDate = dateStrings[0].split("-");
                var newStartDate = new Date(startDate[0], startDate[1] - 1, startDate[2]);
                startDate = ((newStartDate.getTime() / 1000) + 3600).toFixed(0)

                let endDate = dateStrings[1].split("-");
                var newEndDate = new Date(endDate[0], endDate[1] - 1, endDate[2]);
                endDate = ((newEndDate.getTime() / 1000) + 3600).toFixed(0)

                if (startDate === endDate) {
                    props.filter({start: Number(startDate), end: Number(startDate) + 86400})
                } else {
                    props.filter({start: Number(startDate), end: Number(endDate)})
                }
            } else {
                props.filter({start: 0, end: 0})
            }




        }

    }



    let reportsRender = props.reports.reports.map(el => {

        const setDate = (time,start,end) => {
            let currentDate = new Date(time * 1000)
            let timestart = new Date(Number(start) * 1000)
            let timeend = new Date(Number(end) * 1000)
            let day = currentDate.getDate()
            let month = currentDate.getMonth() + 1
            let year = currentDate.getFullYear()
            return (<div>
                <div>{`Дата: ${day}.${month}.${year}`}</div>
                <div>{`Время: ${timestart.getHours()}:${timestart.getMinutes() < 10 ? `0${timestart.getMinutes()}` : timestart.getMinutes()}-${timeend.getHours()}:${timeend.getMinutes() < 10 ? `0${timeend.getMinutes()}` : timeend.getMinutes()}`}</div>
            </div>)



        }

        if (props.start === 0 || (props.start < Number(el.time_plan) && Number(el.time_plan))) {
            return  <div key={el.id} elem={el} className={s.reports_item}>{setDate(Number(el.time_plan),Number(el.time_start),Number(el.time_end))}</div>
        } else {
            return ''
        }
     }).filter(el => el !== '');



return (

    <>
        <div onClick={() => {
            props.setPage(0)
        }}><CloseSquareOutlined height={20} style={{
            color: 'red',
            display: 'block',
            float: 'right',
            marginRight: '20px',
            marginTop: '10px',
            fontSize: '20px',
            userSelect: 'none',
            cursor: 'pointer'


        }}/></div>
        <Divider orientation="left" plain>
            {props.elem.name}
        </Divider>
        <div className={s.profile_container_main}>



            <div className={s.profile_container}>
                <Collapse accordion>
                    <Panel header={"Общая информация"} key={'1'}>
                        <Descriptions size={'small'} column={1} title="Общая информация" layout="horizontal">
                            <Descriptions.Item label="Рабочее название"><b>{props.elem.long_name}</b></Descriptions.Item>
                            <Descriptions.Item label="Тип объекта"><b>{setType(props.elem.type)}</b></Descriptions.Item>
                            <Descriptions.Item
                                label="Описание (скрытое)"><b>{props.elem.description_private}</b></Descriptions.Item>
                            <Descriptions.Item label="Описание"><b>{props.elem.description}</b></Descriptions.Item>
                            <Descriptions.Item label="Доступ"><b>{setAccess(props.elem.access)}</b></Descriptions.Item>
                            <Descriptions.Item
                                label="Рабочие дни"><b>{setAvalibleDay(props.elem.avalible_day)}</b></Descriptions.Item>
                            <Descriptions.Item
                                label="Рабочее время"><b>{+props.elem.avalible_time === 0 ? 'весь день' : props.elem.avalible_time}</b></Descriptions.Item>
                            <Descriptions.Item label="Автополив"><b>{+props.elem.auto_water === 0 ? <Tag color="red">Нет</Tag> :
                                <Tag color="green">Есть</Tag>}</b></Descriptions.Item>
                            <Descriptions.Item label="Локация"><b>{props.elem.locate}</b></Descriptions.Item>
                            <Descriptions.Item
                                label="Видимость"><b>{Number(props.elem.visible) === 1 ? 'Видимый' : 'Скрытый'}</b></Descriptions.Item>
                        </Descriptions>
                    </Panel>

                    <Panel header={"Контактная информация"} key={'2'}>
                        <Descriptions size={'small'} column={1} title="Контактная информация" layout="horizontal">
                            <Descriptions.Item
                                label="Контактное лицо (скрытый)"><b>{props.elem.contact_name_private}</b></Descriptions.Item>
                            <Descriptions.Item
                                label="Телефон (скрытый)"><b>{props.elem.contacts_private}</b></Descriptions.Item>
                            <Descriptions.Item
                                label="Контактное лицо (видимый)"><b>{props.elem.contact_name}</b></Descriptions.Item>
                            <Descriptions.Item label="Телефон (видимый)"><b>{props.elem.contacts}</b></Descriptions.Item>
                            <Descriptions.Item label="Адрес"><b>{``}</b></Descriptions.Item>
                            <Descriptions.Item label="Национальность владельца">{props.elem.nationality !== '' ?
                                <Tag color="blue">{props.elem.nationality}</Tag> : ''}</Descriptions.Item>
                        </Descriptions>
                    </Panel>
                    <Panel header={"Обслуживание"} key={'3'}>
                        <Descriptions size={'small'} column={1} title="Обслуживание" layout="horizontal">
                            <Descriptions.Item label="Дата последнего обслуживания"><b>{setDate()}</b></Descriptions.Item>
                            <Descriptions.Item
                                label="Комментарий с последнего обслуживания"><b>{props.elem.comment_last_service}</b></Descriptions.Item>
                            <Descriptions.Item label="Обслуживаний за месяц"><Tag
                                color="blue">{setCoutOfService()}</Tag></Descriptions.Item>
                        </Descriptions>
                    </Panel>
                </Collapse>

            </div>

            <div className={s.reports_cont}>
                <div className={s.report__title}>Обслуживание объекта</div>
                <ConfigProvider locale={locale}>
                    <RangePicker
                        ranges={{
                            'Сегодня': [moment(), moment()],
                            'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
                        }}
                        onChange={onChange}
                    />
                </ConfigProvider>

                <div>{reportsRender}</div>
            </div>


        </div>
    </>
)
}
export default ObjectProfile