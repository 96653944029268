import {analiticsAPI, dataAPI, objectAPI, reportsAPI} from "../../API/API";
import {GETSTAFFAC} from "./staffReducer";


const GETOBJANL = 'GET-OBJ-ANL';
const GETDATAANL = 'GET-DATA-ANL';
const GETFINANL = 'GET-FIN-ANL';
const GETWORKSANL = 'GET-WORKS-ANL';
const LOADANL = 'LOAD-ANL';

let initialState = {
    objects: {},
    data: {},
    loading: false,
    works: [],
    finance: {}
};


const AnaliticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GETOBJANL: {
            return {
                ...state,
                objects: action.obj
            }
        }
        case GETDATAANL: {
            return {
                ...state,
                data: action.data
            }
        }
        case GETFINANL: {
            return {
                ...state,
                finance: action.finance
            }
        }
        case LOADANL: {
            return {
                ...state,
                loading: action.loading
            }
        }
        case GETWORKSANL: {
            return {
                ...state,
                works: action.works
            }
        }
        default:
            return state;
    }

};

export let GETOBJANLAC = (obj) => ({type: GETOBJANL, obj});
export let GETDATAANLAC = (data) => ({type: GETDATAANL, data});
export let GETFINANLAC = (finance) => ({type: GETFINANL, finance});

export let LOADANLAC = (loading) => ({type: LOADANL, loading});
export let GETWORKSANLAC = (works) => ({type: GETWORKSANL, works});



export const getObjectsThunkCreator = () => async (dispatch) => {
    let response = await objectAPI.getObjects();
    if (response) {
        dispatch(GETOBJANLAC(response.data.objects));
    }
};
export const getDataTable_1 = (start, stop, id) => async (dispatch) => {
    dispatch(LOADANLAC(true));
    let response = await analiticsAPI.getDataTable_1(start, stop, id);
    if (response) {
        dispatch(GETDATAANLAC(response.data.data));
        dispatch(LOADANLAC(false));
    }
};
export const getDataTable_3 = (start, stop) => async (dispatch) => {
    dispatch(LOADANLAC(true));
    let response = await analiticsAPI.getDataTable_3(start, stop);
    if (response) {
        dispatch(GETFINANLAC(response.data.data));
        dispatch(LOADANLAC(false));
    }
};
export const getWorksThunkCreator = () => async (dispatch) => {
    let response = await reportsAPI.getReportsTemp();
    if (response) {
        dispatch(GETWORKSANLAC(response.data.template));
    }
};
export default AnaliticsReducer;




