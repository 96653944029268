import {Upload, Button, message, Modal} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import React from "react";
import { PlusOutlined } from '@ant-design/icons';
import {url_1} from '../../API/API'


function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}


class UploadManual extends React.Component {
    constructor(props){
        super(props)
    }

    defaultPhotoList = this.props.photo ? this.props.photo.map((el,index) => {
        return (
            {
                uid: index,
                name: index,
                status: 'done',
                response: 'Server Error 500', // custom error message to show
                url: `https://gdapp.org/data/${el.link}`,
            }
        )
        }) : []

    state = {
        fileList: [],
        uploading: false,
        previewVisible: false,
        previewImage: '',
        previewTitle: '',
    };

    handleCancel = () => this.setState({ previewVisible: false });
    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };
    url = `${url_1}plans/uploadReports.php?id=${this.props.id}`;
    handleUpload = () => {
        const { fileList } = this.state;
        const formData = new FormData();
        fileList.forEach(file => {
            formData.append('files[]', file);
        });
        this.setState({
            uploading: true,
        });
        // You can use any AJAX library you like
        fetch(this.url, {
            method: 'POST',
            body: formData,
        })
            .then(res => {console.log(res)})
            .then(() => {
                this.setState({
                    fileList: [],
                });
                message.success('загрузка завершена.');
            })
            .catch(() => {
                message.error('ошибка загрузки.');
            })
            .finally(() => {
                this.setState({
                    uploading: false,
                });
            });
        this.props.close()
        this.props.setPhoto()
    };

    render() {

        console.log(this.state.fileList)
        const { uploading, fileList } = this.state;
        const props = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,

        };
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Добавить</div>
            </div>
        );
        return (
            <>
                <Upload {...props}
                        listType={"picture-card"}
                        fileList={this.setState.fileList}
                        onPreview={this.handlePreview}
                        maxCount={30}
                        multiple
                        defaultFileList={this.defaultPhotoList}
                >
                    {fileList.length >= 8 ? null : uploadButton}
                </Upload>
                <Button
                    type="primary"
                    onClick={this.handleUpload}
                    disabled={fileList.length === 0}
                    loading={uploading}
                    style={{ margin: '16px auto', display: 'block' }}
                >
                    {uploading ? 'Загружаю...' : 'Загрузить'}
                </Button>
                <Modal
                    visible={this.state.previewVisible}
                    title={this.state.previewTitle}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
            </>
        );
    }
}

export default UploadManual;

