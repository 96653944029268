import {Calendar, Badge, Table, ConfigProvider, Typography } from 'antd';
import s from './styles/finance.module.css'
import ruRU from 'antd/lib/locale/ru_RU';
import moment from "moment";

import React, {useState} from 'react';
import {Modal} from 'antd';


const Statistic = (props) => {
    const [isModalVisible, setIsModalVisible] = useState({visible: false, unix: 0});
    const showModal = (date) => {
        setIsModalVisible({visible: true, unix: date.format('X')});
    };
    const handleOk = () => {
        setIsModalVisible({visible: false, unix: 0});
    };
    const handleCancel = () => {
        setIsModalVisible({visible: false, unix: 0});
    };
    function getListData(value) {
        /*        let listData;
                switch (value.date()) {
                    case 8:
                        listData = [
                            { type: 'warning', content: 'This is warning event.' },
                            { type: 'success', content: 'This is usual event.' },
                        ];
                        break;
                    case 10:
                        listData = [
                            { type: 'warning', content: 'This is warning event.' },
                            { type: 'success', content: 'This is usual event.' },
                            { type: 'error', content: 'This is error event.' },
                        ];
                        break;
                    case 15:
                        listData = [
                            { type: 'warning', content: 'This is warning event' },
                            { type: 'success', content: 'This is very long usual event。。....' },
                            { type: 'error', content: 'This is error event 1.' },
                            { type: 'error', content: 'This is error event 2.' },
                            { type: 'error', content: 'This is error event 3.' },
                            { type: 'error', content: 'This is error event 4.' },
                        ];
                        break;
                    default:
                }
                return listData || [];*/
        return []
    }
    function dateCellRender(value) {
        const listData = getListData(value);
        return (
            <ul className="events">
                {listData.map(item => (
                    <li key={item.content}>
                        <Badge status={item.type} text={item.content}/>
                    </li>
                ))}
            </ul>
        );
    }

    const plannedIncome = (unix) => {

        let cashincome = 0;
        let cashless = 0;
        let nds;
//Планируемые доходы
        const NumberOfCurrentMonth = Number(moment(unix*1000).format('M'))
        console.log(NumberOfCurrentMonth)
        for (let i = 0, len = props.income.length; i < len; i++){
            let obj = props.income[i]; //массив значений абонентской платы по месяцам
            let type = Number(obj.type);    //тип оплаты нал/безнал
            let costIndex = NumberOfCurrentMonth - 1; //индекс значения абон/пл. в массиве по месяцу
            let costArray = obj.cost.replace(',','.').split('`') //строку со значениями в массив
            cashincome += type === 1 ? Number(costArray[costIndex]) : 0 //прибавлем к наличке
            cashless += type === 2 ? Number(costArray[costIndex]) : 0 //прибавляем к безналу
            //nds += type === 2 ? Number(costArray[costIndex])  *  Number(costArray[costIndex]) / 100: 0 //прибавляем НДС если безнал

        }
        nds = (cashless / 100) * 21
        console.log(cashincome)
        console.log(cashless)
        console.log(nds)
        return [Number(cashincome).toFixed(2),Number(cashless).toFixed(2),Number(nds).toFixed(2),Number(cashincome+cashless).toFixed(2)]





    }

    const plannedExpenses = (unix) => {
        //Планируемые расходы (заработная плата)
        let salary = 0;
        for (let i = 0, len = props.salary.length; i < len; i++){
             salary += Number(props.salary[i].rate)
        }
        return [Number(salary).toFixed(2)]

    }


 /*   const summ = (type, month) => {
        let summCash = 0;
        let summCashless = 0;
        let summ = 0;
        if (Number(type) === 1) {
            return plannedIncome()
        } else if (Number(type) === 2) {
            let arr = props.cars_expenses;
            for (let i = 0, len = arr.length; i < len; i++) {
                if (Number(arr[i].enable) !== 0) {
                    summ += setPeriod(arr[i].period, arr[i].cost)
                    summ += Number(arr[i].type) === 2 ? (setPeriod(arr[i].period, arr[i].cost) / 100) * Number(arr[i].nds) : 0
                }
            }

            arr = props.rent_expenses;
            for (let i = 0, len = arr.length; i < len; i++) {
                if (Number(arr[i].enable) !== 0) {
                    summ += setPeriod(arr[i].period, arr[i].cost)
                    summ += Number(arr[i].type) === 2 ? (setPeriod(arr[i].period, arr[i].cost) / 100) * Number(arr[i].nds) : 0
                }
            }

            arr = props.oper_expenses;
            for (let i = 0, len = arr.length; i < len; i++) {
                if (Number(arr[i].enable) !== 0) {
                    summ += setPeriod(arr[i].period, arr[i].cost)
                    summ += Number(arr[i].type) === 2 ? setPeriod(arr[i].period, arr[i].cost) * Number(arr[i].nds) : 0
                }
            }
            arr = props.one_expenses;

            for (let i = 0, len = arr.length; i < len; i++) {

                let flag = (Number(moment(month * 1000).startOf('month').format('X')) < Number(arr[i].date) && Number(moment(month * 1000).endOf('month').format('X')) > Number(arr[i].date))


                if (flag) {
                    summ += Number(arr[i].cost)
                    summ += Number(arr[i].type) === 2 ? Number(arr[i].cost) * Number(arr[i].nds) : 0
                }
            }

            arr = props.salary
            for (let i = 0, len = arr.length; i < len; i++) {
                summ += Number(arr[i].enable) !== 0 ? Number(arr[i].rate) + (Number(arr[i].cashless) * 0.68) : 0

            }


            return [summCash, summCashless, summ]
        }


        /!*        salary = {props.salary}
                cars_expenses = {props.cars_expenses}
                rent_expenses = {props.rent_expenses}
                oper_expenses = {props.oper_expenses}*!/
    }*/
    function getMonthData(value) {
        let inc = plannedIncome(Number(value.format('X')))
        let exp = plannedExpenses(Number(value.format('X')))
        const columns = [

            {
                title: 'Доход',
                dataIndex: 'income',
                key: 'income',
                align: 'center',
                render: () => {
                    return (<span>{`${Number(inc[3]).toFixed(2)} €`}</span>)
                },
            },
            {
                title: 'Расход',
                dataIndex: 'expenses',
                key: 'expenses',
                align: 'center',
                render: () => {
                    return (<span>{`${Number(exp).toFixed(2)} €`}</span>)
                },
                /*            render: (rate) => {return (<span>{`${Number(rate).toFixed(2)} €`}</span>)},*/
            },
            {
                title: 'Итого',
                dataIndex: 'all',
                key: 'all',
                align: 'center',
                render: () => {
                    return (<span>{`${Number(inc[3] - exp).toFixed(2)} €`}</span>)
                },
            },


        ];

        const data = [

            {
                type: 'Безнал',
                income: inc[3],
/*                expenses: expenses[2].toFixed(2),
                all: (income[2] - expenses[2]).toFixed(2)*/
            },

        ]
        let render = <div className={s.status__cont}>


            <Table
                dataSource={data}
                columns={columns}
                style={{fontSize: 10}}
                size={'small'}
                pagination={false}
            />


            {/*            <div className={s.status__cont__item}><Tag
                color={"orange"}>БЕЗНАЛ</Tag> {`Доход: ${income[1]} | Расход | Итого`}</div>
            <div className={s.status__cont__item}><Tag
                color={"green"}>НАЛИЧНЫЕ</Tag> {`Доход: ${income[0]} | Расход | Итого`}</div>
            <div className={s.status__cont__item}><Tag
                color={"blue"}>ИТОГО</Tag> {`Доход: ${income[2]} | Расход | Итого`}</div>*/}

        </div>

        return render
        /*        if (value.month() === 8) {
                    return 1394;
                }*/
    }
    function monthCellRender(value) {
        const num = getMonthData(value);
        return num ? (
            <div className="notes-month">
                <section>{num}</section>
                {/*                <span>Backlog number</span>*/}
            </div>
        ) : null;
    }
    return (<div className={s.statistic_cont}>
            <ConfigProvider locale={ruRU}>
                <Calendar
                    onSelect={(date) => {showModal(date)}}
                    dateCellRender={dateCellRender}
                    monthCellRender={monthCellRender}
                    mode={'year'}
                    headerRender={({ value, type, onChange, onTypeChange }) => {
                      return  ( <Typography.Title className={s.title_2} level={4}>Планируемые показатели</Typography.Title>)
                    }
                    }

                />
            </ConfigProvider>
            <Modal title={`Сводка за ${moment(Number(isModalVisible.unix)*1000).format('MMMM')}`}
                   unix={isModalVisible.unix}
                   visible={isModalVisible.visible}
                   onOk={handleOk}
                   onCancel={handleCancel}


            >
                <ModalWinStatistic unix={isModalVisible.unix}
                                   income={props.income}
                                   salary={props.salary}
                                   cars_expenses={props.cars_expenses}
                                   rent_expenses={props.rent_expenses}
                                   oper_expenses={props.oper_expenses}
                                   one_expenses={props.one_expenses}

                />
            </Modal>
        </div>)
}


const ModalWinStatistic = (props) => {

    let plancashexpenses = 0;
    let plancashlessexpenses = 0;
    let planndsexpenses = 0;

    let salaryexpenses = 0;
    let salarycashlessexpenses = 0;
    let salarytaxexpenses = 0;
    let cashincome = 0;
    let cashless = 0;
    let nds = 0;
    let all = 0;
    let oneexpenses = 0;
    let onecashlessexpenses = 0;
    let onendsexpenses = 0;

    const setPeriod = (period, cost) => {

        switch (Number(period)) {
            case 1: return Number(cost) * 30
            case 2: return  Number(cost) * 4
            case 3: return  Number(cost)
            case 4: return  Number(cost) / 12
        }
    }






        for (let i = 0, len = props.income.length; i < len; i++){
            let obj = props.income[i];
            let type = Number(obj.type);
            const NumberOfCurrentMonth = Number(moment().format('M'))
            let costIndex = NumberOfCurrentMonth - 1;
            let costArray = obj.cost.replace(',','.').split('`')
            cashincome += type === 1 ? Number(costArray[2]) : 0
            cashless += type === 2 ? Number(costArray[2]) : 0
            all = cashincome + cashless

            nds += type === 2 ? Number(obj.nds)  *  (Number(cashless) / 100): 0
        }





    for (let i = 0, len = props.cars_expenses.length; i < len; i++){
        let obj = props.cars_expenses[i];
        let type = Number(obj.type);
        plancashexpenses += type === 1 ? setPeriod(Number(obj.period), Number(obj.cost))  : 0
        plancashlessexpenses += type === 2 ? setPeriod(Number(obj.period), Number(obj.cost)) : 0
        planndsexpenses += type === 2 ? (setPeriod(Number(obj.period), Number(obj.cost)) / 100)  * Number(obj.nds) : 0

    }
    for (let i = 0, len = props.rent_expenses.length; i < len; i++){
        let obj = props.rent_expenses[i];
        let type = Number(obj.type);
        plancashexpenses += type === 1 ? setPeriod(Number(obj.period), Number(obj.cost))  : 0
        plancashlessexpenses += type === 2 ? setPeriod(Number(obj.period), Number(obj.cost)) : 0
        planndsexpenses += type === 2 ? (setPeriod(Number(obj.period), Number(obj.cost)) / 100)  * Number(obj.nds) : 0

    }
    for (let i = 0, len = props.oper_expenses.length; i < len; i++){
        let obj = props.oper_expenses[i];
        let type = Number(obj.type);
        plancashexpenses += type === 1 ? setPeriod(Number(obj.period), Number(obj.cost))  : 0
        plancashlessexpenses += type === 2 ? setPeriod(Number(obj.period), Number(obj.cost)) : 0
        planndsexpenses += type === 2 ? (setPeriod(Number(obj.period), Number(obj.cost)) / 100)  * Number(obj.nds) : 0

    }

    for (let i = 0, len = props.salary.length; i < len; i++){
        let obj = props.salary[i];
        salaryexpenses += Number(obj.cash)
        salarycashlessexpenses += Number(obj.cashless)
        salarytaxexpenses += Number(obj.cashless) * 0.68

    }

    for (let i = 0, len = props.one_expenses.length; i < len; i++){
        let obj = props.one_expenses[i];
        let type = Number(obj.type);
        let flag = (Number(moment(props.unix * 1000).startOf('month').format('X')) < Number(obj.date) && Number(moment(props.unix * 1000).endOf('month').format('X')) > Number(obj.date))

        oneexpenses += (type === 1 && flag) ? Number(obj.cost)  : 0
        onecashlessexpenses += (type === 2 && flag) ? Number(obj.cost) : 0
        onendsexpenses += (type === 2 && flag) ? Number(obj.cost)  *  (1 + (Number(obj.nds) / 100)) - Number(obj.cost): 0

    }


    return (
        <div>
            <div className={s.statistic_cont_item}>
                <div className={s.statistic_cont_item_header}>Доходы от объектов</div>
                <p>{`Наличными: ${cashincome.toFixed(2)} €`}</p>
                <p>{`Безналичными: ${cashless.toFixed(2)} €`}</p>
                <p><b>{`ИТОГО: ${all.toFixed(2)} € | Ндс: ${((cashless / 100) * 21).toFixed(2)} €`}</b></p>
            </div>
            <div className={s.statistic_cont_item_orange}>
                <div className={s.statistic_cont_item_header_orange}>Фонд заработной платы</div>
                <p>{`Наличными: ${salaryexpenses.toFixed(2)} €`}</p>
                <p>{`Безналичными: ${salarycashlessexpenses.toFixed(2)} €`}</p>
                <p>{`Налоги: ${salarytaxexpenses.toFixed(2)} €`}</p>
                <p><b>{`ИТОГО: ${(salaryexpenses + salarycashlessexpenses + salarytaxexpenses).toFixed(2)} €`}</b></p>
            </div>
            <div className={s.statistic_cont_item_red}>
            <div className={s.statistic_cont_item_header_red}>Расходы плановые (Транспорт, Офис, Операционные)</div>
            <p>{`Наличными: ${plancashexpenses.toFixed(2)} €`}</p>
            <p>{`Безналичными: ${plancashlessexpenses.toFixed(2)} €`}</p>
            <p><b>{`ИТОГО: ${(plancashexpenses + plancashlessexpenses).toFixed(2)} € | Ндс: ${planndsexpenses.toFixed(2)} €`}</b></p>
        </div>
            <div className={s.statistic_cont_item_blue}>
                <div className={s.statistic_cont_item_header_blue}>Расходы разовые</div>
                <p>{`Наличными: ${oneexpenses.toFixed(2)} €`}</p>
                <p>{`Безналичными: ${onecashlessexpenses.toFixed(2)} €`}</p>
                <p><b>{`ИТОГО: ${(oneexpenses + onecashlessexpenses).toFixed(2)} € | Ндс: ${onendsexpenses.toFixed(2)} €`}</b></p>
            </div>

            <div className={s.statistic_cont_item_black}>
                <div className={s.statistic_cont_item_header_black}>{`ИТОГО за ${moment(Number(props.unix)*1000).format('MMMM')}`}</div>
                <p>{`Доход: ${(cashincome + cashless + nds).toFixed(2)} €`}</p>
                <p>{`Расход: ${(salaryexpenses + salarycashlessexpenses + salarytaxexpenses + plancashexpenses + plancashlessexpenses + oneexpenses + onecashlessexpenses).toFixed(2)} €`}</p>
                <p><b>{`Прибыль: ${(cashincome + cashless + nds - (salaryexpenses + salarycashlessexpenses + oneexpenses + onecashlessexpenses + plancashexpenses + plancashlessexpenses)).toFixed(2)} €`}</b></p>
            </div>

        </div>
    )
}

export default Statistic