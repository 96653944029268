import {authAPI} from "../../API/API";


const SETLOGIN = 'SET-LOGIN-A';
const SETUSERNAME = 'SET-USERNAME-A';
const SETCOOKIE = 'SET-COOKIE-A';
const SETSTATUS = 'SET-STATUS-A';
const SETERROR = 'SET-ERROR-A';
const SETMESS = 'SET-MESS';
const SETROLEAUTH = 'SET-ROLE-AUTH';
const SETIDAUTH = 'SET-ID-AUTH';
let initialState = {
    cookie: null,
    status: false,
    error: true,
    username: '',
    message: '',
    role: '',
    id: 0
};


// Редюссер конфигурации
const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETERROR: {
            return {
                ...state,
                error: action.error
            }
        }
        case SETIDAUTH: {
            return {
                ...state,
                id: action.id
            }
        }
        case SETROLEAUTH: {
            return {
                ...state,
                role: action.role
            }
        }
        case SETSTATUS: {
            return {
                ...state,
                status: action.status
            }
        }
        case SETUSERNAME: {
            return {
                ...state,
                username: action.username
            }
        }
        case SETCOOKIE: {
            return {
                ...state,
                cookie: action.cookie
            }
        }
        case SETMESS: {
            return {
                ...state,
                message: action.message
            }
        }

        default:
            return state;
    }

};



let SETROLEAUTHAC = (role) => ({type: SETROLEAUTH, role});
let SETIDAUTHAC = (id) => ({type: SETIDAUTH, id});
export let SETLOGINAC = (login) => ({type: SETLOGIN, login});
export let SETCOOKIEAC = (cookie) => ({type: SETCOOKIE, cookie});
export let SETSTATUSAC = (status) => ({type: SETSTATUS, status});
export let SETERRORAC = (error) => ({type: SETERROR, error})
let SETMESSAC = (message) => ({type: SETMESS, message})
let SETNAMEAC = (username) => ({type: SETUSERNAME, username});

function get_cookie(cookie_name) {
    let results = document.cookie.match('(^|;) ?' + cookie_name + '=([^;]*)(;|$)');

    if (results)
        return (unescape(results[2]));
    else
        return null;
}

export const authThunkCreator = (login, pass) => async (dispatch) => {
    let response = await authAPI.auth(login, pass);
    if (response) {
        if (response.auth) {

            dispatch(SETERRORAC(false));
            document.cookie = `hash=${response.hash}`;
            document.cookie = `login=${login}`;
            dispatch(checkThunkCreator(get_cookie("login"), get_cookie("hash")));
            dispatch(SETMESSAC(''));
        } else {
            dispatch(SETERRORAC(true));
            dispatch(SETMESSAC('Неправильно указан логин или пароль'));
        }
    }
}

export const checkThunkCreator = (login = get_cookie("login"), hash = get_cookie("hash")) => async (dispatch) => {
    let response = await authAPI.check(login, hash);
    if (response) {
        if (response['access']) {
            console.log(response['staff'])
            dispatch(SETCOOKIEAC(response['staff'][0].id));
            dispatch(SETROLEAUTHAC(response['staff'][0].role));
            dispatch(SETIDAUTHAC(response['staff'][0].id));
            dispatch(SETLOGINAC(response['staff'][0].login));
            let name = `${response['staff'][0].firstname} ${response['staff'][0].lastname}`
            dispatch(SETNAMEAC(name));
            dispatch(SETSTATUSAC(true));
        } else {
            dispatch(SETSTATUSAC(false));
        }


    }
}
/*export const getUserThunkCreator = () => async (dispatch) => {
    let response = await opsAPI.getUsers();
    if (response) {

    }
}*/

export default AuthReducer;