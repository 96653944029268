import {dataAPI} from "../../API/API";



const GETSTAFF = 'GET-STAFF-ST';
const GETPD = 'GET-PD-ST';
const SETPAGESTAFF = 'SET-PAGE-ST';
const GETRULESST = 'GET-RULES-ST';



let initialState = {
    staff: {},
    pd: {},
    currentPage: 0,
    rules: {}




};


const StaffReducer = (state = initialState, action) => {
    switch (action.type) {
        case GETSTAFF: {
            return {
                ...state,
                staff: action.staff
            }
        }
        case GETPD: {
            return {
                ...state,
                pd: [...action.pd, action.pd.role = action.role]
            }
        }
        case GETRULESST: {
            return {
                ...state,
                rules: action.rules
            }
        }
        case SETPAGESTAFF: {
            return {
                ...state,
                currentPage: action.currentPage
            }
        }

        default:
            return state;
    }

};

export let GETSTAFFAC = (staff) => ({type: GETSTAFF, staff});
export let GETPDAC = (pd, role) => ({type: GETPD, pd, role});
export let SETPAGESTAFFAC = (currentPage) => ({type: SETPAGESTAFF, currentPage});
export let GETRULESSTAC = (rules) => ({type: GETRULESST, rules});


export const getStaffThunkCreator = () => async (dispatch) => {
    let response = await dataAPI.getStaff();
    if (response) {
        dispatch(GETSTAFFAC(response.data.staff));
        response = await dataAPI.getRules();
        if (response) {
            dispatch(GETRULESSTAC(response.data.rules));
        }
    }
};



export const getPdThunkCreator = (id) => async (dispatch) => {
    let response = await dataAPI.getPersonalData(id);
    if (response) {
        dispatch(GETPDAC(response.data.pd, response.data.role));
    }
};

export const postStaffThunkCreator = (staff, id) => async (dispatch) => {

    let response = await dataAPI.postStaff(staff, id);
    if (response) {
        response = await dataAPI.getStaff();
        if (response) {
            dispatch(GETSTAFFAC(response.data.staff));
        }
    }

};



export default StaffReducer;




