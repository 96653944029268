import React from "react";
import s from "./mobile.module.css";

import {Button, Checkbox, Collapse, Input} from "antd";

const {Panel} = Collapse;


const Report = (props) => {
    const onChange = (elemId) => {
        const mappedArray = props.check.map(el => Number(el.id) === Number(elemId) ? {...el,checked: !el.checked} : el)
            props.setCheck(mappedArray)
        props.setwork()
        };

    const eventListenerChangeInput = (id, e) => {
        const mappedArray = props.check.map(el => Number(el.id) === Number(id) ? {...el,inputVal: e.target.value} : el)
        props.setCheck(mappedArray)

    };

    let mainWorks = props.reportsTemp.map((el,index) => {
            if (Number(el.type) === 1) {
                return (<div key={Number(el.id)}>
                        <Checkbox key={Number(el.id)} checked={props.check[el.id - 1].checked} onChange={(e) => {
                            onChange(Number(el.id))
                        }}>{el.name}</Checkbox>
                        <br/>
                        {Number(el.input) !== 0 &&
                         <Input  value={props.check[el.id - 1].inputVal} className={props.check[el.id - 1].checked === false ? 'novision' : ''} placeholder={props.check[el.id - 1].inputName} onChange={(e) => {
                            eventListenerChangeInput(el.id, e)
                        }}/>}
                    </div>
                )
            } else {
                return ''
            }
        }).filter((el) => {
            return el !== ''
        });

    let chemistry = props.reportsTemp.map((el,index) => {
            if (Number(el.type) === 2) {
                return (<div key={Number(el.id)}>
                        <Checkbox key={Number(el.id)} checked={props.check[el.id - 1].checked} onChange={(e) => {
                            onChange(Number(el.id))
                        }}>{el.name}</Checkbox>
                        <br/>
                        {Number(el.input) !== 0 && <Input value={props.check[el.id - 1].inputVal} className={props.check[el.id - 1].checked === false ? 'novision' : ''} placeholder={props.check[el.id - 1].inputName} onChange={(e) => {
                            eventListenerChangeInput(el.id, e)
                        }}/>}
                    </div>
                )
            } else {
                return ''
            }
        }).filter((el) => {
            return el !== ''
        });
        let water = props.reportsTemp.map((el,index) => {
            if (Number(el.type) === 3) {
                return (<div key={Number(el.id)}>
                        <Checkbox key={Number(el.id)} checked={props.check[el.id - 1].checked} onChange={(e) => {
                            onChange(Number(el.id))
                        }}>{el.name}</Checkbox>
                        <br/>
                        {Number(el.input) !== 0 && <Input value={props.check[el.id - 1].inputVal} className={props.check[el.id - 1].checked === false ? 'novision' : ''} placeholder={props.check[el.id - 1].inputName} onChange={(e) => {
                            eventListenerChangeInput(el.id, e)
                        }}/>}
                    </div>
                )
            } else {
                return ''
            }
        }).filter((el) => {
            return el !== ''
        });
        let autoWater = props.reportsTemp.map((el,index) => {
            console.log(el)
            if (Number(el.type) === 4) {
                return (<div key={Number(el.id)}>
                        <Checkbox key={Number(el.id)} checked={props.check[el.id - 1].checked} onChange={(e) => {
                            onChange(Number(el.id))
                        }}>{el.name}</Checkbox>
                        <br/>
                        {Number(el.input) !== 0 && <Input value={props.check[el.id - 1].inputVal} className={props.check[el.id - 1].checked === false ? 'novision' : ''} placeholder={props.check[el.id - 1].inputName} onChange={(e) => {
                            eventListenerChangeInput(el.id, e)
                        }}/>}
                    </div>
                )
            } else {
                return ''
            }
        }).filter((el) => {
            return el !== ''
        });
        let other = props.reportsTemp.map((el,index) => {
            if (Number(el.type) === 5) {
                return (<div key={Number(el.id)}>
                        <Checkbox key={Number(el.id)} checked={props.check[el.id - 1].checked} onChange={(e) => {
                            onChange(Number(el.id))
                        }}>{el.name}</Checkbox>
                        <br/>
                        {Number(el.input) !== 0 && <Input value={props.check[el.id - 1].inputVal}  className={props.check[el.id - 1].checked === false ? 'novision' : ''} placeholder={props.check[el.id - 1].inputName} onChange={(e) => {
                            eventListenerChangeInput(el.id, e)
                        }}/>}
                    </div>
                )
            } else {
                return ''
            }
        }).filter((el) => {
            return el !== ''
        });
        let finance = props.reportsTemp.map((el,index) => {
            if (Number(el.type) === 6) {
                return (<div key={Number(el.id)}>
                        <Checkbox key={Number(el.id)} checked={props.check[el.id - 1].checked} onChange={(e) => {
                            onChange(Number(el.id))
                        }}>{el.name}</Checkbox>
                        <br/>
                        {Number(el.input) !== 0 && <Input value={props.check[el.id - 1].inputVal} className={props.check[el.id - 1].checked === false ? 'novision' : ''} placeholder={props.check[el.id - 1].inputName} onChange={(e) => {
                            eventListenerChangeInput(el.id, e)
                        }}/>}
                    </div>
                )
            } else {
                return ''
            }
        }).filter((el) => {
            return el !== ''
        });

    return (<>


            <div className={s.chekContainer}>
                <Collapse accordion ghost={'true'}>
                    <Panel header="Основные работы" key="1">
                        {mainWorks}
                    </Panel>
                    <Panel header="Химия и удобрения" key="2">
                        {chemistry}
                    </Panel>
                    <Panel header="Полив" key="3">
                        {water}
                    </Panel>
                    <Panel header="Автополив" key="4">
                        {autoWater}
                    </Panel>
                    <Panel header="Остальное" key="5">
                        {other}
                    </Panel>
                    <Panel header="Финансы" key="6">
                        {finance}
                    </Panel>
                </Collapse>
            </div>


            <Button
                type="primary"
                onClick={props.close}
                style={{ margin: '16px auto', display: 'block' }}
            >
                Ок
            </Button>

        </>
    )


}


export default Report;