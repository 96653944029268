import React, {useState} from "react";
import {Table, Switch, Button, Collapse, Space} from 'antd';
import AddOneExpenses from "./AddOneExpenses";
import moment from "moment";
import OneFinanceInfo from "./OneFinanceInfo";
/*import OperFinanceInfo from "./OperFinanceInfo";
import AddOperFinanceExpensive from "./AddOperFinanceExpensive";*/
const {Panel} = Collapse;
const OneFinanceExpensive = (props) => {

    let [page, setPage] = useState(1)

    console.log(props)
    let summRate = 0, summAll = 0;

    const getArticleNameById = (id) => {
        for (let i = 0, len = props.articles_expenses.length; i < len; i++) {
            if (Number(props.articles_expenses[i].id) === Number(id)) {
                return props.articles_expenses[i].name
            }
        }
    }

    const setDate = (unix) => {
        return moment(unix*1000).format('L')
    }
    const defaultData = props.one_expenses.map(el => {
        summRate += Number(el.cost)
        summAll += Number(el.cost) * (1 + (Number(el.nds) / 100))
        return {
            id: el.id,
            name: el.name,
            article: getArticleNameById(el.article),
            type: el.type,
            cost: el.cost,
            nds: el.nds,
            all: el.cost * (1 + (Number(el.nds) / 100)),
            date: setDate(Number(el.date))
        }
    })
    defaultData.push({id: 0, name: 'ИТОГО:', article: '', type: '', cost: summRate, nds: '', enable: '', all: summAll})

    let [state, setState] = useState(defaultData)



    const columns = [
        {
            title: 'Статья затрат',
            dataIndex: 'article',
            key: 'article',
        },
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
            /*            render: (rate) => {return (<span>{`${Number(rate).toFixed(2)} €`}</span>)},*/
        },
        {
            title: 'Тип платежа',
            dataIndex: 'type',
            key: 'type',
            render: (type, e) => {
                return Number(e.id !== 0) ? (<span>{Number(type) === 1 ? 'Наличные' : 'Безнал'}</span>) : ''
            },
        },
        {
            title: 'Стоимость',
            dataIndex: 'cost',
            key: 'cost',
            render: (cost) => {
                return (<span>{`${Number(cost).toFixed(2)} €`}</span>)
            },
        },
        {
            title: 'НДС',
            dataIndex: 'nds',
            key: 'nds',
            render: (nds, elem) => {
                return Number(elem.id !== 0) ? <span>{`${nds} %`}</span> : ''
            },
        },
        {
            title: 'Всего с НДС',
            dataIndex: 'all',
            key: 'all',
            render: (all) => {
                return (<span>{`${Number(all).toFixed(2)} €`}</span>)
            },
        },
        {
            title: 'Дата покупки',
            dataIndex: 'date',
            key: 'date',
            /*            render: (rate) => {return (<span>{`${Number(rate).toFixed(2)} €`}</span>)},*/
        },


    ];


    if (page === 1) {
        return (
            <>
                <Collapse defaultActiveKey={['1']}>
                    <Panel header="Общая информация" key="1">
                                              <OneFinanceInfo one_expenses={props.one_expenses}/>
                    </Panel>
                    <Panel header="Подробная информация" key="2">
                        <Button onClick={() => {
                            setPage(2)
                        }} style={{marginRight: '10px', marginLeft: '10px',}} type="primary">
                            Добавить пункт расходов
                        </Button>
                        <Button type="primary">
                            Сохранить изменения
                        </Button>
                        <Table
                            dataSource={state}
                            columns={columns}
                            scroll={{y: 400}}
                            style={{padding: 20}}
                            size={'small'}
                            pagination={false}
                        />
                    </Panel>
                </Collapse>
            </>
        )
    } else {
                return <AddOneExpenses close={() => {setPage(1)}} postOne={props.postOne}  articles_expenses={props.articles_expenses}/>
    }

}

export default OneFinanceExpensive