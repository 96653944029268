import React, {useState} from "react";
import {Button, Collapse, Input} from "antd";
import s from "./mobile.module.css";
import {MailOutlined, StarFilled, StarTwoTone} from '@ant-design/icons';
import Report from "./Report";
import UploadPhoto from "./UploadPhoto";
import Time from "./Time";
import moment from 'moment'
const {Panel} = Collapse;
const MobileReport = (props) => {


    let [timeStart, setTimeStart] = useState(props.oldReport !== null ? {status: true, time: moment(Number(props.oldReport.time_start)*1000)} : {status: false, time: 0});
    let [timeEnd, setTimeEnd] = useState(props.oldReport !== null ? {status: true, time: moment(Number(props.oldReport.time_end)*1000)} : {status: false, time: 0});
    let [open,setOpen] = useState(['0'])
    let [photo, setPhoto] = useState(props.oldReport !== null)
    let [work, setwork] = useState(props.oldReport !== null)
    let [nextReason, setNextReason] = useState({status: false, text: ''})
    let defaultArr = []
    if (props.oldReport){

        let worksArr = props.oldReport.works_id.split(',')
        let commentsArr = props.oldReport.comments_id.split('`')
        defaultArr = props.reportsTemp.map((el) => {
            let f = false;
            let index = 0;
            for (let i = 0, len = worksArr.length; i < len ; i++){
                if (Number(worksArr[i]) === Number(el.id)){
                    f = true;
                    index = i;
                }
            }

            return f ? {...el, checked: true, inputVal: commentsArr[index]} : {...el, checked: false}
        })


    } else {
        defaultArr = props.reportsTemp.map((el) => ({...el, checked: false}));
    }

    let [check, setCheck] = useState(defaultArr);

    let setData = (e) => {
        let t_start = (timeStart.time._d.getTime() / 1000).toFixed(0)
        let t_end = (timeEnd.time._d.getTime() / 1000).toFixed(0)
        post(props.elId,t_start, t_end, check)
    };


    const setObjectName = (id) => {
        let name = ''
        for (let i = 0, len = props.objects.length; i < len; i++) {
            if (Number(id) === Number(props.objects[i].id)) {
                name = `${props.objects[i].name} ${props.objects[i].long_name}`
            }
        }
        return name
    };


    const post = (id, timestart = 0, timeend = 0, data) => {


        let worksIdsArray = []
        let worksCommentsArray = []

        for (let i = 0, len = data.length; i < len; i++) {
            if (data[i].checked) {
                worksIdsArray.push(data[i].id)
                worksCommentsArray.push(data[i].inputVal ? data[i].inputVal : '')
            }
        }

        let plan_id = props.plans.id;
        let object_id = id;
        let time_start = timestart;
        let time_end = timeend;
        let time_download = (new Date().getTime() / 1000).toFixed(0);
        let time_plan = props.plans.used_date;
        let works_id = worksIdsArray.join(',');
        let comment_id = worksCommentsArray.join('`');
        let updateFlag = !!props.oldReport
        //console.log(plan_id, object_id, time_start, time_end, time_download, time_plan, works_id, comment_id,updateFlag,props.report_id)
        let body__time_start = moment(timestart*1000).format('LT');
        let body__time_end = moment(timeend*1000).format('LT');
        let body__time_duration = moment.utc(moment.duration(timeend*1000) - moment.duration(timestart*1000)).format('HH:mm')
        let body = ''
        if (Number(worksIdsArray[0]) === 0) {
            body = `Объект ${props.name} был пропущен, по причине "${comment_id}".` ;
        } else {
            body = !props.oldReport ? `Создан отчет по объекту ${props.name}. Работы проведены с ${body__time_start} до ${body__time_end}. Затраченное время: ${body__time_duration}` : `Изменен отчет по объекту ${props.name}.` ;

        }

        props.post(plan_id, object_id, time_start, time_end, time_download, time_plan, works_id, comment_id,updateFlag,props.report_id,body)
        props.setPage(1)
    }


    const nextObject = () => {
        setNextReason(prev => ({...prev, status: true}))
        collapse()
        post(props.elId,0, 0, [{id: 0, checked: true, inputVal: nextReason.text}])
        props.setPage(1)
    }

    const collapse = () => {
        setOpen([])
    }

    if (props.page === 2) {
        //Страница выбора рабочего времени
        return (
            <div className={s.new__plan__container__report}>
                <div className={s.new__plan__report__title}>{`Отчет по объекту ${props.name}`}</div>
                <Collapse accordion ghost={'true'} activeKey={open} onChange={(e) => setOpen([e])}>

























                    <Panel header={`Укажите время ${timeStart.status && timeEnd.status ? '(Указано)' : '(Не указано)'}`} key={'1'} onChange={() => setOpen(prev => [1])}>
                        <Time setPage={props.setPage}
                              id={props.elId}
                              reportsTemp={props.reportsTemp}
                              setTimeStart={setTimeStart}
                              setTimeEnd={setTimeEnd}
                              setData={setData}
                              timeStart={timeStart}
                              timeEnd={timeEnd}
                              close={()=>{collapse()}}

                        />
                    </Panel>

                    <Panel header={`Укажите выполненные работы ${work ? '(Указаны)' : '(Не указаны)'}`} key={'2'} onChange={() => setOpen(prev => [2])}>
                        <Report setPage={props.setPage}
                                id={props.elId}
                                reportsTemp={props.reportsTemp}
                                setData={setData}
                                check={check}
                                setCheck={setCheck}
                                setwork={()=>{setwork(true)}}
                                close={()=>{collapse()}}



                        />
                    </Panel>
                    <Panel header={`Загрузите фото ${photo ? '(Загружено)' : '(Не загружено)'}`} key={'3'} onChange={() => setOpen(prev => [3])}>
                        <UploadPhoto photo={props.photo ? props.photo : null} close={()=>{collapse()}} id={props.elId} setPhoto={()=>{setPhoto(true)}}/>
                    </Panel>


                    <Panel header={`Пропустить объект ${nextReason.status ? '(Пропущен!)' : ''}`} key={'4'} onChange={() => setOpen(prev => [4])}>
                        <div className={s.reportTitle}>
                            <Input className={s.comment_1} placeholder={'Причина пропуска'} value={nextReason.text} onChange={(e) => {setNextReason(prev => ({...prev, text: e.target.value}))}}/>
                            <Button onClick= {() => {nextObject()}} className={s.mobile_btn} type="primary">Пропустить</Button>

                        </div>
                    </Panel>
                </Collapse>
                <div className={s.uploadCont}>
                    {(timeStart.status === true && timeEnd.status === true && photo === true && work === true) ||  nextReason.status === true ?
                        <Button onClick={() => {
                            setData()
                        }} className={s.uploadbtn} icon={<MailOutlined/>}>Отправить</Button>
                        :''}
                    <Button onClick={() => {
                        props.close()
                    }} className={s.mobile_btn}>Назад</Button>

                </div>
            </div>

        )


    } else if (props.page === 4) {
        //Страница выбора выполненных работ
        return <Report setPage={props.setPage}
                       id={props.elId}
                       reportsTemp={props.reportsTemp}
                       setData={setData}
        />
    } else if (props.page === 5) {
        //Страница загрузки основных фотографий объекта
        return <UploadPhoto setPage={props.setPage} id={props.elId}/>
    }


}

export default MobileReport;