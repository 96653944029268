import './App.css';
import React from 'react';
import Auth from "./components/authorization/Auth";


const App = (props) => {
    return <Auth store={props.store}/>
};

export default App;
