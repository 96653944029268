import React, {Component, useState} from "react";
import ObjectsContainer from "./ObjectsContainer";
import Objects_item from "./Objects_item";
import {connect} from "react-redux";
import {
    getLinksThunkCreator, getObjectsThunkCreator,
    getReportsAllThunkCreator, getReportsByObjectIdThunkCreator, getReportsByTimeThunkCreator,
    getReportsTempThunkCreator, postObjectThunkCreator,
    SETPAGETOOBJAC
} from "../../redux/reducers/objectsReducer";
import ObjectReports from "./ObjectReports";
import moment from 'moment';


class ObjectsGeneral extends Component {

    componentDidMount() {
        console.log(`componentDidMount`)
        this.props.getReportsTempThunkCreator();
        this.props.SETPAGETOOBJAC(0)
        this.props.getReportsAllThunkCreator()
        this.props.getObjectsThunkCreator();
    }


    state = {
        currentElem: {}
    };




    getLinks = (object_id, start, end) => {
        this.props.getLinksThunkCreator(object_id, start, end)
    }

    setCurrentElem = (id) => {
        let result = this.props.objects.filter(el => Number(el.id) === Number(id))
        return result[0]
    };

    setPage = (page, id) => {
        if (id) {
            this.setState({currentElem: this.setCurrentElem(id)})
        }
        this.props.SETPAGETOOBJAC(page)
    };






    render() {


        if (this.props.mainPage === '41') {
            return (
                <div>
                    {this.props.page === 0 && <ObjectsContainer
                        setPage={this.setPage}
                        post={this.props.postObjectThunkCreator}
                    />}
                    {this.props.page === 1 && <Objects_item
                        setPage={this.setPage}
                        elem={this.state.currentElem}
                        getLinks={this.getLinks}
                        getReports={this.props.getReportsByObjectIdThunkCreator}

                    />}

                </div>
            )
        } else if (this.props.mainPage === '42' && (this.props.objects && this.props.objects.length > 1)) {

            return <ObjectReports
                data={this.props.allReports}
                objects={this.props.objects}
                getLinks={this.getLinks}
                template={this.props.reports.template}
                links={this.props.links}
                getReports={this.props.getReportsByTimeThunkCreator}
            />

        } else {
            return <div>!</div>
        }


    }
}

const mapStateToProps = (state) => {
    return {
        page: state.objectsRDCR.page,
        objects: state.objectsRDCR.objects,
        reports: state.objectsRDCR.reports,
        allReports: state.objectsRDCR.allReports,
        links: state.objectsRDCR.links,

    }
};


export default connect(mapStateToProps, {
    SETPAGETOOBJAC,
    getReportsTempThunkCreator,
    getLinksThunkCreator,
    getReportsAllThunkCreator,
    getObjectsThunkCreator,
    getReportsByTimeThunkCreator,
    getReportsByObjectIdThunkCreator,
    postObjectThunkCreator
})(ObjectsGeneral);