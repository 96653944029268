import React, {useState} from 'react';
import {Card} from 'antd';
import Contacts from "./items/Contacts";
import Documents from "./items/Documents";
import General from "./items/General";
import Expirience from "./items/Expirience";
import Roles from "./items/Roles";


export const Anketa = (props) => {
    const [activeTabKey1, setActiveTabKey1] = useState('tab1');
    const tabList = [
        {
            key: 'tab1',
            tab: 'Основное',
        },
        {
            key: 'tab2',
            tab: 'Документы',
        },
        {
            key: 'tab3',
            tab: 'Навыки',
        },
        {
            key: 'tab4',
            tab: 'Контакты',
        },
        {
            key: 'tab5',
            tab: 'Роли',
        },
    ];

    const onTab1Change = key => {
        setActiveTabKey1(key);
    };

        return (

            <Card

                style={{width: '100%', padding: 20}}
                title={props.fn + ' ' + props.ln}
                extra={<a onClick={()=>{props.setPage(0)}}>Выход</a>}
                tabList={tabList}
                activeTabKey={activeTabKey1}
                onTabChange={key => {
                    onTab1Change(key);
                }}
            >
                {activeTabKey1 === 'tab1' ? <General data={props.data}/> : ''}
                {activeTabKey1 === 'tab2' ? <Documents data={props.data}/> : ''}
                {activeTabKey1 === 'tab3' ? <Expirience data={props.data}/> : ''}
                {activeTabKey1 === 'tab4' ? <Contacts data={props.data}/> : ''}
                {activeTabKey1 === 'tab5' ? <Roles roles={props.rules} data={props.data}/> : ''}
            </Card>

        );


};