import {Alert, Input, Modal, Tag} from "antd";
import React, {useState} from "react";
import Title from "antd/lib/typography/Title";
import {Checkbox, Row, Col} from 'antd';
import s from '../styles/planning.module.css'

const PlanStaffChange = (props) => {
    console.log('render component PlanStaffChange');

    let [changedStaff, setChangedStaff] = useState([]);

    const onChange = (checkedValues) => {
        setChangedStaff(checkedValues)
    };
    const post = (e) => {
        props.post(changedStaff);
        props.close(0)
    };
    const cancelEvent = (e) => {
        props.close(0)
    };

    const staff = props.staff.map((el) => {

        let n = el.role.split(',');
        let role = false;
        for (let i = 0, len = n.length; i < len; i++) {
            if (Number(n[i]) === 3) {
                role = true
            }
        }
        if (role) {
                return (
                    <Col span={16}>
                        <Checkbox className={s.staffChange__span} disabled={el.disabled} value={el.id}>{`${el.firstname} ${el.lastname}`}</Checkbox>
                    </Col>
                )
        } else {
            return ''
        }


    }).filter((el) => {
        return el !== ''
    });

    return (
        <Modal
            title={<Title level={5}>{`Добавление / изменение сотрудников | ${props.title}`}</Title>}
            centered
            visible={true}
            onOk={() => post()}
            onCancel={() => cancelEvent()}
            okText="Сохранить"
            cancelText="Отмена"

        >


            <Checkbox.Group style={{width: '100%', userSelect: 'none'}} onChange={onChange}>
                <Row>
                    {staff}
                </Row>
            </Checkbox.Group>

            {/*            { alert ? <Alert message="Нельзя переместить больше, чем есть..." type="error"  showIcon/> : ''}
            { isNull ? <Alert message="Укажите корректное количество..." type="error"  showIcon/> : ''}
            { transfer ? <Alert message="Перемещение выполнено!" type="success" showIcon/> : ''}*/}
        </Modal>
    )

}


export default PlanStaffChange