import React, {useState} from "react";
import {Input, Tag, Typography, Modal, Radio, Select, Alert} from 'antd';
import './styles/store.css'

const {Option} = Select;
const {Title} = Typography;


const ChangeWin = (props) => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(props.element.name);
    const [brand, setBrand] = useState(props.element.brand);
    const [cost, setCost] = useState(Number(props.element.cost));
    const [description, setDescriptiom] = useState(props.element.description);
    const [packName, setPackName] = useState(props.element.package_name);
    const [pack, setPack] = useState(props.element.package);
    const [dateAvalible, setDateAvalible] = useState(props.element.date_avalible);
    const [budva_store, setbudva_store] = useState(Number(props.element.store_1));
    const [CatId, setCatId] = useState({
        cat: (Number(props.element.maincatId)),
        sub: Number(props.element.subCatId)
    });


    const categoryArray = () => {
        return props.category.mainCategory.map((el) => {
            return <Option key={Number(el.id)} value={Number(el.id)}>{el.name}</Option>
        })
    };
    const subcategoryArray = (id) => {
        return props.category.subCategory.map((el) => {
            return Number(el.parent_id) === id ?
                <Option key={Number(el.id)} value={Number(el.id)}>{el.name}</Option> : ''
        }).filter((el) => {
            return el !== ''
        })

    };
    const changeCategory = (e, option) => {
        setCatId({cat: Number(option.key), sub: Number(subcategoryArray(Number(option.key))[0].props.value)})

    };

    const resultElem = () => {
        return <>
            <div className={'label_3'}>Наименование:</div>
            <Input className={'inpChange'} value={name} onChange={(input) => {
                setName(input.target.value)
            }} placeholder="Наименование"/>
            <div className={'label_3'}>Производитель:</div>
            <Input className={'inpChange'} value={brand} onChange={(input) => {
                setBrand(input.target.value)
            }} placeholder="Производитель"/>
            <div className={'label_3'}>Описание:</div>
            <Input className={'inpChange'} value={description} onChange={(input) => {
                setDescriptiom(input.target.value)
            }} placeholder="Описание"/>
            <div className={'label_3'}>Единица измерения:</div>
            <Input className={'inpChange'} value={packName} onChange={(input) => {
                setPackName(input.target.value)
            }} placeholder="Единица измерения"/>
            <div className={'label_3'}>Количество в упаковке:</div>
            <Input min={0} className={'inpChange'} value={pack} onChange={(input) => {
                setPack(input.target.value)
            }} placeholder="Количество в упаковке"/>
            <div className={'label_3'}>Кол-во Склад Будва:</div>
            <Input min={0} type={'number'} step={'any'} className={'inpChange'} value={budva_store} onChange={(input) => {
                setbudva_store(input.target.value)
            }} placeholder="Кол-во Склад Будва"/>
            <div className={'label_3'}>Годен до:</div>
            <Input  className={'inpChange'} value={dateAvalible} onChange={(input) => {
                setDateAvalible(input.target.value)
            }} placeholder="Годен до"/>
            <div className={'label_3'}>Стоимость:</div>
            <Input className={'inpChange'} value={cost} onChange={(input) => {
                setCost(input.target.value)
            }} placeholder="Стоимость"/>
        </>

    };


    const post = () => {
        props.setModal()
        setLoading(true)
        let postId = Number(props.element.id);
        let postSubCat = CatId.sub;
        let postBrand = brand;
        let postName = name;
        let postCost = cost === '' ? 0 : cost;
        let postDescription = description;
        let postPackage_name = packName;
        let postDate_avalible = dateAvalible;
        let postPack = Number(pack);
        let postCount = budva_store === '' ? 0 : budva_store;

        //console.log(postId,postSubCat,postBrand,postName,postCost,postDescription,postPackage_name,postDate_avalible,postPack,postCount)
        props.post(postId, postSubCat, postBrand, postName, postCost, postDescription, postPackage_name, postDate_avalible, postPack, postCount)


    }
    const cancelEvent = () => {
        props.setModal();
    }


    const changeSubCategory = (e) => {
        setCatId({...CatId,sub: e})
    }
    const SelectSubCat = (props) => {
        return (
            <>
                <div className={'label_3'}>Подкатегория:</div>
                <Select
                    style={{width: 200}}
                    placeholder="Выберите категорию"
                    optionFilterProp="children"
                    defaultValue={props.defaultValue}
                    onChange={(input, option) => {
                        changeSubCategory(input, option)
                    }}
                >
                    {props.arr}
                </Select>
            </>
        )
    }


    const SelectCat = (props) => {
        return (<>
                <div className={'label_3'}>Категория:</div>
                <Select
                    style={{width: 200}}
                    placeholder="Выберите категорию"
                    optionFilterProp="children"
                    defaultValue={props.defaultValue}
                    onChange={(input, option) => {
                        changeCategory(input, option)
                    }}
                >
                    {props.arr}
                </Select>
            </>
        )
    }


    return (

        <>                <Modal
            title={<Title level={5}>{`${props.element.catname} | ${props.element.name}`}</Title>}
            centered
            visible={true}
            onOk={() => post()}
            onCancel={() => cancelEvent()}
            okText="Изменить"
            cancelText="Отмена"
            confirmLoading={loading}

        >
            {resultElem()}
            <SelectCat defaultValue={CatId.cat} arr={categoryArray()}/>
            <SelectSubCat defaultValue={CatId.sub} arr={subcategoryArray(CatId.cat)}/>

        </Modal>


        </>
    )


}


export default ChangeWin;