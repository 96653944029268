import React from "react";
import s from "./mobile.module.css";
import {Button, TimePicker} from "antd";


const Time = (props) => {

/*
    const setPageValidator = () => {
        if (timeStart && timeEnd){
            props.setPage(4)
        } else {
            alert('Укажите время начала и окончания работ на объекте!')
        }
    }*/

    const setTimeStart = (e) => {

        props.setTimeStart({status: true, time: e})
    }

    const setTimeEnd = (e) => {

        props.setTimeEnd({status: true, time: e})

    }

    return (
        <div className={s.time_cont}>
            <div className={s.reportTitle}>Время начала работ на объекте</div>
            <div className={s.time}>
                <TimePicker placeholder={'Укажите время'} defaultValue={props.timeStart.time} size={'large'} className={s.timePicker} format={'HH:mm'} onChange={(e) => {
                    setTimeStart(e)
                }}/>
            </div>
            <div className={s.reportTitle}>Время окончания работ на объекте</div>
            <div className={s.time}>
                <TimePicker placeholder={'Укажите время'} defaultValue={props.timeEnd.time} size={'large'} className={s.timePicker} format={'HH:mm'} onChange={(e) => {
                    setTimeEnd(e)
                }}/>
            </div>



            <Button
                type="primary"
                onClick={props.close}
                style={{ margin: '16px auto', display: 'block' }}
            >
     Ок
            </Button>
        </div>


    )
}


export default Time