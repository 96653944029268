import s from "./mobile.module.css";
import React from "react";

import './mobile.css'
import UploadManual from "./UploadManual";

const UploadPhoto = (props) => {

    return (
        <div>
             <div className={s.reportTitle_new}>
                ЗАГРУЗИТЕ ФОТОГРАФИИ ОБЪЕКТА
            </div>
            <UploadManual photo={props.photo} id={props.id} close={props.close} setPhoto={props.setPhoto}/>

        </div>
    )
};


export default UploadPhoto;