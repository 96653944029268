import {Button, ConfigProvider, DatePicker, Modal, Typography, Checkbox} from "antd";
import locale from "antd/lib/locale/ru_RU";
import SelectField from "./SelectForm";
import s from "../styles/analitics.module.css";
import React, {useEffect, useState} from "react";
import {ExportReactCSV} from "../AnaliticsMain";



const { RangePicker } = DatePicker;
const {Title} = Typography;
const ModalForm = (props) => {

/*    const plainOptions = props.checkboxData.map(el => el.name);
    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);*/
    let data = props.data
    const rpchange = (e) => {
        props.rpChange(e)

    }
    const changeObj = (id) => {
        props.buttonOnClick(id)
        props.onChangeSel(id)
    }
/*    const onChange = (list) => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < plainOptions.length);
        setCheckAll(list.length === plainOptions.length);
        data.filter(el => {
            console.log(el)
            console.log(checkedList)
            let flag = false
            for (let i = 0, l = checkedList.length; i < l; i++){
                console.log(el.cat.indexOf(checkedList[i]) !== -1)
                if (el.cat.indexOf(checkedList[i]) !== -1){
                    flag = true

                }
            }
            return flag ? el : ''
        }).filter(elem => elem !== '')
        console.log(data)
    };
    useEffect(()=>{
        setCheckedList(plainOptions)
    },[props.checkboxData])
    console.log(plainOptions)*/



    let load = !!(props.data.length <= 0 || props.loading)

    return (
        <Modal
            title={<Title level={5}>{props.title}</Title>}
            centered
            visible={props.visible}
            onOk={() => props.cancel()}
            onCancel={() => props.cancel()}
            okText={props.okText}
            cancelText={props.cancelText}
        >
            {props.rangepicker &&
            <ConfigProvider locale={locale}>
                <RangePicker onCalendarChange={val => rpchange(val)}  />
            </ConfigProvider>
            }

            {props.selectVisible && <SelectField
                options={props.options}
                onChange={changeObj}
                placeholder={props.placeholderSel}
            />}
{/*
            {props.selectVisible ? '' :
            <Checkbox.Group options={plainOptions}  value={checkedList} onChange={onChange} />}*/}


{/*            <Button loading={props.loading} className={s.button_down} onClick={()=>props.buttonOnClick()}>
                Запросить данные с сервера
            </Button>*/}
            <ExportReactCSV load={load} data={props.data} headers={props.headers} separator={","} fileName={props.filename} />
        </Modal>
    )
}

export default ModalForm