import React, {useState} from "react";
import { Table, Switch, Button, Collapse, Space } from 'antd';
import AddStaffFinanceExpensive from "./AddStaffFinanceExpensive";
import StaffFinanceInfo from "./StaffFinanceInfo";
const { Panel } = Collapse;
const StaffFinanceExpensive = (props) => {

    let [page, setPage] = useState(1)

    console.log(props)
    let summRate = 0, summCash = 0, summCashless = 0;

    const setTax = (count) => {
        return Number(count) * 0.68;
    }

    const defaultData = props.salary.map(el => {
        summRate += Number(el.enable) === 1 ? Number(el.rate) : 0
        summCash += Number(el.enable) === 1 ? Number(el.cash) : 0
        summCashless += Number(el.enable) === 1 ? Number(el.cashless) : 0
        return {key: el.id, personal_id: el.personal_id, name: el.full_name, post: el.post, enable: el.enable, rate: el.rate, cash: el.cash, cashless: el.cashless, tax: setTax(el.cashless)}
    })
    defaultData.push({key: 0, personal_id: 0, name: 'ИТОГО:', post: '', enable: 0, rate: summRate, cash: summCash, cashless: summCashless, tax: setTax(summCashless)})






/*    const onChangeEnable = (e) => {
        summRate = 0;
        summCash = 0;
        summCashless = 0;

        let temp = [...state]
        for (let i = 0, len = state.length; i < len; i++){
            if (Number(e) === Number(temp[i].personal_id)){
                console.log(Number(temp[i].enable) === 0)
                temp[i].enable = Number(temp[i].enable) === 0 ? 1 : 0
            }
        }


        for (let i = 0, len = state.length; i < len; i++){
            summRate += Number(state[i].enable) === 1 ? Number(state[i].rate) : 0
            summCash += Number(state[i].enable) === 1 ? Number(state[i].cash) : 0
            summCashless += Number(state[i].enable) === 1 ? Number(state[i].cashless) : 0
        }
        temp[temp.length - 1] = {key: 0, personal_id: 0, name: 'ИТОГО:', post: '', enable: 0, rate: summRate, cash: summCash, cashless: summCashless, tax: setTax(summCashless)}
        setState(temp)

    }*/


    const columns = [
        {
            title: 'ФИО',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Должность',
            dataIndex: 'post',
            key: 'post',
        },
        {
            title: 'Оклад',
            dataIndex: 'rate',
            key: 'rate',
            render: (rate) => {return (<span>{`${Number(rate).toFixed(2)} €`}</span>)},
        },
        {
            title: 'Наличные',
            dataIndex: 'cash',
            key: 'cash',
            render: (cash) => {return (<span>{`${Number(cash).toFixed(2)} €`}</span>)},
        },
        {
            title: 'Безнал',
            dataIndex: 'cashless',
            key: 'cashless',
            render: (cashless) => {return (<span>{`${Number(cashless).toFixed(2)} €`}</span>)},
        },
        {
            title: 'Налог',
            dataIndex: 'tax',
            key: 'tax',
            render: (tax) => {return (<span>{`${Number(tax).toFixed(2)} €`}</span>)},
        },
/*        {
            title: 'Выплата активна',
            dataIndex: 'enable',
            key: 'enable',
            render: (enable, elem) => {
                return Number(elem.personal_id !== 0) ?  <Switch size="small" onChange={()=>{onChangeEnable(elem.personal_id)}} checked={Number(enable) === 1} /> : '';
            },
        },*/




    ];



    if (page === 1){
        return (
            <>
                <Collapse defaultActiveKey={['1']}>
                    <Panel header="Общая информация" key="1">
                   <StaffFinanceInfo salary={props.salary}/>
                    </Panel>
                    <Panel header="Подробная информация" key="2">
                        <Button onClick={()=>{setPage(2)}} style={{marginRight: '10px', marginLeft: '10px',}} type="primary" >
                            Добавить сотрудника
                        </Button>
{/*                        <Button type="primary" >
                            Сохранить изменения
                        </Button>*/}
                        <Table
                            dataSource={defaultData}
                            columns={columns}
                            scroll={{ y: 400 }}
                            style={{ padding: 20 }}
                            size={'small'}
                            pagination={false}
                        />
                    </Panel>
                </Collapse>
            </>
        )
    } else {
        return <AddStaffFinanceExpensive close={() => {setPage(1)}} postSalary={props.postSalary} staff={props.staff} salary={props.salary}/>
    }

}

export default StaffFinanceExpensive