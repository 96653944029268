import {Card, Avatar, List, Modal, Input} from 'antd';
import {
    EditOutlined,
} from '@ant-design/icons';
import React, {useState} from "react";
import s from '../styles/planning.module.css'
import Title from "antd/lib/typography/Title";
const {Meta} = Card;
const {TextArea} = Input;
const ModalWInCom = (props) => {



    let [com, setCom] = useState(props.comment)
    console.log(com)
    return (
        <Modal
            title={<Title level={5}>{`Добавление комментария | ${props.name}`}</Title>}
            centered
            visible={true}
            onOk={() => props.set(com)}
            onCancel={() => props.cancel()}
            okText="Сохранить"
            cancelText="Отмена"
        >

                <div className={s.comment__title}>{props.name}</div>
                <TextArea
                    className={'text'}
                    value={com}
                    onChange={(e) => {
                        setCom(e.currentTarget.value)
                    }}
                    rows={4}
                    style={{fontWeight: 400}}
                    size={'large'}
                />

        </Modal>
    )
}





const PlanCommentsCard = (props) => {


    let [currentObj, setCurrentObj] = useState({id: 0, name: '', comment: ''})



    const editcomments = (item) => {
        let obj = {...currentObj, id: Number(item.id), name: item.name, comment: item.comment}
        setCurrentObj(obj)
    }

    const editTeamcomments = (item) => {
       // let obj = {...currentObj, id: Number(item.id), name: item.name, comment: item.comment}
       // setCurrentObj(obj)
    }

    const editComment = (val) => {
        let obj = {...currentObj, comment: val}
        setCurrentObj(obj)
        props.post(val,props.id,currentObj.id)
        obj = {...currentObj, id: 0}
        setCurrentObj(obj)
    }

    const cancel = () => {
        let obj = {...currentObj, id: 0}
        setCurrentObj(obj)
    }

    let commentsArray = props.objects.map(el => el);
    commentsArray.push({id:99999,name: 'Комментарий для команды', comment: props.comment})

    return (
        <>
        <Card
            style={               {
                minWidth: 230,
                margin: '10px auto',
                padding: 10,
                display: 'block',
                float: 'left',
                textAlign: 'left',
                height: 212
            }}
        > <Meta style={{textAlign: 'center'}}
                title={'Комментарии'}
                description={
                    <List
                        style={{textAlign: 'left', height: 150, overflowY: 'scroll'}}
                        itemLayout="horizontal"
                        dataSource={commentsArray}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                style={{fontWeight: 400}}
                                description={`${item.name}:  ${item.comment}`}
                                />
                                <EditOutlined onClick={()=>{editcomments(item)}} style={{cursor: 'pointer'}} />
                            </List.Item>
                        )}
                    />
                }

        />

        </Card>




    {currentObj.id !== 0 && <ModalWInCom
        id={currentObj.id}
        name={currentObj.name}
        comment={currentObj.comment}
        set={editComment}
        cancel={cancel}

    />}
        </>
    )

}

export default PlanCommentsCard

