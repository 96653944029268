



import {dataAPI, objectAPI, reportsAPI} from "../../API/API";
import {GETSTAFFAC} from "./staffReducer";


const GETOBJ = 'GET-OBJ-OBJ';
const SETPAGETOOBJ = 'SET-PAGE-OBJ';
const SETREPORTOBJ = 'GET-REPORT-OBJ';
const SETREPORTEMPTOBJ = 'GET-REPORTTEMP-OBJ';
const SETFILTEROBJ = 'GET-FILTER-OBJ';
const GETLINKSOBJ = 'GET-LINKS-OBJ';
const SETALLREPORTEMPTOBJ = 'GET-ALLREPORTTEMP-OBJ';



let initialState = {
    objects: {},
    page: 0,
    reports: {
        reports: [],
        template: []
    },
    filter: {start: 0, end: 0},
    links: [],
    allReports: []
};


const ObjectsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GETOBJ: {
            return {
                ...state,
                objects: action.obj
            }
        }
        case GETLINKSOBJ: {
            return {
                ...state,
                links: action.links
            }
        }
        case SETFILTEROBJ: {
            return {
                ...state,
                filter: action.filter
            }
        }
        case SETREPORTOBJ: {
            return {
                ...state,
                reports: {template: state.reports.template, reports: action.reports}
            }
        }
        case SETALLREPORTEMPTOBJ: {
            return {
                ...state,
                allReports: action.allReports
            }
        }
        case SETREPORTEMPTOBJ: {
            return {
                ...state,
                reports: {reports: state.reports.reports, template: action.template}
            }
        }
        case SETPAGETOOBJ: {
            return {
                ...state,
                page: action.page
            }
        }

        default:
            return state;
    }

};

export let GETOBJAC = (obj) => ({type: GETOBJ, obj});
export let SETREPORTOBJAC = (reports) => ({type: SETREPORTOBJ, reports});
export let SETPAGETOOBJAC = (page) => ({type: SETPAGETOOBJ, page});
export let SETREPORTEMPTOBJAC = (template) => ({type: SETREPORTEMPTOBJ, template});
export let SETFILTEROBJAC = (filter) => ({type: SETFILTEROBJ, filter});
export let GETLINKSOBJAC = (links) => ({type: GETLINKSOBJ, links});
export let SETALLREPORTEMPTOBJAC = (allReports) => ({type: SETALLREPORTEMPTOBJ, allReports});





export const getObjectsThunkCreator = () => async (dispatch) => {
    let response = await objectAPI.getObjects();
    if (response) {
        dispatch(GETOBJAC(response.data.objects));
    }
};

export const getReportsByObjectIdThunkCreator = (id,start = 0,end = 0) => async (dispatch) => {
    let response = await reportsAPI.getReportsByObjectId(id,start,end);
    if (response) {
        dispatch(SETREPORTOBJAC(response.data.reports));
    }
};
export const getReportsAllThunkCreator = () => async (dispatch) => {
    let response = await reportsAPI.getReportsAll();
    if (response) {
        dispatch(SETALLREPORTEMPTOBJAC(response.data.reports));
    }
};
export const getReportsByTimeThunkCreator = (start, end) => async (dispatch) => {
    let response = await reportsAPI.getReportByTime(start,end);
    if (response) {
        dispatch(SETALLREPORTEMPTOBJAC(response.data.reports));
    }
};

export const getLinksThunkCreator = (object_id, start, end) => async (dispatch) => {
    let response = await reportsAPI.getLinks(object_id, start, end);
    if (response) {
        dispatch(GETLINKSOBJAC(response.data.links));
    }
};
export const getReportsTempThunkCreator = () => async (dispatch) => {
    let response = await reportsAPI.getReportsTemp();
        if (response) {
            dispatch(SETREPORTEMPTOBJAC(response.data.template));
        }
    };

export const postObjectThunkCreator = (state, id) => async (dispatch) => {

    let response = await objectAPI.postObject(state, id);
    if (response) {
        response = await objectAPI.getObjects();
        if (response) {
            dispatch(GETOBJAC(response.data.objects));
        }
    }

};




export default ObjectsReducer;




