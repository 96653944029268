import React, {useState} from "react";
import s from "../staff/styles/staff.module.css";
import {Button, ConfigProvider, DatePicker, Divider, Form, Input, Select, Steps, Switch} from "antd";
import locale from "antd/lib/locale/ru_RU";
import moment from "moment";

const AddWork = (props) => {

    let defaultState = Number(props.page) === 2 ? props.state : {
        id: 0,
        name: '',
        type: 1,
        input: 0,
        input_name: '',
        cat: '',
        count: 0,
        count_name: ''
    }

    let [state, setState] = useState(defaultState)
    const change = (name, value) => {
        let tempstate = {...state}
        tempstate[name] = value
        setState(tempstate)
    }
    const handleChange = (value) => {
        let tempstate = {...state}
        tempstate['type'] = value
        setState(tempstate)
    }
    const onChangeInput = (checked) => {
        let tempstate = {...state}
        tempstate.input = checked ? 1 : 0
        setState(tempstate)
    }
    const post = () => {
        let update = Number(props.page) === 1 ? 0 : 1;
        props.post(state, update)
        props.close();
    }


    return (
        <div className={s.addStaffCont}>

            <div className={s.exit}><a onClick={()=>{props.close()}}>Выход</a></div>
            <Form
                labelCol={{
                    span: 4,
                }}
                wrapperCol={{
                    span: 14,
                }}
                layout="horizontal"
                size={'small'}
            >
                <Divider plain>Заполните данные</Divider>
                <Form.Item label="Наименование работы">
                    <Input value={state.name} onChange={(val)=>{change('name', val.target.value)}}/>
                </Form.Item>
                <Form.Item label="Тип работы">
                    <Select defaultValue={state.type} onChange={handleChange}>
                        <Select.Option value={1}>Основные работы</Select.Option>
                        <Select.Option value="2">Химия и удобрения</Select.Option>
                        <Select.Option value="3">Полив</Select.Option>
                        <Select.Option value="4">Автополив</Select.Option>
                        <Select.Option value="5">Остальное</Select.Option>
                        <Select.Option value="6">Финансы</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Поле для комментария" valuePropName="checked">
                    <Switch checked={Number(state.input) === 1} onChange={onChangeInput}/>
                </Form.Item>
                {Number(state.input) === 1 ?
                <Form.Item label="Название поля">
                    <Input value={state.input_name} onChange={(val)=>{change('input_name', val.target.value)}}/>
                </Form.Item> : ''}




                <Button style={{margin: '0 auto', display: 'block'}} onClick={()=>{post()}}>{Number(props.page) !== 2 ? 'Добавить работу' : 'Обновить данные'}</Button>
            </Form></div>
    )
}
export default AddWork