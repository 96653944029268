import React from 'react'
import s from '../styles/currentPlan.module.css'
import logo from '../../../assets/logo.png'
import {Divider} from "antd";
import {CloseSquareOutlined} from '@ant-design/icons';
const CurrentPlan = (props) => {

debugger


    console.log(props.title)

    console.log(props.content)
    const checkName = (id) => {
        return props.staff.map(el => Number(el.id) === Number(id) ? `${el.firstname} ${el.lastname}` : '').filter(el => el !== '')
    };
    const checkObject = (id) => {
        let obj = {};
        for (let i = 0, len = props.objects.length; i < len; i++) {
            if (Number(props.objects[i].id) === Number(id)) {
                obj = {
                    id: Number(props.objects[i].id),
                    name: props.objects[i].name,
                    type: Number(props.objects[i].type),
                    district: props.objects[i].district,
                    avalible_day: props.objects[i].avalible_day,
                    long_name: props.objects[i].long_name,
                    access: props.objects[i].access,
                    contacts: props.objects[i].contacts,
                    contact_name: props.objects[i].contact_name,
                    description: props.objects[i].description,
                }
            }
        }
        return obj
    }

    const access = (id) => {
        switch (id) {
            case 1: return 'Открытый'
            case 2: return 'Ключ у нас'
            case 3: return 'Ключ на объекте'
            case 4: return 'Через контактное лицо'
            default: return 'Нет данных'

        }
    }
    let defArr = props.content || []
    let render = defArr.map((el, index) => {
        let staff = el.staff.map(elem => {
            return (
                <li><div className={s.team_props}>{elem.name}</div></li>
            )
        });
        let objects = el.objects.map(elem => {
            let obj = checkObject(elem.id)
            return (<div className={s.object_container}>
                    <div className={s.team_props}>{`${obj.name}`}{obj.long_name !== '' && ` | ${obj.long_name}`}</div>
                    <div className={s.team_props}>{`Доступ на объект: ${access(Number(obj.access))}`}</div>
                    <div className={s.team_props}>{obj.contact_name !== '' && `Контактное лицо: ${obj.contact_name}`}</div>
                    <div className={s.team_props}>{obj.contacts !== '' && `Контактный номер: ${obj.contacts}`}</div>
                    <div className={s.team_props}>{obj.description !== '' && `Правила посещения: ${obj.description}`}</div>
                    <div className={s.team_props}>{elem.comment !== '' && `Комментарий: ${elem.comment}`}</div>
                </div>
            )
        });

        return (
            <div className={s.team_container}>

                <div className={s.team_title}>{`Команда ${index + 1} | ${el.carName}`}</div>
                <div className={s.flex_container}>
                <div className={s.sub_container}>
                    <Divider plain={true} >Основные данные</Divider>
                    <div className={s.team_props}>{`Водитель: `}<b>{checkName(el.driver)}</b></div>
                    <div className={s.team_props}>{`Старший: `}<b>{checkName(el.lead)}</b></div>
                </div>
                <div className={s.sub_container}>
                    <Divider plain={true} >Сотрудники</Divider>
                    <ul>
                        {staff}
                    </ul>

                </div>
                </div>
                <Divider plain={true} >Объекты</Divider>
                {objects}

                <Divider plain={true} >Комментарий для команды</Divider>
                {el.comment}

            </div>

        )
    })


    return (
        <div className={props.old ? s.main_container_1 : s.main_container}>
            <div onClick={() => {
                props.close()
            }}><CloseSquareOutlined height={20} style={{
                color: 'red',
                display: 'block',
                float: 'right',
                marginRight: '20px',
                marginTop: '10px',
                fontSize: '20px',
                userSelect: 'none',
                cursor: 'pointer'


            }}/></div>
            <div className={s.logo}>
                <img src={logo} alt="logo"/>
            </div>
            <div className={s.planTitle}>{props.title}</div>
            <Divider plain={true} orientation="left"/>

            <div className={s.main__comment}>Общий комментарий: <span style={{color: 'red'}}>{props.maincomment}</span></div>


            {render}

        </div>
    )
}

export default CurrentPlan
