



import {dataAPI, objectAPI, plansAPI, reportsAPI, whatsappApi} from "../../API/API";
import {GETLINKSOBJAC, GETOBJAC, SETREPORTOBJAC} from "./objectsReducer";
import {GETSTAFFAC} from "./staffReducer";
import moment from 'moment'

const GETMOBILEPLAN = 'GET-MOBILE-PLAN';

const MOBILELOADER = 'GET-MOBILE-LOADER';

const GETMOBILEOBJECTS = 'GET-MOBILE-OBJECTS';

const GETREPORTSTEMPLATE = 'GET-REPORTS-TEMPLATE';

const GETSTAFFMOBILE = 'GET-STAFF-MOBILE';

const GETOLDREPORTSMOBILE = 'GET-OLDREPORTS-MOBILE';

const GETREPORTSMOBILE = 'GET-REPORTS-MOBILE';

const GETPHOTOMOBILE = 'GET-PHOTO-MOBILE';

let initialState = {
    plans: {},
    objects: {},
    loading: true,
    reportsTemp: {},
    staff: [],
    reports: [],
    photoLinks: [],
    oldreports: []
};


const MobileReducer = (state = initialState, action) => {
    switch (action.type) {
        case GETMOBILEPLAN: {
            return {
                ...state,
                plans: action.plans
            }
        }
        case GETPHOTOMOBILE: {
            return {
                ...state,
                photoLinks: action.photoLinks
            }
        }
        case GETOLDREPORTSMOBILE: {
            return {
                ...state,
                oldreports: action.oldreports
            }
        }
        case GETREPORTSMOBILE: {
            return {
                ...state,
                reports: action.reports
            }
        }
        case GETSTAFFMOBILE: {
            return {
                ...state,
                staff: action.staff
            }
        }
        case GETREPORTSTEMPLATE: {
            return {
                ...state,
                reportsTemp: action.reportsTemp
            }
        }
        case GETMOBILEOBJECTS: {
            return {
                ...state,
                objects: action.objects
            }
        }
        case MOBILELOADER: {
            return {
                ...state,
                loading: action.loading
            }
        }
        default:
            return state;
    }

};

export let GETMOBILEPLANAC = (plans) => ({type: GETMOBILEPLAN, plans});
let GETMOBILEOBJECTSAC = (objects) => ({type: GETMOBILEOBJECTS, objects});
let GETREPORTSTEMPLATEAC = (reportsTemp) => ({type: GETREPORTSTEMPLATE, reportsTemp});
let SETLOADINGAC = (loading) => ({type: MOBILELOADER, loading});
let GETSTAFFMOBILEAC = (staff) => ({type: GETSTAFFMOBILE, staff});
let GETREPORTSMOBILEAC = (reports) => ({type: GETREPORTSMOBILE, reports});
let GETPHOTOMOBILEAC = (photoLinks) => ({type: GETPHOTOMOBILE, photoLinks});
let GETOLDREPORTSMOBILEAC = (oldreports) => ({type: GETOLDREPORTSMOBILE, oldreports});






let end = moment().endOf('day').format('X')
let start = moment().startOf('day').format('X')

export const getMobilePlanThunkCreator = () => async (dispatch) => {
    let response = await plansAPI.getCurrentPlans();
        if (response) {
        dispatch(GETMOBILEPLANAC(response.data.plans));
            response = await plansAPI.getObjects();
            if (response) {
                dispatch(GETMOBILEOBJECTSAC(response.data.objects));
                response = await plansAPI.getReportsTemp();
                if (response) {
                    dispatch(GETREPORTSTEMPLATEAC(response.data.template));
                    response = await dataAPI.getStaff();
                    if (response) {
                        dispatch(GETSTAFFMOBILEAC(response.data.staff));
                        response = await reportsAPI.getReportByTime(start,end);
                        if (response) {
                            dispatch(GETREPORTSMOBILEAC(response.data.reports));
                            response = await reportsAPI.getLinksByTime(start, end);
                            if (response) {
                                dispatch(GETPHOTOMOBILEAC(response.data.links));
                                setTimeout(() => {
                                    dispatch(SETLOADINGAC(false));
                                }, 4000)
                            }

                        }




                    }
                }
            }
    }
};

export const postReportThunkCreator = (plan_id,object_id,time_start,time_end,time_download,time_plan,works_id,comments_id,updateFlag,report_id,body) => async (dispatch) => {
    await whatsappApi.sendAction(body);
    let response = await plansAPI.postReport(plan_id,object_id,time_start,time_end,time_download,time_plan,works_id,comments_id,updateFlag,report_id);
        if (response) {
            response = await reportsAPI.getReportByTime(start,end);
            if (response) {
                dispatch(GETREPORTSMOBILEAC(response.data.reports));
                response = await reportsAPI.getLinksByTime(start, end);
                if (response) {
                    dispatch(GETPHOTOMOBILEAC(response.data.links));
                    setTimeout(() => {
                        dispatch(SETLOADINGAC(false));
                    }, 4000)
                }
            }
        }

};


export const getReportsByObjectIdThunkCreator = (id,start = 0,end = 0) => async (dispatch) => {
    let response = await reportsAPI.getReportsByObjectId(id,start,end);
    if (response) {
        dispatch(GETOLDREPORTSMOBILEAC(response.data.reports));
    }
};







export default MobileReducer;




