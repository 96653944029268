import React, {useState} from "react";
import s from "../../../staff/styles/staff.module.css";
import {Button, ConfigProvider, DatePicker, Divider, Form, Input, Select, Steps, Switch} from "antd";
import locale from "antd/lib/locale/ru_RU";
import moment from "moment";

const AddObjFinanceIncome = (props) => {


    let obj = props.page === 3 ? props.objects : props.objects.map(el => {

        let flag = true;
        for (let i = 0, len = props.income.length; i < len; i++) {
            if (Number(props.income[i].obj_id) === Number(el.id)) {
                flag = false;
            }
        }
        return flag ? el : ''
    }).filter(el => el !== '')


    let defaultState = {
        id: props.page === 3 ? Number(props.current.obj_id) : Number(obj[0].id),
        name: props.page === 3 ? props.current.obj_name : obj[0].name,
        cost: props.page === 3 ? props.current.cost.split('`') : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        enable: props.page === 3 ? props.current.enable : 1,
        type: props.page === 3 ? props.current.type : 1,
        nds: props.page === 3 ? props.current.nds : 0,
        balance: props.page === 3 ? props.current.balance : 0

    }


    let [state, setState] = useState(defaultState)

    let render = obj.map(el => {
        console.log(Number(el.id))
        return <Select.Option value={Number(el.id)}>{el.name}</Select.Option>
    })

    const handleChange = (e) => {
        let tempstate = {...state}
        tempstate.id = Number(e)
        for (let i = 0, len = obj.length; i < len; i++) {
            if (Number(obj[i].id) === Number(e)) {
                tempstate.name = obj[i].name
            }
        }

        setState(tempstate)
        console.log(e)
    }

    const change = (name, value) => {
        let tempstate = {...state}
        tempstate[name] = value
        setState(tempstate)
    }

    const changeCost = (name, value, id) => {
        let tempstate = {...state}
        tempstate[name][id] = value
        setState(tempstate)
    }

    function onChangeEnable(checked) {
        let tempstate = {...state}
        tempstate.enable = checked ? 1 : 0
        setState(tempstate)
    }

    const handleChangeType = (e) => {
        let tempstate = {...state}
        tempstate.type = Number(e)
        setState(tempstate)
        console.log(e)
    }



    const handleChangeNds = (e) => {
        let tempstate = {...state}
        tempstate.nds = Number(e)
        setState(tempstate)
        console.log(e)
    }


    const post = () => {
        console.log(state)
        props.postIncome(state, props.page === 3 ? 1 : 0);
        props.close()

    }


    return (
        <div className={s.addStaffCont}>

            <div className={s.exit}><a onClick={() => {
                props.close()
            }}>Выход</a></div>
            <Form
                labelCol={{
                    span: 4,
                }}
                wrapperCol={{
                    span: 14,
                }}
                layout="horizontal"
                size={'small'}
            >
                <Divider plain>Основные данные</Divider>
                <Form.Item label="Выберите объект">
                    <Select defaultValue={state.id} onChange={handleChange}>
                        {render}
                    </Select>
                </Form.Item>
                <Form.Item label="Тип оплаты">
                    <Select defaultValue={Number(state.type)}onChange={handleChangeType}>
                        <Select.Option value={1}>Наличные</Select.Option>
                        <Select.Option value={2}>Безнал</Select.Option>
                    </Select>
                </Form.Item>
                {Number(state.type) === 2 &&
                <Form.Item label="НДС">
                    <Select defaultValue={Number(state.nds)} onChange={handleChangeNds}>
                        <Select.Option value={0}>БЕЗ НДС</Select.Option>
                        <Select.Option value={7}>НДС 7%</Select.Option>
                        <Select.Option value={21}>НДС 21%</Select.Option>
                    </Select>
                </Form.Item>}
                <Form.Item label="Баланс">
                    <Input value={state.balance} onChange={(val) => {
                        change('balance', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Январь">
                    <Input value={state.cost[0]} onChange={(val) => {
                        changeCost('cost', val.target.value, 0)
                    }}/>
                </Form.Item>
                <Form.Item label="Февраль">
                    <Input value={state.cost[1]} onChange={(val) => {
                        changeCost('cost', val.target.value, 1)
                    }}/>
                </Form.Item>
                <Form.Item label="Март">
                    <Input value={state.cost[2]} onChange={(val) => {
                        changeCost('cost', val.target.value, 2)
                    }}/>
                </Form.Item>
                <Form.Item label="Апрель">
                    <Input value={state.cost[3]} onChange={(val) => {
                        changeCost('cost', val.target.value, 3)
                    }}/>
                </Form.Item>
                <Form.Item label="Май">
                    <Input value={state.cost[4]} onChange={(val) => {
                        changeCost('cost', val.target.value, 4)
                    }}/>
                </Form.Item>
                <Form.Item label="Июнь">
                    <Input value={state.cost[5]} onChange={(val) => {
                        changeCost('cost', val.target.value, 5)
                    }}/>
                </Form.Item>
                <Form.Item label="Июль">
                    <Input value={state.cost[6]} onChange={(val) => {
                        changeCost('cost', val.target.value, 6)
                    }}/>
                </Form.Item>
                <Form.Item label="Август">
                    <Input value={state.cost[7]} onChange={(val) => {
                        changeCost('cost', val.target.value, 7)
                    }}/>
                </Form.Item>
                <Form.Item label="Сентябрь">
                    <Input value={state.cost[8]} onChange={(val) => {
                        changeCost('cost', val.target.value, 8)
                    }}/>
                </Form.Item>
                <Form.Item label="Октябрь">
                    <Input value={state.cost[9]} onChange={(val) => {
                        changeCost('cost', val.target.value, 9)
                    }}/>
                </Form.Item>
                <Form.Item label="Ноябрь">
                    <Input value={state.cost[10]} onChange={(val) => {
                        changeCost('cost', val.target.value, 10)
                    }}/>
                </Form.Item>
                <Form.Item label="Декабрь">
                    <Input value={state.cost[11]} onChange={(val) => {
                        changeCost('cost', val.target.value, 11)
                    }}/>
                </Form.Item>


                <Form.Item label="Выплата активна" valuePropName="checked">
                    <Switch checked={Number(state.enable) === 1} onChange={onChangeEnable}/>
                </Form.Item>

                <Button style={{margin: '0 auto', display: 'block'}} onClick={() => {
                    post()
                }}>{props.page === 3 ? 'Обновить данные' : 'Добавить объект'}</Button>
            </Form></div>
    )
}

export default AddObjFinanceIncome