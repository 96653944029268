import {Empty, Table, Tag} from 'antd';
import React from "react";
const columns = [
    {
        title: '',
        dataIndex: 'name',
        key: 'name',

    },
    {
        title: '',
        dataIndex: 'value',
        key: 'value',
    },
];

const  getData = (props) => {
    if (props) {


        let result = [
            {
                key: 1,
                name: 'KPI',
                value: props.kpi
            },
            {
                key: 2,
                name: 'Опыт работы',
                value: props.exp
            },
            {
                key: 3,
                name: 'Владение языками' ,
                value: <Tag color="green">{props.language}</Tag>
            },



        ];
        return result
    } else {
        return [{
            key: 0,
            name: 'init',
            value: 'init'
        }]
    }

};
const Expirience = (props) => {
    if (props.data.length > 0) {
        return  <Table pagination={false} columns={columns} dataSource={getData(props.data[0])} />
    } else {
        return   <Empty description={<span> Доступ к разделу запрещен.</span>} />
    }
};

export default Expirience;