import '../..//index.css';
import 'antd/dist/antd.css';
import s from './auth.module.css'
import React, {Component} from "react";
import {connect} from "react-redux";
import {authThunkCreator, checkThunkCreator, SETSTATUSAC} from "../../redux/reducers/authReducer";
import * as md5 from "md5";
import {AuthForm} from "./AuthForm";
import Main from "../main/Main";
import GeneralMobile from "../mobile/GeneralMobile";
import Select from "./Select";


class Auth extends Component {

    componentDidMount() {
        this.props.checkThunkCreator();                      //Проверяем авторизацию
    }
    submit = (value) => {
        let pass = md5(value.password);
        this.props.authThunkCreator(value.username, pass);  //Авторизация
    };
    exit = () => {
        document.cookie = `hash=''`;
        document.cookie = `login=''`;
        this.props.SETSTATUSAC(false);                      //Выход
    };
    render() {

        this.mobile = true;

        if (this.props.role) {
            this.role = this.props.role.split(',')
        }



        if (this.props.status ) {

            if ((this.role.indexOf('1') !== -1 && this.role.indexOf('3') !== -1) || (this.role.indexOf('8') !== -1 && this.role.indexOf('3') !== -1) || (this.role.indexOf('6') !== -1 && this.role.indexOf('3') !== -1) ){
                return <Select exit={this.exit} id={this.props.id} username={this.props.username}/>
            } else if ((this.role.indexOf('1') === -1 && this.role.indexOf('3') !== -1) ||  (this.role.indexOf('8') === -1 && this.role.indexOf('3') !== -1) ||  (this.role.indexOf('6') === -1 && this.role.indexOf('3') !== -1)) {
                return <GeneralMobile exit={this.exit} id={this.props.id} username={this.props.username}/>
            } else if ((this.role.indexOf('1') !== -1 && this.role.indexOf('3') === -1) || (this.role.indexOf('8') !== -1 && this.role.indexOf('3') === -1) || (this.role.indexOf('6') !== -1 && this.role.indexOf('3') === -1)) {
                return <Main exit={this.exit} username={this.props.username} role={this.role} />
            }

        } else {
            return (
                <div className={s.main}>
                    < AuthForm submit={this.submit} message={this.props.message}/>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        status: state.authRDCR.status,
        username: state.authRDCR.username,
        message: state.authRDCR.message,
        role: state.authRDCR.role,
        id: state.authRDCR.id,
    }
};



export default connect(mapStateToProps, {
    authThunkCreator,
    SETSTATUSAC,
    checkThunkCreator,
})(Auth);



