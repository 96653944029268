import React, {useState} from 'react';
import s from './styles/staff.module.css'
import {
    Form,
    Input,
    Button,
    Select,
    DatePicker,
    Switch, Divider, ConfigProvider, Steps
} from 'antd';

import moment from "moment";
import locale from 'antd/lib/locale/ru_RU';


const {Step} = Steps;
const FormSizeDemo = (props) => {


    let defaultState = props.state !== '' ? props.state : {
        name: '',
        secondName: '',
        patronymic: '',
        nationality: '',
        birthdayDate: '',
        dateStartWork: '',
        workContract: '',
        workContractEnd: '',
        passport: '',
        passportDate: '',
        boravok: '',
        boravokDate: '',
        driverLicense: '',
        driverLicenseDate: '',
        exp: '',
        language: '',
        phone: '',
        eMail: '',
        adress: '',
        contactFaceOne: '',
        contactFacePhoneOne: '',
        contactFaceTwo: '',
        contactFacePhoneTwo: '',
        roles: [],
        login: '',
        pass: '',
        store: '',
        personalCar: 0,
        steps: 0

    }

    let [state, setState] = useState(defaultState)


    console.log(state.roles)


    const change = (name, value) => {
        let tempstate = {...state}
        tempstate[name] = value
        setState(tempstate)
    }


    function onChangebirthdayDate(date) {
        let tempstate = {...state}
        tempstate['birthdayDate'] = date.format('X')
        setState(tempstate)
    }

    function onChangedateStartWork(date) {
        let tempstate = {...state}
        tempstate['dateStartWork'] = date.format('X')
        setState(tempstate)
    }

    function onChangeworkContractEnd(date) {
        let tempstate = {...state}
        tempstate['workContractEnd'] = date.format('X')
        setState(tempstate)
    }

    function onChangepassportDate(date) {
        let tempstate = {...state}
        tempstate['passportDate'] = date.format('X')
        setState(tempstate)
    }

    function onChangeboravokDate(date) {
        let tempstate = {...state}
        tempstate['boravokDate'] = date.format('X')
        setState(tempstate)
    }

    function onChangedriverLicenseDate(date) {
        let tempstate = {...state}
        tempstate['driverLicenseDate'] = date.format('X')
        setState(tempstate)
    }

    function handleChange(value) {
        let tempstate = {...state}
        tempstate['workContract'] = value
        setState(tempstate)
    }

    function onChangeGardens(checked) {
        let tempstate = {...state}
        if (!checked) {
            let i = tempstate.roles.indexOf('3');
            if (i >= 0) {
                tempstate.roles.splice(i, 1);
            }
        } else {
            tempstate.roles.push('3')
        }

        setState(tempstate)
    }

    function onChangeCar(checked) {
        let tempstate = {...state}
        tempstate.personalCar = checked ? 1 : 0
        setState(tempstate)
    }

    function onChangeAdmin(checked) {
        let tempstate = {...state}
        if (!checked) {
            let i = tempstate.roles.indexOf('1');
            if (i >= 0) {
                tempstate.roles.splice(i, 1);
            }
        } else {
            tempstate.roles.push('1')
        }

        setState(tempstate)
    }
    function onChangeStoreManager(checked) {
        let tempstate = {...state}
        if (!checked) {
            let i = tempstate.roles.indexOf('6');
            if (i >= 0) {
                tempstate.roles.splice(i, 1);
            }
        } else {
            tempstate.roles.push('6')
        }

        setState(tempstate)
    }

    function onChangeStore(checked) {
        let tempstate = {...state}
        tempstate.store = checked ? 1 : 0
        setState(tempstate)
    }

    const post = () => {
        props.post(state, Number(props.page) === 3 ? Number(props.id) : 0);
    }


    const setStep = current => {
        console.log('onChange:', current);
        let tempstate = {...state}
        tempstate.steps = current
        setState(tempstate)
    };

    return (
        <div className={s.addStaffCont}>

            <div className={s.exit}><a onClick={() => {
                props.setPage(0)
            }}>Выход</a></div>
            <Form
                labelCol={{
                    span: 4,
                }}
                wrapperCol={{
                    span: 14,
                }}
                layout="horizontal"
                size={'small'}
            >
                <Divider plain>Основные данные</Divider>
                <Form.Item label="Фамилия">
                    <Input value={state.secondName} onChange={(val) => {
                        change('secondName', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Имя">
                    <Input value={state.name} onChange={(val) => {
                        change('name', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Отчество">
                    <Input value={state.patronymic} onChange={(val) => {
                        change('patronymic', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Гражданство">
                    <Input value={state.nationality} onChange={(val) => {
                        change('nationality', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Дата рождения">
                    <ConfigProvider locale={locale}>
                        <DatePicker defaultValue={state.birthdayDate ? moment(Number(state.birthdayDate) * 1000) : ''}
                                    onChange={onChangebirthdayDate}/>
                    </ConfigProvider>

                </Form.Item>
                <Form.Item label="Дата начала работы">
                    <ConfigProvider locale={locale}>
                        <DatePicker defaultValue={state.dateStartWork ? moment(Number(state.dateStartWork) * 1000) : ''}
                                    onChange={onChangedateStartWork}/>
                    </ConfigProvider>
                </Form.Item>
                <Form.Item label="Рабочий контракт">
                    <Select defaultValue={state.workContract} onChange={handleChange}>
                        <Select.Option value="1">Есть</Select.Option>
                        <Select.Option value="0">Нет</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Дата завершения контракта">
                    <ConfigProvider locale={locale}>
                        <DatePicker
                            defaultValue={state.workContractEnd ? moment(Number(state.workContractEnd) * 1000) : ''}
                            onChange={onChangeworkContractEnd}/>
                    </ConfigProvider>
                </Form.Item>
                <Form.Item label="Личный транспорт" valuePropName="checked">
                    <Switch checked={Number(state.personalCar) === 1} onChange={onChangeCar}/>
                </Form.Item>

                <Divider plain>Документы</Divider>
                <Form.Item label="Паспорт">
                    <Input value={state.passport} onChange={(val) => {
                        change('passport', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Срок действия до">
                    <ConfigProvider locale={locale}>
                        <DatePicker defaultValue={state.passportDate ? moment(Number(state.passportDate) * 1000) : ''}
                                    onChange={onChangepassportDate}/>
                    </ConfigProvider>
                </Form.Item>
                <Form.Item label="Боравок">
                    <Input value={state.boravok} onChange={(val) => {
                        change('boravok', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Срок действия до">
                    <ConfigProvider locale={locale}>
                        <DatePicker defaultValue={state.boravokDate ? moment(Number(state.boravokDate) * 1000) : ''}
                                    onChange={onChangeboravokDate}/>
                    </ConfigProvider>
                </Form.Item>
                <Form.Item label="ВУ">
                    <Input value={state.driverLicense} onChange={(val) => {
                        change('driverLicense', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Срок действия до">
                    <ConfigProvider locale={locale}>
                        <DatePicker
                            defaultValue={state.driverLicenseDate ? moment(Number(state.driverLicenseDate) * 1000) : ''}
                            onChange={onChangedriverLicenseDate}/>
                    </ConfigProvider>
                </Form.Item>

                <Divider plain>Навыки</Divider>
                <Form.Item label="Опыт работы">
                    <Input value={state.exp} onChange={(val) => {
                        change('exp', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Владение языками">
                    <Input value={state.language} onChange={(val) => {
                        change('language', val.target.value)
                    }}/>
                </Form.Item>

                <Divider plain>Контакты</Divider>
                <Form.Item label="Телефон">
                    <Input value={state.phone} onChange={(val) => {
                        change('phone', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="E-mail">
                    <Input value={state.eMail} onChange={(val) => {
                        change('eMail', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Адрес">
                    <Input value={state.adress} onChange={(val) => {
                        change('adress', val.target.value)
                    }}/>
                </Form.Item>


                <Form.Item label="Контактное лицо 1">
                    <Input value={state.contactFaceOne} onChange={(val) => {
                        change('contactFaceOne', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Телефон">
                    <Input value={state.contactFacePhoneOne} onChange={(val) => {
                        change('contactFacePhoneOne', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Контактное лицо 2">
                    <Input value={state.contactFaceTwo} onChange={(val) => {
                        change('contactFaceTwo', val.target.value)
                    }}/>
                </Form.Item>
                <Form.Item label="Телефон">
                    <Input value={state.contactFacePhoneTwo} onChange={(val) => {
                        change('contactFacePhoneTwo', val.target.value)
                    }}/>
                </Form.Item>


                <Divider plain>Роли</Divider>

                <Form.Item label="Садовник" valuePropName="checked">
                    <Switch checked={state.roles.indexOf('3') !== -1} onChange={onChangeGardens}/>
                </Form.Item>
                <Form.Item label="Администратор" valuePropName="checked">
                    <Switch checked={state.roles.indexOf('1') !== -1} onChange={onChangeAdmin}/>
                </Form.Item>
                <Form.Item label="Материально-отв. лицо" valuePropName="checked">
                    <Switch checked={Number(state.store) === 1} onChange={onChangeStore}/>
                </Form.Item>
                <Form.Item label="Менеджер склада" valuePropName="checked">
                    <Switch checked={state.roles.indexOf('6') !== -1} onChange={onChangeStoreManager}/>
                </Form.Item>
                <Form.Item label="Статус" valuePropName="checked">
                    <Steps current={Number(state.steps)} onChange={setStep} size={'small'}>
                        <Step title="Внештатный сотрудник" description=""/>
                        <Step title="Дневница" description="Получает дневницу"/>
                        <Step title="Стажер" description="На испытательном сроке"/>
                        <Step title="Сотрудник" description="Получает полный оклад"/>
                    </Steps>
                </Form.Item>


                <Divider plain>Данные для входа в приложение</Divider>


                <Form.Item label="Логин">
                    <Input value={state.login} onChange={(val) => {
                        change('login', val.target.value)
                    }}/>
                </Form.Item>
                {Number(props.page) !== 3 ?
                    <Form.Item label="Пароль">
                        <Input value={state.pass} onChange={(val) => {
                            change('pass', val.target.value)
                        }}/>
                    </Form.Item>

                    :

                    ''
                }


                <Button style={{margin: '0 auto', display: 'block'}} onClick={() => {
                    post()
                }}>{Number(props.page) !== 3 ? 'Добавить сотрудника' : 'Обновить данные'}</Button>
            </Form></div>

    );
};


const AddStaff = (props) => {
    let defaultArray = '';
    if (Number(props.page) === 2) {
        return (
            <FormSizeDemo state={defaultArray} id={0} post={props.post} setPage={props.setPage} page={props.page}/>
        )
    } else {

        if (props.data.length > 0 && props.pd.length > 0 && props.id) {
            let tempArr = props.data.filter(el => Number(el.id) === Number(props.id))
            defaultArray = {
                name: tempArr[0].firstname,
                secondName: tempArr[0].lastname,
                patronymic: tempArr[0].patronymic,
                nationality: props.pd[0].nationality,
                birthdayDate: props.pd[0].birthday_date,
                dateStartWork: props.pd[0].work_start_date,
                workContract: props.pd[0].contract,
                workContractEnd: props.pd[0].contract_fdate,
                passport: props.pd[0].passport,
                passportDate: props.pd[0].passport_fdate,
                boravok: props.pd[0].boravok,
                boravokDate: props.pd[0].boravok_fdate,
                driverLicense: props.pd[0].driver_license,
                driverLicenseDate: props.pd[0].driver_license_fdate,
                exp: props.pd[0].exp,
                language: props.pd[0].language,
                phone: props.pd[0].phone,
                eMail: props.pd[0].email,
                adress: props.pd[0].adress,
                contactFaceOne: props.pd[0].first_parrent_name,
                contactFacePhoneOne: props.pd[0].first_parrent_phone,
                contactFaceTwo: props.pd[0].second_parrent_name,
                contactFacePhoneTwo: props.pd[0].second_parrent_phone,
                roles: tempArr[0].role.split(','),
                login: tempArr[0].login,
                pass: tempArr[0].pass,
                store: tempArr[0].enable_store,
                personalCar: props.pd[0].personal_car,
                steps: tempArr[0].status

            }
            return (
                <FormSizeDemo state={defaultArray} id={props.id} post={props.post} setPage={props.setPage} page={props.page}/>
            )
        }  else {
            return <div></div>
        }
    }


}

export default AddStaff;




