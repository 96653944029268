import {Input, Modal} from "antd";
import React, {useState} from "react";
import Title from "antd/lib/typography/Title";
import s from '../styles/planning.module.css'

const {TextArea} = Input;

const PlanCommentsChange = (props) => {

    let [comment, setComment] = useState(props.comments)
    console.log(props.comments)

    const setComm = (val, id) => {
        let comments = comment.map( el => Number(el.id) === Number(id) ? {...el, comment: val} : el)
        setComment(comments)

    }

    const retComm = (id) => {
        for (let i = 0, len = comment.length; i < len; i++) {
            if (Number(comment[i].id) === Number(id)){
                return comment[i].comment
            }
        }
    }

    const commentsRender = props.objects.map(el => {
        return (
            <>
                <div className={s.comment__title}>{el.name}</div>
                <TextArea
                    className={'text'}
                    value={retComm(el.id)}
                    onChange={(e) => {
                        setComm(e.currentTarget.value, el.id)
                    }}
                    rows={4}
                    style={{fontWeight: 400}}
                    size={'large'}
                />
            </>
        )

    })


    const post = () => {
        //console.log(comment)
        //props.post(comment)
        // props.close(0)
    };
    const cancelEvent = (e) => {
        props.close(0)
    };

    return (
        <Modal
            title={<Title level={5}>{`Добавление комментария | ${props.title}`}</Title>}
            centered
            visible={true}
            onOk={() => post()}
            onCancel={() => cancelEvent()}
            okText="Сохранить"
            cancelText="Отмена"
        >
            {commentsRender}


            {/*            { alert ? <Alert message="Нельзя переместить больше, чем есть..." type="error"  showIcon/> : ''}
            { isNull ? <Alert message="Укажите корректное количество..." type="error"  showIcon/> : ''}
            { transfer ? <Alert message="Перемещение выполнено!" type="success" showIcon/> : ''}*/}
        </Modal>
    )

}


export default PlanCommentsChange