
import React from "react";
import {Divider, Form} from "antd";
import s from '../../styles/finance.module.css'


const StaffFinanceInfo = (props) => {

    let summRate = 0;
    let summCash = 0;
    let summCashless = 0;
    let tax = 0;
    for (let i = 0, len = props.salary.length; i < len; i++){
        summRate +=  Number(props.salary[i].enable) === 1 ? Number(props.salary[i].rate) : 0
       summCash +=  Number(props.salary[i].enable) === 1 ? Number(props.salary[i].cash) : 0
        summCashless +=  Number(props.salary[i].enable) === 1 ? Number(props.salary[i].cashless) : 0
    }
    tax = summCashless * 0.68



    return (
        <div>
            <div className={s.maintitle}>Ежемесячные расходы на заработную плату</div>
            <div className={s.mainCont}>
                <div className={s.financeStaffCont}>
                    <div className={s.financeStaffTitle}>Всего на зарплату</div>
                    <div className={s.financeStaffContent}>{`${summRate.toFixed(2)} €`}</div>
                </div>
                <div className={s.financeStaffCont}>
                    <div className={s.financeStaffTitle}>Из них наличными</div>
                    <div className={s.financeStaffContent}>{`${summCash.toFixed(2)} €`}</div>
                </div>
                <div className={s.financeStaffCont}>
                    <div className={s.financeStaffTitle}>Из них безналом</div>
                    <div className={s.financeStaffContent}>{`${summCashless.toFixed(2)} €`}</div>
                </div>
                <div className={s.financeStaffCont}>
                    <div className={s.financeStaffTitle}>Налоги</div>
                    <div className={s.financeStaffContent}>{`${tax.toFixed(2)} €`}</div>
                </div>
                <div className={s.financeStaffCont}>
                    <div className={s.financeStaffTitle}>ИТОГО (с налогами)</div>
                    <div className={s.financeStaffContent}>{`${(tax + summRate).toFixed(2)} €`}</div>
                </div>
            </div>
        </div>

    )
}

export default StaffFinanceInfo