import React, {useState} from 'react';
import {Button, Tag} from 'antd';


import {DoubleRightOutlined, EditOutlined} from '@ant-design/icons';
import ModalWin from "./ModalWin";
import s from './styles/store.css'
import StoreCategoryTab from "./StoreCategoryTab";
import ChangeWin from "./ChangeWin";



const getDate = (date) => {
    if (date && date !== 'n') {
        let year = date[6] + date[7] + date[8] + date[9];
        let month = date[3] + date[4];
        let day = date[0] + date[1]
        let unix = new Date(`${year}.${month}.${day}`).getTime() / 1000;
        return +new Date() / 1000 < unix ? <Tag color={'green'}>{date}</Tag> : <Tag color={'red'}>{date}</Tag>
    } else if (date && date === 'n') {
        return <Tag color={'green'}>{'Бессрочно'}</Tag>
    } else {
        return <Tag color={'yellow'}>{'Нет даты'}</Tag>
    }

};


const Store = (props) => {


    let [changeElem, setChangeElem] = useState(false);
    let [currentElem, setCurrentElem] = useState({})

    let [transferElem, setTransferElem] = useState(false);
    let [empty, setEmpty] = useState(0);

    const handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);

    };
    const tabListNoTitle = props.category.mainCategory.map((el) => ({key: Number(el.id), tab: el.name}))
    const eventListenerTransfer = (e) => {
        setTransferElem(!transferElem)
        setCurrentElem(e)
    };
    const eventListenerChange = (e) => {
        setChangeElem(!changeElem)
        setCurrentElem(e)
    };
    let columns = [
        {
            title: 'Арт',
            dataIndex: 'index',
            key: 'index',
            width: '50px',
            fixed: true,
            align: 'center'

        },
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => (a.name > b.name) ? 1 : -1,
            sortDirections: ['ascend'],
            width: '150px',
            fixed: true,
            ellipsis: false,
        },
        {
            title: 'Ед.изм.',
            dataIndex: 'package',
            key: 'package',
            width: '70px',
            fixed: true,
            align: 'center'
        },
        {
            title: 'Действия',
            dataIndex: 'action',
            key: 'action',
            width: '100px',
            fixed: true,
            align: 'center',
            render: (action) => {
                return <><Button className={'btnTransfer'} onClick={() => {
                    eventListenerTransfer(action)
                }} size={"small"} type="primary"><DoubleRightOutlined placeholder={'Переместить'}/></Button><Button
                    onClick={() => {
                        eventListenerChange(action)
                    }} className={'changeIco'} size={"small"} type="primary"><EditOutlined
                    placeholder={'Изменить'}/></Button></>
            },

        }


        ,
        {
            title: 'Категория',
            dataIndex: 'subCategoryName',
            key: 'subCategoryName',
            width: '200px',
            filters: '',
            onFilter: (value, record) => record.subCategoryName.includes(value),

        },
        {
            title: 'Количество',
            children: '',
        },
        {
            title: 'Годен до',
            dataIndex: 'date_avalible',
            key: 'date_avalible',
            width: '80px',
            align: 'center',

        },
        {
            title: 'Производитель | Примечание',
            dataIndex: 'brand',
            key: 'brand',
            width: '150px',
            align: 'center',
            /*            render: (brand) => {
                            return <Popover content={brand} title="Производитель | Примечание" trigger="hover">
                                <QuestionCircleOutlined style={{fontSize: '20px', color: '#1890ff', cursor: 'pointer'}}/>
                            </Popover>
                        }*/

        },


    ];

    let stores = props.stores.filter( el => Number(el.enable) !== 0)



    const packageCorrector = (count, name) => {
        let correctorCount = count ? Number(count) : 0;

        if (correctorCount < 1) {
            switch (name) {
                case 'L':
                    return `${Math.round((correctorCount * 1000) * 1000) / 1000} ml`;
                case 'Kg':
                    return `${Math.round((correctorCount * 1000) * 1000) / 1000} g`;
                case 'M':
                    return `${Math.round((correctorCount * 100) * 1000) / 1000} sm`;
                default:
                    return ''
            }
        } else if (correctorCount >= 100 && name === 'sm') {
            return `${Math.round((correctorCount / 100) * 1000) / 1000} M`
        } else if (correctorCount >= 1000 && name !== 'шт' && name !== 'M2') {
            switch (name) {
                case 'ml':
                    return `${Math.round((correctorCount / 1000) * 1000) / 1000} L`;
                case 'g':
                    return `${Math.round((correctorCount / 1000) * 1000) / 1000} Kg`;
                case 'sm':
                    return `${Math.round((correctorCount / 100) * 1000) / 1000} M`;
                case 'M':
                    return `${Math.round((correctorCount / 1000) * 1000) / 1000} km`;
                default:
                    return ''
            }
        } else {
            return `${Math.round(correctorCount * 1000) / 1000} ${name}`
        }
    };
    const storeCorrector = (count, el) => {

        let correctorCount = Number(count)
        return correctorCount === 0 ? 0
            :
            <div className={s.countPack}>
                <b>{`${packageCorrector(correctorCount * Number(el.package), el.package_name)}`}</b>{` | ${correctorCount}`}
            </div>

    }
    const findCategoryId = (id) => {
        let categoryId = null;
        for (let i = 0, len = props.category.subCategory.length; i < len; i++) {
            if (Number(props.category.subCategory[i].id) === id) {
                categoryId = props.category.subCategory[i].parent_id
            }
        }
        return Number(categoryId)
    };
    const findSubCategoryName = (id) => {
        let name = '';
        for (let i = 0, len = props.category.subCategory.length; i < len; i++) {
            if (Number(props.category.subCategory[i].id) === id) {
                name = props.category.subCategory[i].name
            }
        }
        return name
    };
    const findCategoryName = (id) => {
        let name = '';
        for (let i = 0, len = props.category.mainCategory.length; i < len; i++) {
            if (Number(props.category.mainCategory[i].id) === id) {
                name = props.category.mainCategory[i].name
            }
        }
        return name
    };
    const findModificator = (id) => {
        let modificator = null;
        for (let i = 0, len = props.category.mainCategory.length; i < len; i++) {
            if (Number(props.category.mainCategory[i].id) === id) {
                modificator = props.category.mainCategory[i].modificator
            }
        }
        return Number(modificator)
    };

    const arr = props.storeData.map((el) => {
        // Создание из серверных данных основного массива товаров, с необходимыми свойствами
        let categoryId = findCategoryId(Number(el.sub_category));
        let mod = findModificator(categoryId);
        let subCategoryName = findSubCategoryName(Number(el.sub_category));
        let mainCategoryName = findCategoryName(categoryId);
        let summ = 0;
        let s = {
            index: Number(el.id),
            key: Number(el.id),
            maincatId: categoryId,
            mainCategoryName: mainCategoryName,
            subCatId: Number(el.sub_category),
            subCategoryName: subCategoryName,
            brand: `${el.brand} | ${el.description}`,
            name: mod === 0 ? el.name : `${el.brand !== '' ? el.brand + ' | ' + el.name : el.name}`,
            package: `${el.package}${el.package_name}`,
            description: el.description,
            action: {
                ...el,
                catname: subCategoryName,
                maincatId: categoryId,
                maincatName: mainCategoryName,
                subCatId: Number(el.sub_category),
                subCategoryName: subCategoryName
            },
            date_avalible: getDate(el.date_avalible)
        };
        for (let i = 0; i < stores.length; i++) {

            s[`store_${stores[i].name_db}`] = storeCorrector(el[`store_${stores[i].name_db}`], el)
        }
        for (let i = 0; i < stores.length; i++) {
            if (Number(stores[i].type) === Number(props.storesTypeId)) {
                summ += Number(el[`store_${stores[i].name_db}`])
            }

        }

        if (empty === 0) {
            return s
        } else if (empty === 1 && Number(summ) === 0) {
            return ''
        } else if (empty === 1 && Number(summ) !== 0) {
            return s
        } else if (empty === 2 && Number(summ) === 0) {
            return s
        } else if (empty === 2 && Number(summ) !== 0) {
            return ''
        } else {
            return s
        }
    }).filter((el) => {
        return el !== ''
    });


    return (
        <div>
            <StoreCategoryTab
                tabListNoTitle={tabListNoTitle}
                columns={columns}
                Arr={arr}
                handleChange={handleChange}
                category={props.category}
                stores={stores}
                addModal={props.addModal}
                setAddModal={props.SETADDSTOREAC}
                post={props.post}
                storesTypeId={props.storesTypeId}
                setEmpty={setEmpty}
                empty={empty}

            />
            {transferElem ? <ModalWin update={props.transfer}
                                      element={currentElem}
                                      modal={transferElem}
                                      setModal={() => {
                                          setTransferElem()
                                      }}
                                      stores={stores}
                                      storesTypeId={props.storesTypeId}
            /> : ''}


            {changeElem ? <ChangeWin element={currentElem}
                                     change={changeElem}
                                     setModal={() => {
                                         setChangeElem()
                                     }}
                                     category={props.category}
                                     post={props.changeElem}


            /> : ''}


        </div>
    )


}


export default Store;