
import React from "react";
import {Divider, Form} from "antd";
import s from '../../styles/finance.module.css'


const OperFinanceInfo = (props) => {

    let summRateCash = 0;
    let summRateCashless = 0;
    let summAll = 0;
    let summAllNds = 0;

    const setPeriod = (period, cost) => {
        switch (Number(period)) {
            case 1: return cost * 30
            case 2: return cost * 4
            case 3: return cost
            case 4: return cost / 12
        }
    }


    for (let i = 0, len = props.oper_expenses.length; i < len; i++){
        summRateCash +=  (Number(props.oper_expenses[i].enable) === 1 &&  Number(props.oper_expenses[i].type) === 1) ? setPeriod(Number(props.oper_expenses[i].period), Number(props.oper_expenses[i].cost)) : 0
        summRateCashless +=  (Number(props.oper_expenses[i].enable) === 1 &&  Number(props.oper_expenses[i].type) === 2) ? setPeriod(Number(props.oper_expenses[i].period), Number(props.oper_expenses[i].cost)) : 0
        summAll +=  Number(props.oper_expenses[i].enable) === 1 ? setPeriod(Number(props.oper_expenses[i].period), Number(props.oper_expenses[i].cost))   : 0
        summAllNds +=  Number(props.oper_expenses[i].enable) === 1 ? setPeriod(Number(props.oper_expenses[i].period), Number(props.oper_expenses[i].cost)) * (1+(Number(props.oper_expenses[i].nds)/100)) : 0
    }




    return (
        <div>
            <div className={s.maintitle}>Ежемесячные расходы на транспорт</div>
            <div className={s.mainCont}>
                <div className={s.financeStaffCont}>
                    <div className={s.financeStaffTitle}>Затраты всего</div>
                    <div className={s.financeStaffContent}>{`${summAll.toFixed(2)} €`}</div>
                </div>
                <div className={s.financeStaffCont}>
                    <div className={s.financeStaffTitle}>Из них наличными</div>
                    <div className={s.financeStaffContent}>{`${summRateCash.toFixed(2)} €`}</div>
                </div>
                <div className={s.financeStaffCont}>
                    <div className={s.financeStaffTitle}>Из них безнал</div>
                    <div className={s.financeStaffContent}>{`${summRateCashless.toFixed(2)} €`}</div>
                </div>

                <div className={s.financeStaffCont}>
                    <div className={s.financeStaffTitle}>Всего с учетом НДС</div>
                    <div className={s.financeStaffContent}>{`${summAllNds.toFixed(2)} €`}</div>
                </div>
            </div>
        </div>

    )
}

export default OperFinanceInfo