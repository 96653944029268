import React, {useEffect, useState} from "react";
import {Button, Collapse, Popover, Table, Switch, Space, Tag, Modal, Form, Select, Input} from "antd";
import StaffFinanceInfo from "../staff/StaffFinanceInfo";
import Income from "../../Income";
import IncomeFinanceInfo from "./IncomeFinanceInfo";
import AddStaffFinanceExpensive from "../staff/AddStaffFinanceExpensive";
import AddObjFinanceIncome from "./AddObjFinanceIncome";
import {EuroOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons';
import moment from "moment";
import s from '../../styles/finance.module.css'
import AddPaymentModal from "./AddPaymentModal";

const {Panel} = Collapse;
const PeriodicIncome = (props) => {

    let summRate = 0, summAll = 0;

    const setPeriod = (period, cost) => {

        switch (Number(period)) {
            case 1:
                return Number(cost) * 30
            case 2:
                return Number(cost) * 4
            case 3:
                return Number(cost)
            case 4:
                return Number(cost) / 12
        }
    }
    const setData = (unix) => {
        return moment(unix *1000).format('LLLL')
    }
    const getObjectNameById= (id) => {
        for (let i = 0, len = props.objects.length; i < len; i++){
            if (Number(id) === Number(props.objects[i].id)){
                return props.objects[i].name
            }
        }
    }
    const defaultData = props.income.map(el => {
        summRate += Number(el.enable) === 1 ? setPeriod(el.period, el.cost) : 0
        console.log(summRate)
        summAll += Number(el.enable) === 1 ? (setPeriod(el.period, el.cost) * (1 + (el.nds / 100))) : 0

        return {
            id: el.id,
            obj_id: el.obj_id,
            name: el.obj_name,
            type: el.type,
            cost: el.cost,
            current: el.cost,
            nds: el.nds,
            enable: el.enable,
            all: el.cost,
            edit: el,
            balance: Number(el.balance),
        }
    })
    const columns = [
        {
            title: 'Название объекта',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Тип платежа',
            dataIndex: 'type',
            key: 'type',
            render: (type, e) => {
                return Number(e.id !== 0) ? (<span>{Number(type) === 1 ? 'Наличные' : 'Безнал'}</span>) : ''
            },
        },
        {
            title: 'Стоимость (мес)',
            dataIndex: 'cost',
            key: 'cost',
            align: 'center',
            render: (cost, e) => {
                return (Number(e.id !== 0) ?
                        <Popover content={content(cost)} title="Абонентская плата (мес)">
                            <EuroOutlined/>
                        </Popover>
                        :
                        ''


                )
            },
        },
        {
            title: 'Текущий месяц',
            children: [
                {
                    title: 'Стоимость',
                    dataIndex: 'current',
                    key: 'current',
                    align: 'center',
                    render: (current, e) => {
                        return Number(e.id !== 0) ? <span>{`${currentMonth(current)} €`}</span> : ''
                    },
                },
                {
                    title: 'Оплата',
                    dataIndex: 'balance',
                    key: 'balance',
                    align: 'center',
                    render: (balance, e) => {
                        if (Number(e.id !== 0)) {
                            return balance >= 0 ? <Tag color="success">Оплачено</Tag> :
                                <Tag color="error">Не оплачено</Tag>
                        } else {
                            return ''
                        }

                    },
                },
                {
                    title: 'НДС',
                    dataIndex: 'nds',
                    key: 'nds',
                    align: 'center',
                    render: (nds, elem) => {
                        return Number(elem.id !== 0) ? <span>{`${nds} %`}</span> : ''
                    },
                },
                {
                    title: 'Всего с НДС',
                    dataIndex: 'all',
                    key: 'all',
                    render: (all, e) => {
                        if (Number(e.id !== 0)) {
                            return (<span>{`${(currentMonth(all) + ((currentMonth(all) / 100) * Number(e.nds))).toFixed(2)} €`}</span>)
                        } else {
                            return ''
                        }
                    }
                },
            ]
        },

        {
            title: 'Баланс',
            dataIndex: 'balance',
            key: 'balance1',
            render:
                (balance, e) => {
                    return <a onClick={()=>{showModal(Number(e.obj_id), e.name)}}>{`${balance} €`}</a>
                },
        },
        /*

                    {
                        title: 'Оплата активна',
                        dataIndex:
                            'enable',
                        key:
                            'enable',
                        render:
                            (enable, elem) => {
                                return Number(elem.id !== 0) ? <Switch size="small" onChange={() => {
                                    onChangeEnable(elem.id)
                                }} checked={Number(enable) === 1}/> : '';
                            },
                    },*/
        {
            title: 'Редактировать',
            dataIndex:
                'edit',
            key:
                'edit',
            align: 'center',
            render:
                (edit) => {
                    return (
                        <a onClick={() => {
                            editObject(edit)
                        }}><EditOutlined/></a>)
                },
        },


    ]
/*    defaultData.push({
        id: 0,
        obj_id: 0,
        name: 'ИТОГО:',
        cost: summRate,
        current: '',
        nds: '',
        enable: '',

    })*/
    let defaultPayments = props.payments.map(el => {
        return {
            id: Number(el.id),
            cost: Number(el.cost),
            name: getObjectNameById(Number(el.object_id)),
            date: setData(Number(el.date)),
            type: Number(el.type),
            nds: Number(el.nds),
            obj_id: Number(el.object_id),
            del: Number(el.id)
        }


    })
    const paymentColumns = [
        {
            title: 'Дата платежа',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Название объекта',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Тип платежа',
            dataIndex: 'type',
            key: 'type',
            render: (type, e) => {
                return <span>{Number(type) === 1 ? 'Наличные' : 'Безнал'}</span>
            },
        },
        {
            title: 'Ндс',
            dataIndex: 'nds',
            key: 'nds',
            render: (nds) => {
                return (<span>{`${nds} %`}</span>)
            },
        },
        {
            title: 'Сумма',
            dataIndex: 'cost',
            key: 'cost',
            render: (cost) => {
                return (<span>{`${cost.toFixed(2)}  €`}</span>)
            },
        },
        {
            title: 'Удалить',
            dataIndex:
                'del',
            key:
                'del',
            align: 'center',
            render:
                (del) => {
                    return (
                        <a onClick={() => {
                            delPayment(del)
                        }}><DeleteOutlined /></a>)
                },
        },
    ]
    const [page, setPage] = useState({page: 1, current: {}})
    const [isModalVisible, setIsModalVisible] = useState({visible: false, name: '', id: 0});


    const showModal = (id, name) => {
        setIsModalVisible({visible: true, name: name, id: id});
    };
    const handleOk = (state) => {
        let tempState = {...state}
        console.log(tempState)
        tempState.data = Number(moment().format('X'))
        tempState.obj_id = Number(isModalVisible.id)
        tempState.cost = tempState.type === 1 ? Number(tempState.cost) : (Number(tempState.cost) + (( Number(tempState.cost) / 100) * tempState.nds)).toFixed(2)

        setIsModalVisible({visible: false, name: '', id: 0});
        props.postPayment(tempState,0)
    };

    const handleCancel = () => {
        setIsModalVisible({visible: false, name: '', id: 0});
    };


    const content = (cost) => {
        if (cost) {

            let costArray = cost.split('`')
            costArray = costArray.map((el, index) => {
                return <p>{`${moment(`${index + 1}.2018`, 'MM.YYYY').locale('ru').format('MMMM')}: ${el.replace(',', '.')} €`}</p>

            })
            return <div className={s.pop}>{costArray}</div>
        } else {
            return <p></p>
        }

    }
    const currentMonth = (cost) => {

        let month = Number(moment().format("M"))
        let tempcost = cost.split('`')
        tempcost = tempcost.map(el => {
            return el.replace(',', '.')
        })
        return Number(tempcost[month - 1])
    }
    const editObject = (el) => {
        console.log(el)
        setPage({page: 3, current: el})
    }
    const delPayment = (el) => {
        console.log(el)
        props.delPayment(Number(el))
        //setPage({page: 3, current: el})
    }



    if (page.page === 1) {
        return (
            <Collapse defaultActiveKey={['1']}>
                <Panel header="Планируемые доходы" key="1">
                    <IncomeFinanceInfo type={1} income={props.income}/>
                </Panel>
                <Panel header="Фактические доходы" key="2">
                    <IncomeFinanceInfo type={2} income={props.income}/>
                </Panel>
                <Panel header="Подробная информация" key="3">
                    <Button onClick={() => {
                        setPage(2)
                    }} style={{marginRight: '10px', marginLeft: '10px',}} type="primary">
                        Добавить объект
                    </Button>
{/*                    <Button type="primary">
                        Сохранить изменения
                    </Button>*/}
                    <Table
                        dataSource={defaultData}
                        columns={columns}
                        scroll={{y: 400}}
                        style={{padding: 20}}
                        size={'small'}
                        pagination={false}
                        bordered={true}
                        payments={props.payments}
                    />
                </Panel>
                <Panel header="Платежи" key="4">
                    <Table
                        dataSource={defaultPayments}
                        columns={paymentColumns}
                        scroll={{y: 400}}
                        style={{padding: 20}}
                        size={'small'}
                        pagination={false}
                        bordered={true}
                        payments={props.payments}
                    />
                </Panel>
                <AddPaymentModal isModalVisible={isModalVisible}
                                 ok={handleOk}
                                 cancel={handleCancel}

                />
            </Collapse>
        )
    } else {
        return <AddObjFinanceIncome close={() => {
            setPage({page: 1, current: {}})
        }} postIncome={props.postIncome} objects={props.objects} income={props.income}
                                    current={page.current} page={page.page}/>
    }

}

export default PeriodicIncome